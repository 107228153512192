import React from 'react';

import PropTypes from 'prop-types';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';

const ColorData = ({ value }) => {
  const dataClassName = value >= 0 ? 'pf-data green-pf-data' : 'pf-data red-pf-data';
  return (
    <span className={dataClassName}>{value > 0 ? `+${twoDecimalWithSpaces(value)}%` : `${twoDecimalWithSpaces(value)}%`}</span>
  );
};

export default ColorData;

ColorData.propTypes = {
  value: PropTypes.number,
};
