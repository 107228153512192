import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Radio, Space } from 'antd';

import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';

import dayjs from 'dayjs';
import './style.scss';
import { postOnboardingData } from '../../data/store/clients/clientActions';

const OnboardingPlanning = ({ userId, url, accountInfo, setAccountInfo }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const meetingType = Form.useWatch('meetingType', form);
  const meetingDate = Form.useWatch('meetingDate', form);

  useEffect(() => {
    form.setFieldsValue({});
  }, []);

  const handleStartOnbording = () => {
    form.submit();
  };

  const onCreate = (data) => {
    const newData = {
      account_type: accountInfo?.accountType,
      personal_number: accountInfo?.personalNumber?.toString(),
      type: data?.meetingType,
      start_date: dayjs(data.meetingDate).format(),
    };
    console.log('🚀 ~ onCreate ~ newData:', newData);
    setAccountInfo(newData);
    // history.push(`${url}/planning-complete`);
    dispatch(postOnboardingData({ clientId: userId, ...newData }));
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div style={{ padding: '35px 24px', height: '100%' }}>
        <Container widthP={100} height={'100%'} padding='24px 0'>
        <div className='onbording-card-wrapper'>
          <div className='onbording-card card-left'>
          <div className='onbording-step-wrapper'>
            <div className='onbording-card-number'>2</div>
            <h2 className='onbording-card-title'>
              {t('ONBOARDING')}
            </h2>
            <p className='onbording-card-description'>{t('PLEASE_PROVIDE_MEETING')}</p>
          </div>
          </div>
          <div className='onbording-card card-right'>
          <Form
            form={form}
            name="onboarding-form-first"
            layout="vertical"
            onFinish={onCreate}
            className="instrument-form"
            style={{ marginLeft: '109px' }}
          >
            <h2 className='onbording-card-header'>
              {t('WHAT_TYPE_MEETING')}
            </h2>
            <div className='onbording-card-checkbox-wrap'>
              <Form.Item
                name='meetingType'
                className="m-top-12 m-bottom-0">
                <Radio.Group className='checkbox-block'>
                <Space direction="horizontal" align='center'>
                    <CustomRadioButtom value='online'>{t('ONLINE')}</CustomRadioButtom>
                    <CustomRadioButtom value='offline'>{t('OFFLINE')}</CustomRadioButtom>
                </Space>
                </Radio.Group>
              </Form.Item>
            </div>
            <p className='onbording-card-subheader step-two'>{t('PLEASE_PROVIDE_MEETING_LINK')}</p>
            <Form.Item
                name='meetingDate'
                className="m-top-12 m-bottom-0">
              <DatePicker
                className='date-picker-btn'
                showTime
                placeholder={t('PLAN_MEETING')}
                suffixIcon={<Picker />}
                onChange={(value, dateString) => {
                  console.log('Selected Time: ', value);
                  console.log('Formatted Selected Time: ', dateString);
                }}
              />
            </Form.Item>
              <Button
                disabled={!meetingDate || !meetingType}
                className="optimise-btn start-onbording-btn"
                onClick={handleStartOnbording}
              >
                {t('CONFIRM')}
              </Button>
          </Form>
          </div>
        </div>

        </Container>
      </div>
      <MainFooter />
    </div>
  );
};

export default OnboardingPlanning;

OnboardingPlanning.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  accountInfo: PropTypes.object,
  setAccountInfo: PropTypes.func,
};
