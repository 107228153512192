/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Button, Col, Divider, Form, Input, Layout, Row, Select } from 'antd';
import { assetRequest, createInstrument, getCurrency, getDiversification, getInstrumentsAll, updateInstrument } from '../../data/store/instruments/instrumentsActions';
import { feeValidation, instrumentNameRules, isinRules, selectRules, tickerRules } from '../../helpers/validation';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import Container from '../../components/Container';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import MainFooter from '../../components/MainFooter';

const { Header } = Layout;

const NewInstrument = ({
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert
}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const { instrId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const instruments = useSelector((state) => state.instrumentsReducer.instruments.list);
  const instrumentsTBD = useSelector((state) => state.instrumentsReducer.instrumentsTBD.list);
  const { assets } = useSelector((state) => state.instrumentsReducer);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const { diversificationList } = useSelector((state) => state.instrumentsReducer);

  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [assetID, setAssetID] = useState(null);
  const [isValid, setIsValid] = useState(false);

  let instrTryFetch = 0;

  useEffect(() => {
    if (!instruments.length && !instrTryFetch) {
      ++instrTryFetch;
      dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    };
    if (!currencyList.length) dispatch(getCurrency());
    if (!diversificationList?.length) dispatch(getDiversification());
  }, []);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const renderOptions = useCallback(() => {
    const dataOptions = assets.map((el) => ({ value: el.name, id: el.ID }))?.sort((a, b) => textSort(a?.value, b?.value));
    return dataOptions.map(({ value, id }) => (
      <Select.Option key={id} id={id} value={value}>
        {value}
      </Select.Option>
    ));
  }, [assets]);

  const getID = useCallback(
    (value, data) => {
      setAssetID(data.id);
    },
    [assetID]
  );

  const getInstrumentById = (id) => {
    const target = state.key === 'edit' ? instruments : instrumentsTBD;
    const instr = target.find(i => i.ID === id);
    return instr;
  };

  const getCurrencyName = useCallback((id) => {
    if (id) {
      const currency = currencyList.find(i => i.id === id);
      return currency?.name;
    }
  }, [currencyList, instrId]);

  useEffect(() => {
    if (instrId) {
      const data = getInstrumentById(+instrId);
      if (data) {
        const { currency_id } = data;
        data.currency = getCurrencyName(currency_id);
        data.name = state.key === 'edit' ? data?.name : data?.instrumentName;
        data.financialAssetID = state.key === 'edit' ? data?.financialAsset?.name : '';
        data.isin = state.key === 'edit' ? data?.isin : data?.instrumentISIN;
        form.setFieldsValue(data);
      }
    }
  }, [instrId, instruments, instrumentsTBD, assets, currencyList]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const handleValueChange = async () => {
    setNeedSave(true);
    const allValues = await form.getFieldsValue();
    const { name, isin, currency, diversification_id, fee, financialAssetID, latest_value } = allValues;
    const isNeedSave = !!name && !!isin && !!currency &&
    !!diversification_id && (fee !== undefined) && !!financialAssetID && !!latest_value;
    if (isNeedSave) {
      try {
        await form.validateFields();
        setIsValid(true);
      } catch (errorInfo) {
        console.log('🚀 ~ handleValueChange ~ errorInfo:', errorInfo);
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  const onCreateInstrument = useCallback(() => {
    const { fee, isin, currency, ...rest } = form.getFieldValue();
    const data = {
      instrument: {
        name: rest?.name,
        ticker: rest?.ticker,
        latest_value: +rest?.latest_value,
        diversification_id: +rest?.diversification_id,
        fee: +fee,
        currency_id: +currency || rest?.currency_id,
        isin: isin === 'N/A' ? 'NOT_APPLICABLE' : isin,
        financialAssetID: assetID ?? rest?.financialAsset?.ID,
      },
    };
    if (!instrId) {
      dispatch(createInstrument({ data, history, path: saveAlert.path }));
    } else if (instrId && state?.key === 'edit') {
      dispatch(updateInstrument({ id: instrId, data, history, path: saveAlert.path }));
    } else if (state?.key === 'requests') {
      const { portfolioContentID } = data.instrument;
      dispatch(assetRequest({ newData: data.instrument, portfolioContentID, id: instrId, history, path: saveAlert.path }));
    }
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    // form.resetFields();
  }, [dispatch, form, assetID, saveAlert]);

  const hendleBlureTicker = async () => {
    const ticker = await form.getFieldValue('ticker');
    if (ticker) {
      form.setFieldValue('ticker', ticker.toUpperCase());
    }
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    form.resetFields();
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    closeModal();
    form.submit();
  };

  const handleReset = () => {
    form.resetFields();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    history.goBack();
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button
              type="text"
              className={'overview-btn ant-btn-active'}
            >
              {instrId
                ? state.key === 'edit'
                  ? t('INSTRUMENT_EDIT')
                  : t('REQUEST_EDIT')
                : t('INSTRUMENT_CREATION')
              }
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}>
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-create">
          {!instrId ? t('CREATE') : t('SAVE')}
        </Button>
      </div>

      <div>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '4px', padding: '0px 40px' }} >
        <Col>
          <h2 className="portfolio-header-title">
            {instrId
              ? state.key === 'edit'
                ? `${t('EDIT')} ${getInstrumentById(+instrId)?.name}`
                : t('REQUEST_EDIT')
              : t('INSTRUMENT_CREATION')}
          </h2>
        </Col>
      </Row>
        <Form
          form={form}
          name="instrument-form"
          layout="vertical"
          onFinish={onCreateInstrument}
          className="instrument-form"
        >
          <Container widthP={100} mb="24px" padding="0">
            <div className='instrument-form-iner-wrapper'>
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  className='custom-required'
                  label={t('NAME')}
                  name="name"
                  rules={instrumentNameRules}
                  required={false}>
                  <Input
                    disabled={!!instrId && state.key === 'edit'}
                    placeholder={t('NAME')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>

              </Col>
              <Col>
                <Form.Item
                  className='custom-required'
                  label={t('ISIN')}
                  name="isin"
                  rules={isinRules}
                  required={false}>
                  <Input
                    disabled={!!instrId && state.key === 'edit'}
                    placeholder={t('ISIN')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>

              </Col>
            </Row>
            <Divider />
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  label={t('TICKER')}
                  name="ticker"
                  rules={tickerRules}
                  onBlur={hendleBlureTicker}>
                  <Input
                    placeholder={t('TICKER')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>
              </Col>
              <Col className='currency-wrapper'>
                <Form.Item
                  className='custom-required'
                  label={t('SHARE_PRICE')}
                  name="latest_value"
                  rules={[
                    {
                      required: true,
                      message: 'Is required!',
                    },
                  ]}
                  required={false}
                >
                  <CustomInputNumber
                    width={'178px'}
                    decimalSeparator={decimalSeparator()}
                    min={0.01}
                    placeholder={t('SHARE_PRICE')}
                    onChange={handleValueChange}
                  />
                </Form.Item>
                <Form.Item
                  className='custom-required'
                  label={t('CURRENCY')}
                  name="currency"
                  required={false}
                >
                  <CustomSelect
                    width={'172px'}
                    onChange={handleValueChange}
                    placeholder={t('CURRENCY')}
                    className="custom-select">
                    {currencyList?.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </CustomSelect>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  className="custom-required"
                  label={t('DIVERSIFICATION')}
                  name="diversification_id"
                  rules={[
                    {
                      required: true,
                      message: 'Is required!',
                    },
                  ]}
                  required={false}
                >
                  <CustomSelect
                    className="custom-select"
                    placeholder={t('DIVERSIFICATION')}
                    onChange={handleValueChange}
                  >
                    {diversificationList?.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </CustomSelect>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="custom-required"
                  label={t('FEE')}
                  name="fee"
                  rules={feeValidation}
                  required={false}
                >
                  <CustomInputNumber
                    width={'360px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={2}
                    className=''
                    min={0}
                    step={0.01}
                    placeholder={t('FEE')}
                    onChange={handleValueChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  className="custom-required"
                  label={t('ASSET_CLASS')}
                  name="financialAssetID"
                  rules={selectRules}
                  required={false}
                >
                  <CustomSelect
                    placeholder={t('ASSET_CLASS')}
                    onSelect={getID}
                    className="custom-select"
                    onChange={handleValueChange}
                  >
                    {renderOptions()}
                  </CustomSelect>
                </Form.Item>
              </Col>
            </Row>
            </div>
          </Container>
        </Form>
      </div>
        <ModalUnsaveData
          open={modalCancelVisible}
          closeModal={closeModal}
          cancelWithoutChange={cancelWithoutChange}
          onSubmit={handleSubmit}
      />
      <MainFooter />
    </div>
  );
};

export default NewInstrument;

NewInstrument.propTypes = {
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
