/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Input, Layout, Row } from 'antd';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainFooter from '../MainFooter/MainFooter';
import ModalUnsaveData from '../ModalUnsaveData/ModalUnsaveData';
import Container from '../Container';
import { emailRules, nameRules, numberRules } from '../../helpers/validation';
import { createAdvisor, getAdvisors, updateAdvisor } from '../../data/store/advisors/advisorAction';
import CustomPhoneInput from '../CustomPhoneInput/CustomPhoneInput';

const { Header } = Layout;

const AdvisorForm = ({
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');
  const { userId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const advisors = useSelector(state => state.advisorReducer.advisors.list);

  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
  }, []);

  const getAdvisorData = (id) => {
    return advisors?.find(i => i.ID === +id);
  };

  useEffect(() => {
    const advisor = getAdvisorData(userId);
    if (userId && advisor) {
      const data = {
        id: advisor.ID,
        firstName: advisor?.profile?.firstName ?? '',
        lastName: advisor?.profile?.lastName ?? '',
        phone: advisor?.profile?.phone ?? '',
        email: advisor?.email,
        country_code: advisor?.profile?.country_code,
      };
      form.setFieldsValue(data);
    }
  }, [advisors]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const handleReset = () => {
    form.resetFields();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    history.goBack();
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    form.resetFields();
    closeModal();
    history.push(path);
  };

  const onCancel = useCallback(() => {
    form.resetFields();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    dispatch(getAdvisors({ limit: 10, offset: 0 }));
    history.replace('/advisors/advisors-list');
  }, []);

  const handleSubmit = () => {
    form.submit();
  };

  const handleValueChange = async () => {
    setNeedSave(true);
    const allValues = await form.getFieldsValue();
    const { firstName, lastName, email, phone, country_code } = allValues;
    const isNeedSave = !!firstName && !!lastName && !!email && !!phone && !!country_code;
    if (isNeedSave) {
      try {
        await form.validateFields();
        setIsValid(true);
        setNeedSave(true);
      } catch (errorInfo) {
        console.log('🚀 ~ handleValueChange ~ errorInfo:', errorInfo);
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  const onCreate = () => {
    const { firstName, lastName, email, phone, country_code } = form.getFieldsValue();
    const data = {
      user: {
        email,
        role: 'ADVISER'
      },
      profile: {
        firstName,
        lastName,
        phone,
        country_code
      }
    };
    if (!userId) {
      dispatch(createAdvisor({ data, callback: onCancel, history, path: saveAlert.path }));
    } else {
      const data = {
        email,
        firstName,
        lastName,
        phone,
        country_code
      };
      dispatch(updateAdvisor({ data, id: userId, history, path: saveAlert.path }));
    }
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    form.resetFields();
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
        <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button
              type="text"
              className={'overview-btn ant-btn-active'}>
              {userId
                ? t('EDIT_ADVISOR')
                : t('INVITE_ADVISOR')
              }
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}>
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-create">
          {!userId ? t('INVITE') : t('SAVE')}
        </Button>
      </div>
      <div>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '4px', padding: '0px 40px' }} >
        <Col>
          <h2 className="portfolio-header-title">
            {userId
              ? t('EDIT_ADVISOR_INFORMATION')
              : t('ADVISOR_INVITATION')}
          </h2>
        </Col>
      </Row>
        <Form
          form={form}
          name="instrument-form"
          layout="vertical"
          onFinish={onCreate}
          className="instrument-form"
        >
          <Container widthP={100} mb="24px" padding="0">
            <div className='instrument-form-iner-wrapper'>
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  className='custom-required'
                  label={t('FIRST_NAME')}
                  name="firstName"
                  rules={nameRules}
                  required={false}>
                  <Input
                    placeholder={t('FIRST_NAME')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className='custom-required'
                  label={t('LAST_NAME')}
                  name="lastName"
                  rules={nameRules}
                  required={false}>
                  <Input
                    placeholder={t('LAST_NAME')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  label={t('EMAIL')}
                  className='custom-required'
                  name="email"
                  required={false}
                  rules={emailRules}>
                  <Input
                    placeholder={t('EMAIL')}
                    onChange={handleValueChange}
                    className='custom-input'/>
                </Form.Item>
              </Col>
              <Col className='currency-wrapper'>
                <Form.Item
                  className='custom-required'
                  label={t('PHONE')}
                  name="phone"
                  rules={numberRules}
                  required={false}
                >
                  <CustomPhoneInput
                    autoComplete={false}
                    onChange={handleValueChange}
                    nameCode='country_code'
                  />
                </Form.Item>
              </Col>
            </Row>
            </div>
          </Container>
        </Form>
      </div>
        <ModalUnsaveData
          open={modalCancelVisible}
          closeModal={closeModal}
          cancelWithoutChange={cancelWithoutChange}
          onSubmit={handleSubmit}/>
      <MainFooter />
    </div>
  );
};

export default AdvisorForm;

AdvisorForm.propTypes = {
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
