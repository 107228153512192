import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ScriptItem from './OnboardingScriptList/ScriptItem';
import { OnboardingPoints } from './data/OnboardingPoints';
import { ReactComponent as Array } from './img/array-icon.svg';
import { OnboardingQustions } from './data/OnboardingQuestions';

import './style.scss';

const CategoryItem = ({ t, name, status, selected }) => {
  return (
    <div className={selected ? 'category-item selected' : 'category-item'}>
      <div className="category-colunm">
        <div className="category-checkbx" />
        <p className="category-name">{t(name)}</p>
      </div>
      <div className="category-colunm">
        <Array />
      </div>
    </div>
  );
};

const OnboardingScript = ({ currentLang }) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  function useQuery () {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const category = query.get('category');

  const questions = useMemo(() => OnboardingQustions?.find(i => i.key === category), [category]);

  useEffect(() => {
    if (!category) {
      history.push(`${url}?category=client-information`);
    };
  }, []);

  useEffect(() => {
    const newCategory = OnboardingPoints.find(i => i.value === category);
    setSelectedCategory(newCategory);
  }, [category]);

  const [selectedCategory, setSelectedCategory] = useState();

  const { onbording } = useSelector((state) => state.clientReducer);
  console.log('🚀 ~ OnboardingSigning ~ onbording:', onbording);

  const handleSelectCategory = (value, label) => {
    if (value !== 'type-of-account') {
      history.push(`${url}?category=${value}`);
      // setSelectedCategory({ value, label });
    }
  };

  return (
    <div className="profile-wrap onboarding-wrap" style={{ position: 'relative' }}>
      <div style={{ padding: '35px 24px', height: '100%', overflowY: 'auto' }}>
        <Container widthP={100} height={'800px'} padding="24px 0">
          <div className="onbording-card-wrapper">
            <div className="onbording-card card-left">
              <div className="onbording-step-wrapper step-signing">
                <h2 className="onbording-card-title">{t('ONBOARDING_SCRIPT')}</h2>
                <p className="onbording-card-description ">
                  {t('FIND_ALL_QUESTIONS')}
                </p>
                <div className="finplan-category-list-wrapper onboarding-category-wrap">
                    {OnboardingPoints.map((i) => {
                      return (
                        <div
                          key={i.key}
                          className={i.value === 'type-of-account' ? 'onboarding-category-disabled' : 'onboarding-category-item'}
                          onClick={() => handleSelectCategory(i.value, i.label)}
                        >
                          <CategoryItem
                            name={i.label}
                            selected={selectedCategory?.value === i.value}
                            t={t}
                          />
                        </div>
                      );
                    })}
                  </div>
              </div>
            </div>

            <div className="onbording-card card-right">
            <ScriptItem
              questions={questions}
              currentLang={currentLang}
              t={t}
              url={url}
              />
            </div>
          </div>
        </Container>
      </div>
      <MainFooter />
    </div>
  );
};

export default OnboardingScript;

OnboardingScript.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  currentLang: PropTypes.string,
};
// t, name, status, selected
CategoryItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  status: PropTypes.string,
  selected: PropTypes.bool,
};
