export const SET_AUTH_LABEL = 'SET_AUTH_LABEL';

export const RESET_ERROR = 'RESET_ERROR';

export const SET_ERROR = 'SET_ERROR';

export const MODAL_NOT_CLOSED = 'MODAL_NOT_CLOSED';

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const CLEAR_SUGGESTIONS_PORTFOLIO = 'CLEAR_SUGGESTIONS_PORTFOLIO';

export const CLEAR_APPROVED_PORTFOLIO = 'CLEAR_APPROVED_PORTFOLIO';

export const CLEAR_EXECUTED_PORTFOLIO = 'CLEAR_EXECUTED_PORTFOLIO';

export const CLEAR_IMPLEMENTED_PORTFOLIO = 'CLEAR_IMPLEMENTED_PORTFOLIO';
