/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, Form, Radio, Space } from 'antd';

import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import { ReactComponent as QuestionIcon } from '../../../assets/icon-question.svg';

const CategoryTypeOfAccount = ({ t }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const personalNumber = Form.useWatch('personalNumber', form);

  const handleStartOnbording = () => {
    form.submit();
  };

  const onCreate = (data) => {
    console.log('🚀 ~ onCreate ~ data:', data);
  };
  return (
    <div className="card-right-wrap">
      <Form
          form={form}
          name="onboarding-form-first"
          layout="vertical"
          onFinish={onCreate}
          className="instrument-form"
        >
          <h2 className='onbording-card-header'>
            {t('WHAT_TYPE_ACCOUNT_OPEN')}
          </h2>
          <p className='onbording-card-subheader'>{t('DO_YOU_WANT_TO_START')}</p>
          <div className='onbording-card-checkbox-wrap'>
          <Form.Item
            name='accountType'
            className="m-top-12 m-bottom-0">
            <Radio.Group onChange={null} className='checkbox-block'>
              <Space direction="vertical" align='center'>
                <CustomRadioButtom value='privat' checked>{t('PRIVATE_CUSTOMER')}<QuestionIcon/></CustomRadioButtom>
                <CustomRadioButtom disabled value='corporate'>{t('CORPORATE_CLIENT')}<QuestionIcon/></CustomRadioButtom>
              </Space>
            </Radio.Group>
          </Form.Item>
          </div>
            <Form.Item
              className='custom-required input-personal-number'
              label={t('PERSONAL_NUMBER')}
              name="personalNumber"
            >
              <CustomInputNumber
                className='custom-inputnumber'
                controls={false}
              />
            </Form.Item>
            <Button
              disabled={!personalNumber}
              className="optimise-btn start-onbording-btn"
              onClick={handleStartOnbording}
            >
              {t('SAVE')}
            </Button>
        </Form>
    </div>
  );
};

export default CategoryTypeOfAccount;

CategoryTypeOfAccount.propTypes = {
  t: PropTypes.func,
};
