import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import { ReactComponent as Decrease } from '../../assets/decrease-icon.svg';
import { ReactComponent as Increase } from '../../assets/increase-icon.svg';

import './style.scss';

const ModalFilterItemsFP = ({
  itemsFilter,
  setItemsFilter,
  openFilter,
  handleCloseFilter,
  selectedItemType
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');

  const [necessity, setNecessity] = useState({ min: 0, max: 0 });
  const [purpose, setPurpose] = useState({ min: 0, max: 0 });
  const [happiness, setHappiness] = useState({ min: 0, max: 0 });
  const [probability, setProbability] = useState({ min: 0, max: 0 });

  useEffect(() => {
    form.setFieldsValue();
  }, []);

  const handleDecrease = (target) => {
    if (target === 'necessity-min') {
      if (necessity.min > 0.099) {
        const min = Math.round((+necessity.min - 0.1) * 100) / 100;
        setNecessity({ ...necessity, min });
      }
    }
    if (target === 'necessity-max') {
      if (necessity.max > 0.099) {
        const max = Math.round((+necessity.max - 0.1) * 100) / 100;
        setNecessity({ ...necessity, max });
      }
    }
    if (target === 'purpose-min') {
      if (purpose.min > 0.099) {
        const min = Math.round((+purpose.min - 0.1) * 100) / 100;
        setPurpose({ ...purpose, min });
      }
    }
    if (target === 'purpose-max') {
      if (purpose.max > 0.099) {
        const max = Math.round((+purpose.max - 0.1) * 100) / 100;
        setPurpose({ ...purpose, max });
      }
    }
    if (target === 'happiness-min') {
      if (happiness.min > 0.099) {
        const min = Math.round((+happiness.min - 0.1) * 100) / 100;
        setHappiness({ ...happiness, min });
      }
    }
    if (target === 'happiness-max') {
      if (happiness.max > 0.099) {
        const max = Math.round((+happiness.max - 0.1) * 100) / 100;
        setHappiness({ ...happiness, max });
      }
    }
    if (target === 'probability-min') {
      if (probability.min > 0.099) {
        const min = Math.round((+probability.min - 0.1) * 100) / 100;
        setProbability({ ...probability, min });
      }
    }
    if (target === 'probability-max') {
      if (probability.max > 0.099) {
        const max = Math.round((+probability.max - 0.1) * 100) / 100;
        setProbability({ ...probability, max });
      }
    }
  };

  const handleIncrease = (target) => {
    if (target === 'necessity-min') {
      const min = Math.round((+necessity.min + 0.1) * 100) / 100;
      setNecessity({ ...necessity, min });
    }
    if (target === 'necessity-max') {
      const max = Math.round((+necessity.max + 0.1) * 100) / 100;
      setNecessity({ ...necessity, max });
    }

    if (target === 'purpose-min') {
      const min = Math.round((+purpose.min + 0.1) * 100) / 100;
      setPurpose({ ...purpose, min });
    }
    if (target === 'purpose-max') {
      const max = Math.round((+purpose.max + 0.1) * 100) / 100;
      setPurpose({ ...purpose, max });
    }
    if (target === 'happiness-min') {
      const min = Math.round((+happiness.min + 0.1) * 100) / 100;
      setHappiness({ ...happiness, min });
    }
    if (target === 'happiness-max') {
      const max = Math.round((+happiness.max + 0.1) * 100) / 100;
      setHappiness({ ...happiness, max });
    }
    if (target === 'probability-min') {
      const min = Math.round((+probability.min + 0.1) * 100) / 100;
      setProbability({ ...probability, min });
    }
    if (target === 'probability-max') {
      const max = Math.round((+probability.max + 0.1) * 100) / 100;
      setProbability({ ...probability, max });
    }
  };

  const handleInputChange = (event, target) => {
    const { value } = event.target;
    if (target === 'necessity-min') {
      setNecessity({ ...necessity, min: +value });
    }
    if (target === 'necessity-max') {
      setNecessity({ ...necessity, max: +value });
    }

    if (target === 'purpose-min') {
      setPurpose({ ...purpose, min: +value });
    }
    if (target === 'purpose-max') {
      setPurpose({ ...purpose, max: +value });
    }

    if (target === 'happiness-min') {
      setHappiness({ ...happiness, min: +value });
    }
    if (target === 'happiness-max') {
      setHappiness({ ...happiness, max: +value });
    }

    if (target === 'probability-min') {
      setProbability({ ...probability, min: +value });
    }
    if (target === 'probability-max') {
      setProbability({ ...probability, max: +value });
    }
  };

  const handleRest = () => {
    setNecessity({ min: 0, max: 0 });
    setPurpose({ min: 0, max: 0 });
    setHappiness({ min: 0, max: 0 });
    setProbability({ min: 0, max: 0 });
    setItemsFilter(null);
  };

  const handleCancel = () => {
    handleCloseFilter();
  };

  const onSubmit = () => {
    const configFilter = selectedItemType === 'expenses'
      ? ({
          necessity,
          purpose,
          happiness,
        })
      : ({
          probability
        });
    setItemsFilter(configFilter);
    handleCloseFilter();
  };

  return (
    <Modal
      width={408}
      className="filter-modal"
      centered
      open={openFilter}
      onCancel={handleCancel}
      footer={false}
      zIndex={1500}
    >
        {selectedItemType === 'expenses'
          ? (<div>
            <Row justify={'space-between'} style={{ marginBottom: '20px', marginTop: '40px' }}>
              <Col className="filter-control-title">{t('NECESSITY')}</Col>
              <Col className="filter-control-wrapper">
                <div className="controll-wrapper">
                  <div
                    className="controll-decrease"
                    onClick={() => handleDecrease('necessity-min')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-min-value"
                    value={necessity.min}
                    onChange={(event) =>
                      handleInputChange(event, 'necessity-min')
                    }
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('necessity-min')}
                  >
                    <Increase />
                  </div>
                </div>
                <span className="controll-delimiter">-</span>
                <div className="controll-wrapper">
                  <div
                    className="controll-increase"
                    onClick={() => handleDecrease('necessity-max')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-max-value"
                    value={necessity.max}
                    onChange={(event) =>
                      handleInputChange(event, 'necessity-max')
                    }
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('necessity-max')}
                  >
                    <Increase />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider className="filter-itest-divider" />
            <Row align={'top'} justify={'space-between'}>
              <Col className="filter-control-title">{t('PURPOSE')}</Col>
              <Col className="filter-control-wrapper">
                <div className="controll-wrapper">
                  <div
                    className="controll-decrease"
                    onClick={() => handleDecrease('purpose-min')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    className="control-min-value"
                    value={purpose.min}
                    min={0}
                    max={100}
                    onChange={(event) => handleInputChange(event, 'purpose-min')}
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('purpose-min')}
                  >
                    <Increase />
                  </div>
                </div>
                <span className="controll-delimiter">-</span>
                <div className="controll-wrapper">
                  <div
                    className="controll-increase"
                    onClick={() => handleDecrease('purpose-max')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    className="control-max-value"
                    value={purpose.max}
                    min={0}
                    max={100}
                    onChange={(event) => handleInputChange(event, 'purpose-max')}
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('purpose-max')}
                  >
                    <Increase />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider className="filter-itest-divider" />
            <Row align={'top'} justify={'space-between'}>
              <Col className="filter-control-title">{t('HAPPINESS')}</Col>
              <Col className="filter-control-wrapper">
                <div className="controll-wrapper">
                  <div
                    className="controll-decrease"
                    onClick={() => handleDecrease('happiness-min')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-min-value"
                    value={happiness.min}
                    onChange={(event) => handleInputChange(event, 'happiness-min')}
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('happiness-min')}
                  >
                    <Increase />
                  </div>
                </div>
                <span className="controll-delimiter">-</span>
                <div className="controll-wrapper">
                  <div
                    className="controll-increase"
                    onClick={() => handleDecrease('happiness-max')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-max-value"
                    value={happiness.max}
                    onChange={(event) => handleInputChange(event, 'happiness-max')}
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('happiness-max')}
                  >
                    <Increase />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider className="filter-itest-divider" />

          </div>)
          : (
            <div>
            <Row justify={'space-between'} style={{ marginBottom: '20px', marginTop: '40px' }}>
              <Col className="filter-control-title">{t('PROBABILITY')}</Col>
              <Col className="filter-control-wrapper">
                <div className="controll-wrapper">
                  <div
                    className="controll-decrease"
                    onClick={() => handleDecrease('probability-min')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-min-value"
                    value={probability.min}
                    onChange={(event) =>
                      handleInputChange(event, 'probability-min')
                    }
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('probability-min')}
                  >
                    <Increase />
                  </div>
                </div>
                <span className="controll-delimiter">-</span>
                <div className="controll-wrapper">
                  <div
                    className="controll-increase"
                    onClick={() => handleDecrease('probability-max')}
                  >
                    <Decrease />
                  </div>
                  <input
                    type={'number'}
                    min={0}
                    max={100}
                    className="control-max-value"
                    value={probability.max}
                    onChange={(event) =>
                      handleInputChange(event, 'probability-max')
                    }
                  />
                  <div
                    className="controll-increase"
                    onClick={() => handleIncrease('probability-max')}
                  >
                    <Increase />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider className="filter-itest-divider" />
            </div>
            )
        }
        <Row justify="space-between">
              <Col>
                <Button
                  className="portfolio-details-btn filter-reset"
                  onClick={handleRest}
                >
                  {t('RESET')}
                </Button>
              </Col>
              <Col>
                <Button
                  className="portfolio-details-btn filter-apply"
                  onClick={onSubmit}
                >
                  {t('APPLY')}
                </Button>
              </Col>
            </Row>
    </Modal>
  );
};

export default ModalFilterItemsFP;

ModalFilterItemsFP.propTypes = {
  itemsFilter: PropTypes.object,
  setItemsFilter: PropTypes.func,
  openFilter: PropTypes.bool,
  handleCloseFilter: PropTypes.func,
  selectedItemType: PropTypes.string,
};
