import React from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useTranslation } from 'react-i18next';

export const AreaRange = ({ data1, data2, data3, data4 }) => {
  const { t } = useTranslation('');
  const newDataCurrent = data3.map((i, index) => {
    return [i[0], Math.round(i[1]), Math.round(data1[index][1])];
  });
  const newDataOPt = data4.map((i, index) => {
    return [i[0], Math.round(i[1]), Math.round(data2[index][1])];
  });
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'arearange',
    },
    legend: {
      enabled: true,
      itemWidth: 200,
      itemMarginBottom: 10,
      itemStyle: {
        fontSize: '16px',
        color: '#4D4D4D'
      }

    },
    title: {
      text: null
    },
    xAxis: {
      title: {
        text: t('TIME_HORIZON_YEARS'),
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },
    yAxis: {
      title: {
        text: t('WEALTH_MKR'),
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },
    plotOptions: {
      series: {
        animation: true
      },
      arearange: {
        fillOpacity: 0.3
      }
    },
    tooltip: {
      crosshairs: true,
      valueSuffix: ' mkr',
      // shared: true,
      // headerFormat: 'Year: {point.x} <br/>',
      // pointFormat: '{point.y:,.2f}',
    },
    series: [
      {
        name: t('CURRENT_PORTFOLIO'),
        data: newDataCurrent,
        color: '#6367C3',
      },
      {
        name: t('OPTIMIZED_PORTFOLIO'),
        data: newDataOPt,
        color: '#FB7B34'
      },
    ],
  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

AreaRange.propTypes = {
  data1: PropTypes.array,
  data2: PropTypes.array,
  data3: PropTypes.array,
  data4: PropTypes.array,
};
