/* eslint-disable no-undef */
import { colorsCategory } from '../helpers/colors';

export const getPieData = (data, cashes) => {
  if (!data) return {};
  const getTotalSek = (id, amount) => {
    const total = cashes?.find(i => i.ID === id)?.latest_value_sek * amount;
    return total;
  };
  const dataTotal = data.reduce((acc, item) => acc + item.total_value, 0);
  let category = [];
  for (const item of data) {
    item?.portfolioContent?.concat(item?.unlistedContent)?.forEach(el => {
      const name = el.financialAssetCategory?.name;
      if (category.some(i => i?.name === name)) {
        category = category.map(i => i.name === name ? { ...i, y: i?.y + el.value } : i);
      } else if (name) {
        category.push(
          {
            name,
            y: el.value,
            color: colorsCategory[name] ? colorsCategory[name] : 'lightgrey',
          }
        );
      }
    });
    let cashesSum = 0;
    item?.portfolioCashes?.forEach(el => {
      cashesSum = cashesSum + getTotalSek(el.instrument_id, el.amount);
    });
    if (category.some(i => i?.name === 'Defensiva Tillgångar') && cashesSum) {
      category = category.map(i => i.name === 'Defensiva Tillgångar'
        ? { ...i, y: i?.y + cashesSum }
        : i);
    } else if (cashesSum) {
      category.push(
        {
          name: 'Defensiva Tillgångar',
          y: cashesSum,
          color: colorsCategory['Defensiva Tillgångar']
            ? colorsCategory['Defensiva Tillgångar']
            : colorsCategory.other,
        }
      );
    }
  }
  const sortRule = {
    Avkastningstillgångar: 1,
    Riskspridare: 2,
    'Defensiva Tillgångar': 3
  };
  const categoryRound = category.map(i => ({ ...i, y: Math.round(i.y * 100) / 100, sort: sortRule[i.name] })).sort((a, b) => a.sort - b.sort);
  const pieData = { category: categoryRound, dataTotal };
  return pieData;
};
