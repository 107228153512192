export const OnboardingSW = {
  WELCOME: 'Välkommen till Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'Viken typ av konto vill du öppna?',
  WANT_TO_START_A_PERSONAL:
    'Vill du starta ett privat konto eller ett företagskonto?',
  PRIVATE_CUSTOMER: 'Privatkund',
  BUSINESS_CUSTOMER: 'Företagskund',
  PRIVAT_ORGANISATION_NUMBER: 'Person-/organisationsnummer',
  PRIVAT_NUMBER: 'Personnummer',
  ORGANISATION_NUMBER: 'Organisationsnummer',
  ENTER_ORGANIZATION_NUMBER: 'Ange person-/organisationsnummer',
  MOVE_ON: 'Gå vidare',
  RFA19: 'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
  MOBILEID_HEADER: 'Starta BankID-appen på din telefon eller surfplatta. Tryck på QR-kodknappen i appen. Rikta kameran mot QR-koden nedan.',
  USE_BANKID: 'Använd BankID på den här enheten',
  THE_LOGIN_FAILED: 'Inloggningen misslyckades',
  LOGIN_FAILED_TEXT: 'Det gick inte att skanna QR-koden. Starta BankID-appen och skanna QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen behöver du installera den och beställa ett BankID från din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
  TRY_AGAIN: 'Försök igen',
  LOGIN_WITH_BANKID: 'Logga in med Testa BankID',
  TYPE_YOUR_SECURITY: 'Ange din säkerhetskod i BankID-appen och välj Identifiera.',
  WHAT_TYPE_OF_ACCOUNT_IS: 'Vilken typ av konto behövs för din kund?',
  WHAT_TYPE_ACCOUNT_OPEN: 'Vilken typ av konto vill du öppna?',
  DO_YOU_WANT_TO_START: 'Vill du starta ett personligt konto eller ett företagskonto?',
  PLEASE_PROVIDE_MEETING: 'Ange hur du möter din kund',
  WHAT_TYPE_MEETING: 'Vilken typ av möte planerar du?',
  ONLINE: 'Online',
  OFFLINE: 'Off-line',
  PLEASE_PROVIDE_MEETING_LINK: 'Ange en möteslänk eller planera det nu:',
  CONFIRM: 'Bekräfta',
  PLAN_MEETING: 'Planera möte',
  PLEASE_USE_SCRIPT: 'Använd Script för att göra mötet mer produktivt',
  YOUR_MEETING_DETAILS: 'Dina mötesdetaljer',
  MEETING_LINK: 'Möteslänk',
  COPIED: 'Kopierade!',
  WHEN: 'När',
  GUESTS: 'Gäster',
  SCRIPT: 'Manus',
  RESCHEDULE: 'Boka om',
  TO_MAKE_MEETING_TRANSCRIPTION: 'Det tar lite tid att göra mötestranskriberingen, vi meddelar dig när det är klart',
  PROCESSING: 'Bearbetning',
  MEETING_PROCESSING_LEVEL: 'Mötesbehandlingsnivå',
  MEETING_ID: 'Mötes-ID',
  MEETING_TRANSCRIBING: 'Möte transkriberar...',
  CHECK_YOUR_CLIENT_INFO: 'Kontrollera din kundinformation och Singing informationen',
  TYPE_OF_ACCOUNT: 'Typ av konto',
  CLIENT_INFORMATION: 'Kundinformation',
  PURPOSE_MATTER: 'Syfte & Materia',
  FINANCIAL_SITUATION: 'Ekonomisk situation',
  BASIC_PREREQUISITES_FOR_MANAGEMENT: 'Grundläggande förutsättningar för ledning',
  KNOW_YOUR_CLIENT: 'Känn din klient',
  RISK_TOLERANCE: 'Risktolerans',
  RISK_PROFILE: 'Riskprofil',
  EXPERIENCE_BACKGROUND: 'Erfarenhetsbakgrund',
  TYPE_OF_ADVICE: 'Typ av råd',
  ASSIGNMENT_AGREEMENT: 'Uppdragsavtal',
  SIGNING: 'Signering',
  SIGNING_CHECK_ONE: 'Jag samtycker härmed till att Citroneer behandlar personuppgifter som lämnas i samband med att detta avtal ingås. personuppgifterna används av Citroneer för öppnandet av depån och i andra fall i samband med vad som framgår av detta avtal*',
  SIGNING_CHECK_TWO: 'Jag har läst relevant informationsmaterial och faktablad.*',
  SIGNING_CHECK_THREE: 'Jag samtycker till att mina personuppgifter lagras och hanteras av Citroneer för administration.*',
  SIGNING_CHECK_FOUR: 'Jag har förstått att jag i undantagsfall riskerar att förlora hela eller delar av det investerade kapitalet.*',
  MEETING_INFORMATION: 'Mötesinformation',
  DATE_OF_COUNSELING: 'Datum för rådgivning',
  THE_EXECUTION_CONSULTANCY: 'Utförandet av konsultverksamheten',
  BASIC_INFORMATION: 'Grundläggande information',
  SURNAME: 'Efternamn',
  ENTER_SURNAME: 'Ange efternamn',
  SOCIAL_SECURITY_NUMBER: 'Personnummer',
  ENTER_SOCIAL_SECURITY_NUMBER: 'Ange personnummer',
  ONBOARDING_SCRIPT: 'Onboarding Script',
  FIND_ALL_QUESTIONS: 'Du hittar alla frågor här:',
  NEXT: 'Nästa',
};
