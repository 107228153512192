import React, { useCallback, useContext, useEffect } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { EditableContext } from './Instruments';
import { tickerRules } from '../../helpers/validation';
import { decimalSeparator } from '../../helpers/decimalSeparator';

const EditableCell = ({
  TBD,
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  assetClasses,
  editing,
  editingKey,
  currencyList,
  diversificationList,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex, editingKey]);

  const save = async (value = '', data = null) => {
    try {
      const values = await form.getFieldsValue();
      if (data && data?.id) {
        const newCategory = assetClasses?.find(el => el.ID === data.id);
        await form.setFieldValue(['asset_category', newCategory.financialAssetCategory.name]);
        handleSave({ ...record, ...values, asset_category: newCategory.financialAssetCategory.name });
      } else {
        handleSave({ ...record, ...values, ticker: values?.ticker?.toUpperCase(), });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderOptions = useCallback(() => {
    return restProps.assets.map(({ value, id }) => <Select.Option key={id} id={id} value={value}>{value}</Select.Option>);
  }, []);

  const getById = (id, field) => {
    return (
      field === 'currency_id'
        ? currencyList.find(i => i.value === id)?.label
        : diversificationList.find(i => i.value === id)?.label);
  };

  let validation;

  if (['fee'].includes(dataIndex)) {
    validation = [{
      required: true,
      message: `${title} is required.`
    },
    {
      transform: (value) => Number(value),
      type: 'number',
      message: `${title} has been type number`
    },
    () => ({
      validator (rule, value) {
        if (rule.field === 'fee' && value >= 0 && value !== '') {
          return Promise.resolve();
        }
        if (value > 999) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Max value 999');
        }
        return Promise.resolve();
      }
    })
    ];
  } else if (['ISIN'].includes(dataIndex)) {
    validation = [
      {
        required: true,
        message: 'Is required!'
      },
      () => ({
        validator (rule, value) {
          if (value && (value.length !== 12 || !/[A-Z]{2}[A-Z0-9]{10}/.test(value))) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Wrong ISIN format (ex. DE0123456789)');
          }
          return Promise.resolve();
        }
      })
    ];
  } else if (['ticker'].includes(dataIndex)) {
    validation = tickerRules;
  } else {
    validation = [{
      required: true,
      message: `${title} is required.`
    }];
  }
  let childNode;

  if (editable) {
    childNode = editing
      ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={validation}
        >
          {['asset_class'].includes(dataIndex)
            ? <Select
                style={{ width: 200 }}
                suffixIcon={(data) => data.open ? <UpOutlined/> : <DownOutlined />}
                placeholder="Select a asset class"
                optionFilterProp="children"
                onSelect={save}
                onBlur={save}
            >
              {renderOptions()}
            </Select>
            : dataIndex === 'currency_id' || dataIndex === 'diversification_id'
              ? <Select
                options={dataIndex === 'currency_id' ? currencyList : diversificationList}
                style={{ width: 80 }}
                onSelect={save}
                onBlur={save}
              />
              : dataIndex === 'latest_value' || dataIndex === 'fee'
                ? <InputNumber
                    onBlur={save}
                    decimalSeparator={decimalSeparator()}
                    min={0.01}
                    step={0.01}
                  />
                : <Input onPressEnter={save} onBlur={save}/>

          }
        </Form.Item>
        )
      : (
        <span
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
        >
          {dataIndex === 'currency_id' || dataIndex === 'diversification_id'
            ? getById(record[dataIndex], dataIndex)
            : children}
        </span>
        );
  }

  return <td {...restProps}>{childNode ?? children}</td>;
};

export default EditableCell;

EditableCell.propTypes = {
  TBD: PropTypes.bool,
  title: PropTypes.node,
  editable: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  assetClasses: PropTypes.array,
  currencyList: PropTypes.array,
  diversificationList: PropTypes.array,
  editing: PropTypes.bool,
  editingKey: PropTypes.string,
};
