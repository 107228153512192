import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { signUp } from '../../../data/store/user/userActions';
import {
  confirmPassRules,
  emailRules,
  nameRules,
  numberRules,
  passwordRules
} from '../../../helpers/validation';
import { Form, Input, Row, Radio, Space, Divider, Button } from 'antd';
import CustomRadioButtom from '../../CustomRadioButtom/CustomRadioButtom';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';

import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

import './style.scss';

const SignUpForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [type, setType] = React.useState('ADVISER');

  const handleValueChange = async () => {

  };

  const handleTermClick = () => {
    console.log('Terms');
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onSubmit = useCallback((fields) => {
    console.log('🚀 ~ onSubmit ~ fields:', fields);
    const { confirmPassword, agreement, firstName, lastName, ...data } = fields;
    dispatch(signUp({ ...data, role: type, firstName: firstName.trim(), lastName: lastName.trim() }));
  }, [dispatch]);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Row className='sign-up-form-wrapper'>
      <Form
        form={form}
        name="sign-up-form"
        layout="vertical"
        onFinish={onSubmit}
        className='sign-up-form'>
        <Row
          justify={'space-between'}
          align={'middle'}
          className='sign-up-form-check-wrap'>
          <h2 className='sign-up-check-lable'>{t('ROLE')}</h2>

          <Radio.Group onChange={onChangeType} value={type} className=''>
          <Space direction="horizontal">
            <CustomRadioButtom disabled value="CLIENT">{t('CLIENT')}</CustomRadioButtom>
            <CustomRadioButtom value="ADVISER">{t('ADVISOR')}</CustomRadioButtom>
          </Space>
          </Radio.Group>
        </Row>
        <Divider className='login-divider'/>
        <div className="sign-up-wrapper">
          <Form.Item
            label={t('FIRST_NAME')}
            name="firstName"
            rules={nameRules}
            required={false}
            className='custom-required'
            >
            <Input
              className='login-input'
              placeholder={t('FIRST_NAME')} />
          </Form.Item>

          <Form.Item
            label={t('LAST_NAME')}
            name="lastName"
            rules={nameRules}
            required={false}
            className='custom-required'
          >
            <Input
              className='login-input'
              placeholder={t('LAST_NAME')} />
          </Form.Item>
        </div>

        <div className="sign-up-wrapper">
          <Form.Item
            label={t('EMAIL')}
            name="email"
            rules={emailRules}
            required={false}
            className='custom-required'
          >
            <Input
              className='login-input'
              placeholder={t('EMAIL')}
              autoComplete='off'
              />
          </Form.Item>
        </div>

        <div className="sign-up-wrapper">
          <Form.Item
            label={t('PHONE')}
            name="phone"
            rules={numberRules}
            required={false}
            className='custom-required'
          >
            <Input
              className='login-input'
              placeholder={t('PHONE')}
              autoComplete='off'
              />
          </Form.Item>
        </div>

        <div className="sign-up-wrapper">
          <Form.Item
            label={t('PASSWORD')}
            name="password"
            rules={passwordRules}
          >
            <Input.Password
              placeholder={t('PASSWORD')}
              className='login-input'
              visible="true"
              autoComplete='off'
              onChange={handleValueChange}
              />
          </Form.Item>

          <Form.Item
            label={t('CONFIRM_PASSWORD')}
            name="passwordConfirm"
            dependencies={['password']}
            rules={confirmPassRules}
          >
            <Input.Password
              placeholder={t('CONFIRM_PASSWORD')}
              className='login-input'
              visible="true"
              autoComplete='off'
              onChange={handleValueChange}
              />
          </Form.Item>
        </div>
        <div>
            <h3 className='pass-setup-term-title'>{t('TERM_AND_CONDITIONS')}
            <Arrow className='terms-link' onClick={handleTermClick}/>
            </h3>
              <Form.Item name="agreement" valuePropName="checked">
                <CustomCheckbox
                  onChange={handleValueChange}>
                  {t('AGREEMENT_TEXT')}
                </CustomCheckbox>
              </Form.Item>
          </div>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type='submit'
              className='signup-submit-btn'
              onClick={() => form.submit()}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                !form.getFieldValue('agreement')
              }
            >
              {t('REGISTER')}
            </Button>
          )}
        </Form.Item>

      </Form>
    </Row>
  );
};

export default SignUpForm;
