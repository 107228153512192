/* eslint-disable camelcase */
import {
  CHANGE_TO_CURRENT,
  CHANGE_TO_DRAFT,
  CLEAR_PORTFOLIO_STATE,
  CREATE_PORTFOLIO_TASK_ANALYSIS,
  CREATE_PORTFOLIO_TASK_PROSPECTING,
  CREATE_REPORT,
  DELETE_PORTFOLIO_BY_ID,
  DOWNLOAD_REPORT,
  EXPORT_PORTFOLIO,
  EXPORT_SELECTED_PORTFOLIO,
  GET_ALL_PORTFOLIO,
  GET_ALL_REPORTS,
  GET_HISTORY_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
  IMPORT_PORTFOLIO,
  OPTIMIZATION_PORTFOLIO,
  PORTFOLIO_FINAL,
  SAVE_DRAFT_PORTFOLIO,
  SAVE_FULL_PORTFOLIO,
  SEARCH_PARAMS,
  GET_STEP_OPTIMISATION_PORTFOLIO,
  POST_STEP_OPTIMISATION_PORTFOLIO,
  SEND_FINANCIAL_PLAN,
  SEND_DATA_PDF_PORTFOLIO,
  SET_STEPING,
  CLEAR_PORTFOLIO_PROSPECTING,
  MAPPING_LINK_PORTFOLIO,
  DOWNLOAD_TEMP,
  GET_CANDIDATES,
  CLEAR_CANDIDATES,
  GET_TRANSACTIONS,
  CLEAR_PORTFOLIO_LIST,
  GET_AVAILABLE_PORTFOLIO_CODE,
  GET_PRE_CANDIDATE,
} from './portfolioActionTypes';

export const getAllPortfolio = (clientId) => ({
  type: GET_ALL_PORTFOLIO.ACTION,
  payload: clientId,
});

export const getPortfolioById = (data) => ({
  type: GET_PORTFOLIO_BY_ID.ACTION,
  payload: data,
});

export const saveDraftPortfolio = (data) => ({
  type: SAVE_DRAFT_PORTFOLIO.ACTION,
  payload: data,
});

export const saveFullPortfolio = (data) => ({
  type: SAVE_FULL_PORTFOLIO.ACTION,
  payload: data,
});

export const deletePortfolio = (data) => ({
  type: DELETE_PORTFOLIO_BY_ID.ACTION,
  payload: data,
});

export const searchParams = (params, include_in_ib = false) => ({
  type: SEARCH_PARAMS.ACTION,
  payload: { params, include_in_ib }
});

export const clearPortfolioState = () => ({
  type: CLEAR_PORTFOLIO_STATE.ACTION,
});

export const clearPortfolioList = () => ({
  type: CLEAR_PORTFOLIO_LIST.ACTION,
});

export const changeToDraft = () => ({ type: CHANGE_TO_DRAFT.ACTION });

export const setSteping = (data) => ({
  type: SET_STEPING.ACTION,
  payload: data,
});

export const changeToCurrent = () => ({ type: CHANGE_TO_CURRENT.ACTION });

export const exportPortfolio = (id) => ({
  type: EXPORT_PORTFOLIO.ACTION,
  payload: id,
});

export const exportSelectedPortfolio = (data) => ({
  type: EXPORT_SELECTED_PORTFOLIO.ACTION,
  payload: data,
});

export const importPortfolio = (data) => ({
  type: IMPORT_PORTFOLIO.ACTION,
  payload: data,
});

export const getHistoryByPortfolioId = (id) => ({
  type: GET_HISTORY_BY_PORTFOLIO_ID.ACTION,
  payload: id,
});

export const getAllReports = (data) => ({
  type: GET_ALL_REPORTS.ACTION,
  payload: data,
});

export const downloadReport = (reportId) => ({
  type: DOWNLOAD_REPORT.ACTION,
  payload: reportId,
});

export const createReport = (data) => ({
  type: CREATE_REPORT.ACTION,
  payload: data,
});

export const optimizationPortfolio = (id) => ({
  type: OPTIMIZATION_PORTFOLIO.ACTION,
  payload: id,
});

export const createPortfolioTaskAnalysis = (data) => ({
  type: CREATE_PORTFOLIO_TASK_ANALYSIS.ACTION,
  payload: data,
});

export const createPortfolioTaskProspecting = (data) => ({
  type: CREATE_PORTFOLIO_TASK_PROSPECTING.ACTION,
  payload: data,
});

export const clearPortfolioProspecting = (data) => ({
  type: CLEAR_PORTFOLIO_PROSPECTING.ACTION,
});

export const getStepOptimisationPortfolio = (data) => ({
  type: GET_STEP_OPTIMISATION_PORTFOLIO.ACTION,
  payload: data,
});

export const postStepOptimisationPortfolio = (data) => ({
  type: POST_STEP_OPTIMISATION_PORTFOLIO.ACTION,
  payload: data,
});

export const sendFinancialPlan = (data) => ({
  type: SEND_FINANCIAL_PLAN.ACTION,
  payload: data,
});

export const portfolioFinalrequest = (data) => ({
  type: PORTFOLIO_FINAL.ACTION,
  payload: data,
});

export const sendDataPDFportfolio = (data) => ({
  type: SEND_DATA_PDF_PORTFOLIO.ACTION,
  payload: data,
});

export const mappingEditPortfolio = (data) => ({
  type: MAPPING_LINK_PORTFOLIO.ACTION,
  payload: data,
});

export const downloadTEMP = () => ({ type: DOWNLOAD_TEMP.ACTION });

export const getCandidates = (portfolioId) => ({
  type: GET_CANDIDATES.ACTION,
  payload: portfolioId,
});

export const clearCandidates = () => ({
  type: CLEAR_CANDIDATES.ACTION,
});

export const getTransactions = (data) => ({
  type: GET_TRANSACTIONS.ACTION,
  payload: data,
});

export const getAvailablePortfolioCode = (id) => ({
  type: GET_AVAILABLE_PORTFOLIO_CODE.ACTION,
  payload: id,
});

export const getPreCandidate = (data) => ({
  type: GET_PRE_CANDIDATE.ACTION,
  payload: data,
});
