import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Table, Form, Row, Button, Col } from 'antd';

import {
  getAssets,
  getCurrency,
  getDiversification,
} from '../../data/store/instruments/instrumentsActions';

import EditableCell from './EditableCellInstruments';
import CustomSearchInput from '../CustomSearchInput/CustomSearchInput';
import { ReactComponent as Filter } from '../../assets/filter-icon.svg';

import './style.scss';
import ModalFilterInsrt from '../ModalFilterInsrt/ModalFilterInsrt';

export const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const Instruments = ({ columns, instruments, TBD, assetTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();
  const [form] = Form.useForm();

  const { assets } = useSelector((state) => state.instrumentsReducer);
  const currencyList = useSelector(
    (state) => state.instrumentsReducer.currencyList
  );
  // const diversificationList = useSelector((state) => state.instrumentsReducer.diversificationList);

  const [data, setData] = useState();
  const [, setPagination] = useState({ pageSize: 10 });
  const [filteredInstrument, setFilteredInstrument] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [filterCounter, setFilterCounter] = useState(null);

  useEffect(() => {
    form.setFieldsValue({ currency: 1 });
  }, [instruments]);

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getCurrency());
    dispatch(getDiversification());
  }, [dispatch]);

  useEffect(() => {
    if (instruments?.length) {
      const newData = instruments.map((el, index) => {
        return {
          key: uuidv4(),
          id: el.ID,
          instrument: TBD ? el.instrumentName : el.name,
          ISIN:
            el.isin === 'NOT_APPLICABLE'
              ? 'N/A'
              : TBD
                ? el.instrumentISIN
                : el.isin,
          ticker: el.ticker,
          fee: TBD ? el.instrumentFee : el.fee,
          latest_value: el.latest_value,
          currency_id: el?.currency_id || 1,
          diversification_id: el?.diversification_id,
          asset_class: TBD ? 'N/A' : el?.financialAsset?.name,
          asset_category: TBD
            ? 'TBD'
            : el?.financialAsset?.financialAssetCategory?.name,
          portfolioContentID: TBD ? el.portfolioContentID : null,
        };
      });
      setData(newData);
    } else setData([]);
  }, [instruments]);

  useEffect(() => {
    if (data?.length) {
      setFilteredInstrument(data);
    }

    return () => {
      setFilteredInstrument([]);
    };
  }, [data]);

  useEffect(() => {
    console.log('filterCounter', filterCounter);
  }, [filterCounter]);

  const filterProccessing = (config) => {
    const { assetClass, category, currency, fee, price } = config;
    const feeProccess = (value) => {
      if (fee.min === 0 && fee.max === 0) return true;
      if (!fee.max && value >= fee.min) return true;
      if (value >= fee.min && fee.max >= value) return true;
      return false;
    };
    const priceProccess = (value) => {
      if (price.min === 0 && price.max === 0) return true;
      if (!price.max && value >= price.min) return true;
      if (value >= price.min && price.max >= value) return true;
      return false;
    };
    const diversification = [];
    if (config.none) diversification.push(28);
    if (config.medium) diversification.push(29);
    if (config.hight) diversification.push(30);
    if (!diversification.length) return;
    const newInstr = data
      .filter((i) =>
        assetClass.includes('All') ? true : assetClass.includes(i?.asset_class)
      )
      .filter((i) =>
        category.includes('All') ? true : category.includes(i?.asset_category)
      )
      .filter((i) =>
        currency.includes('All') ? true : currency.includes(i?.currency_id)
      )
      .filter((i) => feeProccess(i.fee))
      .filter((i) => priceProccess(i.latest_value))
      .filter((i) =>
        diversification.length === 3
          ? true
          : diversification.includes(i?.diversification_id)
      );
    setFilteredInstrument(newInstr);
    setFilterCounter(newInstr?.length);
  };

  useEffect(() => {
    if (filter) {
      filterProccessing(filter);
    } else {
      setFilterCounter(null);
      if (data?.length) {
        setFilteredInstrument(data);
      }
    }
  }, [filter]);

  const getInstruments = useCallback(
    (page, pageSize) => {
      setPagination({ pageSize });
    },
    [instruments]
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleCreate = () => {
    history.push('/quant/asset/new-instrument');
  };

  const handleSearch = (event) => {
    const { value } = event?.target;
    if (value?.length > 2) {
      const searched = data?.filter(
        (i) =>
          i.instrument?.toLowerCase().includes(value?.toLowerCase()) ||
          i.ISIN?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredInstrument(searched);
    } else {
      setFilteredInstrument(data);
    }
  };

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <div className="top-btn-wrapper">
        <Button
          onClick={handleCreate}
          className="portfolio-details-btn optimise-btn"
        >
          {t('ADD_INSTRUMENT')}
        </Button>
      </div>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '19px', padding: '19px 40px 0' }}
      >
        <Col>
          <h2 className="instrument-header-title">
            {TBD ? t('INSTRUMENTS_REQUESTS') : t('INSTRUMENTS_TAB')}
          </h2>
        </Col>
        <Col className="instrument-button-wraper">
          <CustomSearchInput
            placeholder={t('SEARCH_NAME')}
            onChange={handleSearch}
          />
          {assetTab === 'list' && (
            <Button
              className="filter-btn"
              type="default"
              onClick={handeleFilter}
              icon={<Filter />}
              iconPosition="end"
            >
              {t('FILTER')}
              {filterCounter
                ? (
                <span className="filter-counter">{filterCounter}</span>
                  )
                : (
                    ''
                  )}
            </Button>
          )}
        </Col>
      </Row>
      <Table
        rowKey={(record) => record.id}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        style={{ width: '100%' }}
        components={components}
        bordered
        dataSource={filteredInstrument}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          onChange: getInstruments,
          total: filteredInstrument?.length,
        }}
      />
      <ModalFilterInsrt
        assets={assets}
        currencyList={currencyList}
        setFilter={setFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
      />
    </>
  );
};

export default Instruments;

Instruments.propTypes = {
  setNewData: PropTypes.func,
  columns: PropTypes.array,
  renderButtons: PropTypes.func,
  instruments: PropTypes.array,
  action: PropTypes.func,
  TBD: PropTypes.bool,
  handleChangeSorter: PropTypes.func,
  assetTab: PropTypes.string,
  sorter: PropTypes.string,
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
