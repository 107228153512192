import { useCallback } from 'react';

export const useBarData = (data, colorsCategory, portfolioTotal) => {
  const barData = useCallback(() => {
    if (!data) return [];

    const assetClassSums = data?.reduce((acc, el) => {
      const name = el.financialAsset?.name || 'TBD';
      const value = el?.value;
      if (acc[name]) {
        acc[name].value += value;
      } else {
        acc[name] = {
          name,
          value,
          color: colorsCategory[el?.financialAssetCategory?.name],
          categoryName: el.financialAssetCategory?.name
        };
      }
      return acc;
    }, {});
    return Object.entries(assetClassSums).map(([_, { name, value, color, categoryName }]) => {
      return {
        name,
        categoryName,
        y: Number((value / portfolioTotal * 100).toFixed(2)),
        color
      };
    });
  }, [data, portfolioTotal]);

  return barData;
};
