/* eslint-disable camelcase */
import { put, call } from '@redux-saga/core/effects';
import {
  CREATE_REPORT,
  DELETE_PORTFOLIO_BY_ID,
  DOWNLOAD_REPORT,
  EXPORT_PORTFOLIO,
  GET_ALL_PORTFOLIO,
  GET_ALL_REPORTS,
  GET_HISTORY_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
  IMPORT_PORTFOLIO,
  OPTIMIZATION_PORTFOLIO,
  SAVE_FULL_PORTFOLIO,
  SEARCH_PARAMS,
  CREATE_PORTFOLIO_TASK_ANALYSIS,
  CREATE_PORTFOLIO_TASK_PROSPECTING,
  GET_STEP_OPTIMISATION_PORTFOLIO,
  POST_STEP_OPTIMISATION_PORTFOLIO,
  PORTFOLIO_FINAL,
  SEND_FINANCIAL_PLAN,
  SEND_DATA_PDF_PORTFOLIO,
  CHANGE_TO_DRAFT,
  EXPORT_SELECTED_PORTFOLIO,
  MAPPING_LINK_PORTFOLIO,
  DOWNLOAD_TEMP,
  GET_CANDIDATES,
  CLEAR_CANDIDATES,
  GET_TRANSACTIONS,
  CLEAR_PORTFOLIO_PROSPECTING,
  GET_AVAILABLE_PORTFOLIO_CODE,
  GET_PRE_CANDIDATE,
} from './portfolioActionTypes';

import { PortfolioService } from '../../../services/PortfolioService';

export function * getAllPortfolio (action) {
  try {
    if (!action?.payload) throw new Error('Need argument userID');
    yield put({ type: GET_ALL_PORTFOLIO.REQUEST });
    const response = yield call(PortfolioService.getAll, action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ALL_PORTFOLIO.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ALL_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_PORTFOLIO.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * getPortfolioById (action) {
  const { portfolioId, isToEdit } = action.payload;
  try {
    yield put({ type: CLEAR_CANDIDATES.ACTION });
    yield put({ type: GET_PORTFOLIO_BY_ID.REQUEST });
    const response = yield PortfolioService.getById(portfolioId);
    if (response.status === 200) {
      yield put({
        type: GET_PORTFOLIO_BY_ID.SUCCESS,
        payload: response.data.portfolio || {},
      });
      if (isToEdit) {
        yield put({ type: CHANGE_TO_DRAFT.ACTION });
      }
    } else {
      yield put({
        type: GET_PORTFOLIO_BY_ID.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_PORTFOLIO_BY_ID.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * saveFullPortfolio (action) {
  try {
    const { userId, history, data, portfolioId, file, path } = action.payload;
    let responseImport = null;
    if (file) {
      yield put({ type: IMPORT_PORTFOLIO.REQUEST });
      responseImport = yield PortfolioService.portfolioImport(file);
      if (responseImport.status === 200) {
        yield put({
          type: IMPORT_PORTFOLIO.SUCCESS,
          payload: responseImport.data,
        });
      } else {
        yield put({
          type: IMPORT_PORTFOLIO.ERROR,
          payload: responseImport.response.data.message,
        });
      }
    }
    if (!file || responseImport.status === 200) {
      yield put({ type: SAVE_FULL_PORTFOLIO.REQUEST });
      const response = yield portfolioId
        ? PortfolioService.updatePortfolio(portfolioId, data)
        : PortfolioService.createPortfolioFull(data);

      if (response.status === 200) {
        yield put({
          type: SAVE_FULL_PORTFOLIO.SUCCESS,
          payload: response.data.portfolio,
        });
        yield put({
          type: GET_PORTFOLIO_BY_ID.ACTION,
          payload: { portfolioId: response.data.portfolio.ID },
        });
        yield put({
          type: GET_ALL_PORTFOLIO.ACTION,
          payload: userId,
        });
        if (!portfolioId) {
          history.push(
            `/clients/${userId}/portfolio/${response.data.portfolio.ID}/portfolio-information`
          );
        } else if (path) {
          history.push(path);
        }
      } else {
        yield put({
          type: SAVE_FULL_PORTFOLIO.ERROR,
          payload: response.response?.data?.message,
        });
      }
    }
  } catch (e) {
    yield put({
      type: SAVE_FULL_PORTFOLIO.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * deletePortfolio (action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { history, portfolioId, clientId } = action.payload;
    yield put({ type: DELETE_PORTFOLIO_BY_ID.REQUEST });
    if (portfolioId) {
      const response = yield PortfolioService.deleteById(portfolioId);
      if (response.status === 204 || response.status === 200) {
        history.push(`/clients/${clientId}/portfolio`);
        yield put({
          type: GET_ALL_PORTFOLIO.ACTION,
          payload: clientId,
        });
        yield put({
          type: DELETE_PORTFOLIO_BY_ID.SUCCESS,
          payload: portfolioId,
        });
      } else {
        yield put({
          type: DELETE_PORTFOLIO_BY_ID.ERROR,
          payload: response.response.data.message,
        });
      }
    }
  } catch (e) {
    yield put({
      type: DELETE_PORTFOLIO_BY_ID.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * searchParams (action) {
  try {
    yield put({ type: SEARCH_PARAMS.REQUEST });
    const { params, include_in_ib } = action.payload;
    const response = yield PortfolioService.search(params, include_in_ib);
    if (response.status === 200) {
      yield put({ type: SEARCH_PARAMS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: SEARCH_PARAMS.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: SEARCH_PARAMS.ERROR, payload: e.response.data.message });
  }
}

export function * exportPortfolio (action) {
  try {
    yield put({ type: EXPORT_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.portfolioExport(action.payload);
    const options = response.headers['content-disposition'].split('=');
    const data = response.data;
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = options[options.length - 1];
    link.click();
    if (response.status === 200) {
      yield put({ type: EXPORT_PORTFOLIO.SUCCESS });
    } else {
      yield put({
        type: EXPORT_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: EXPORT_PORTFOLIO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * exportSelectedPortfolio (action) {
  try {
    yield put({ type: EXPORT_SELECTED_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.selectedPortfolioExport(
      action.payload
    );
    const options = response.headers['content-disposition'].split('=');
    const data = response.data;
    const blob = new Blob([data], {
      type:
        action.payload.format.key === 'xlsx'
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8'
          : 'application/pdf ',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = options[options.length - 1];
    link.click();
    if (response.status === 200) {
      yield put({ type: EXPORT_SELECTED_PORTFOLIO.SUCCESS });
    } else {
      yield put({
        type: EXPORT_SELECTED_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: EXPORT_SELECTED_PORTFOLIO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * importPortfolio (action) {
  try {
    const { id, history } = action.payload;
    yield put({ type: IMPORT_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.portfolioImport(id);
    if (response.status === 200) {
      const portfolioID = response.data.ID;
      yield put({ type: IMPORT_PORTFOLIO.SUCCESS });
      history.push(`/clients/${id}/portfolio/${portfolioID}`);
    } else {
      yield put({
        type: IMPORT_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: IMPORT_PORTFOLIO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getAllReports (action) {
  try {
    yield put({ type: GET_ALL_REPORTS.REQUEST });
    const response = yield PortfolioService.getReports(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ALL_REPORTS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ALL_REPORTS.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_REPORTS.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * downloadReport (action) {
  try {
    yield put({ type: DOWNLOAD_REPORT.REQUEST });
    const response = yield PortfolioService.reportDownload(action.payload);
    console.log('🚀 ~ *downloadReport ~ response:', response);
    const options = response.headers['content-disposition'].split('=');
    const data = response.data;
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = options[options.length - 1];
    link.click();
    if (response.status === 200) {
      yield put({ type: DOWNLOAD_REPORT.SUCCESS });
    } else {
      yield put({
        type: DOWNLOAD_REPORT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DOWNLOAD_REPORT.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * createReport (action) {
  try {
    yield put({ type: CREATE_REPORT.REQUEST });
    const report = document.querySelector('#report');
    if (report.files[0].type !== 'application/pdf') {
      yield put({
        type: CREATE_REPORT.ERROR,
        payload: 'File format must be PDF',
      });
      return;
    }
    const response = yield PortfolioService.reportCreate(action.payload);
    if (response.status === 200) {
      const { clientId } = action.payload;
      yield put({ type: CREATE_REPORT.SUCCESS });
      yield put({ type: GET_ALL_REPORTS.ACTION, payload: clientId });
    } else {
      yield put({
        type: CREATE_REPORT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: CREATE_REPORT.ERROR, payload: e.response.data.message });
  }
}

export function * getHistoryByPortfolioId (action) {
  try {
    yield put({ type: GET_HISTORY_BY_PORTFOLIO_ID.REQUEST });
    const response = yield PortfolioService.getHistoryById(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_HISTORY_BY_PORTFOLIO_ID.SUCCESS,
        payload: response.data.portfolio,
      });
    } else {
      yield put({
        type: GET_HISTORY_BY_PORTFOLIO_ID.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_HISTORY_BY_PORTFOLIO_ID.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * optimizationRequest (action) {
  try {
    yield put({ type: OPTIMIZATION_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.optimizationPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: OPTIMIZATION_PORTFOLIO.SUCCESS,
        payload: { success: 'Portfolio sent successfully', info: null },
      });
    } else if (response.response.status === 409) {
      yield put({
        type: OPTIMIZATION_PORTFOLIO.SUCCESS,
        payload: {
          success: null,
          info: 'Request for optimization has already been sent to Admin',
        },
      });
    } else {
      yield put({
        type: OPTIMIZATION_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: OPTIMIZATION_PORTFOLIO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * createPortfolioTaskAnalysis (action) {
  console.log('🚀 ~ *createPortfolioTaskAnalysis ~ action:', action);
  try {
    yield put({ type: CREATE_PORTFOLIO_TASK_ANALYSIS.REQUEST });
    const response = yield PortfolioService.portfolioTaskAnalysis(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: CREATE_PORTFOLIO_TASK_ANALYSIS.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_PORTFOLIO_TASK_ANALYSIS.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_PORTFOLIO_TASK_ANALYSIS.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * createPortfolioTaskProspecting (action) {
  try {
    yield put({ type: CREATE_PORTFOLIO_TASK_PROSPECTING.REQUEST });
    const response = yield PortfolioService.portfolioTaskProspecting(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: CREATE_PORTFOLIO_TASK_PROSPECTING.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: CREATE_PORTFOLIO_TASK_PROSPECTING.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_PORTFOLIO_TASK_PROSPECTING.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * postStepOptimisationPortfolio (action) {
  const { history, path } = action.payload;
  try {
    yield put({ type: POST_STEP_OPTIMISATION_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.postStepOptimisationPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: POST_STEP_OPTIMISATION_PORTFOLIO.SUCCESS,
        payload: response.data,
      });
      if (path) history.push(path);
    } else {
      yield put({
        type: POST_STEP_OPTIMISATION_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: POST_STEP_OPTIMISATION_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}
export function * getStepOptimisationPortfolio (action) {
  const { portfolioId } = action.payload;
  try {
    yield put({ type: GET_STEP_OPTIMISATION_PORTFOLIO.REQUEST });
    const response = yield PortfolioService.getStepOptimisationPortfolio({
      portfolioId,
    });
    if (response.status === 200) {
      yield put({
        type: GET_STEP_OPTIMISATION_PORTFOLIO.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_STEP_OPTIMISATION_PORTFOLIO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_STEP_OPTIMISATION_PORTFOLIO.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * sendFinancialPlan (action) {
  try {
    yield put({ type: SEND_FINANCIAL_PLAN.REQUEST });
    const response = yield PortfolioService.sendFinancialPlan(action.payload);
    if (response.status === 200) {
      yield put({ type: SEND_FINANCIAL_PLAN.SUCCESS });
    } else {
      yield put({
        type: SEND_FINANCIAL_PLAN.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SEND_FINANCIAL_PLAN.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * portfolioFinalrequest (action) {
  try {
    yield put({ type: PORTFOLIO_FINAL.REQUEST });
    const response = yield PortfolioService.portfolioFinalrequest(
      action.payload
    );
    if (response.status === 200) {
      yield put({ type: PORTFOLIO_FINAL.SUCCESS, payload: response?.data });
    } else {
      yield put({
        type: PORTFOLIO_FINAL.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: PORTFOLIO_FINAL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * sendDataPDFportfolio (action) {
  const { history, clientID } = action.payload;
  try {
    yield put({ type: SEND_DATA_PDF_PORTFOLIO.REQUEST });
    history.replace(`/clients/${clientID}/documents`, { key: 'create' });
    const response = yield PortfolioService.sendDataPDFportfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({ type: GET_ALL_REPORTS.ACTION, payload: clientID });
      yield put({
        type: SEND_DATA_PDF_PORTFOLIO.SUCCESS,
        payload: response?.data?.id,
      });
      yield put({ type: CLEAR_PORTFOLIO_PROSPECTING.ACTION });
    } else {
      yield put({
        type: SEND_DATA_PDF_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SEND_DATA_PDF_PORTFOLIO.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * mappingEditPortfolio (action) {
  const { portfolioID, history, isToEdit = true } = action.payload;
  try {
    yield put({ type: MAPPING_LINK_PORTFOLIO.REQUEST });
    yield put({ type: GET_PORTFOLIO_BY_ID.REQUEST });
    const response = yield PortfolioService.getById(portfolioID);
    if (response.status === 200) {
      yield put({
        type: GET_PORTFOLIO_BY_ID.SUCCESS,
        payload: response.data.portfolio,
      });
      if (isToEdit) {
        yield put({ type: CHANGE_TO_DRAFT.ACTION });
      }
      history.replace(
        `/clients/${response.data.portfolio.clientID}/portfolio/${portfolioID}`
      );
      yield put({ type: MAPPING_LINK_PORTFOLIO.SUCCESS });
    } else {
      yield put({
        type: GET_PORTFOLIO_BY_ID.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_PORTFOLIO_BY_ID.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * downloadTEMP () {
  try {
    yield put({ type: DOWNLOAD_TEMP.REQUEST });
    const response = yield PortfolioService.downloadTEMP();
    const { data } = response;
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.click();
    if (response.status === 200) {
      yield put({ type: DOWNLOAD_TEMP.SUCCESS });
    } else {
      yield put({
        type: DOWNLOAD_TEMP.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: DOWNLOAD_TEMP.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getCandidates (action) {
  try {
    yield put({ type: CLEAR_CANDIDATES.ACTION });
    yield put({ type: GET_CANDIDATES.REQUEST });
    const response = yield PortfolioService.getCandidates(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_CANDIDATES.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CANDIDATES.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CANDIDATES.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getTransactions (action) {
  try {
    yield put({ type: GET_TRANSACTIONS.REQUEST });
    const response = yield PortfolioService.getTransactions(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_TRANSACTIONS.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_TRANSACTIONS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_TRANSACTIONS.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getAvailablePortfolioCode (action) {
  try {
    yield put({ type: GET_AVAILABLE_PORTFOLIO_CODE.REQUEST });
    const response = yield PortfolioService.getAvailablePortfolioCode(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_AVAILABLE_PORTFOLIO_CODE.SUCCESS,
        payload: response?.data?.portfolio_code,
      });
    } else {
      yield put({
        type: GET_AVAILABLE_PORTFOLIO_CODE.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_AVAILABLE_PORTFOLIO_CODE.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getPreCandidate (action) {
  try {
    yield put({ type: GET_PRE_CANDIDATE.REQUEST });
    const response = yield PortfolioService.getPreCandidate(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_PRE_CANDIDATE.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PRE_CANDIDATE.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_PRE_CANDIDATE.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}
