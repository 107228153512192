/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';
import { EditableContext } from './FinPlanTable';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';

const EditableCell = ({
  title,
  children,
  dataIndex,
  record,
  handleChangeSelect,
  portfolioList,
  ...restProps
}) => {
  const { t } = useTranslation('');
  const form = useContext(EditableContext);
  const { isLoading } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = () => {
    const values = form.getFieldsValue();
    console.log('🚀 ~ save ~ values:', values);
    const { id } = record;
    console.log('🚀 ~ save ~ id:', id);
    handleChangeSelect({ id, ...values });
    // try {
    // } catch (errInfo) {
    //   console.log('Save failed:', errInfo);
    // }
  };

  return (
    <td {...restProps}>
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        {dataIndex === 'portfolio_ids'
          ? (
            <CustomSelect
              disabled={isLoading}
              width={'100%'}
              className="custom-select"
              mode={'multiple'}
              removeIcon={null}
              maxTagCount='responsive'
              maxTagTextLength={12}
              placeholder={t('SELECT_PORTFOLIOS')}
              onBlur={save}
            >
            {portfolioList?.map((i) => {
              return (
                <Select.Option key={i.ID} value={i.ID}>
                  {i?.name}
                </Select.Option>
              );
            })}
          </CustomSelect>
            )
          : (
              <>{ children }</>
            )}
      </Form.Item>
    </td>
  );
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleChangeSelect: PropTypes.func,
  portfolioList: PropTypes.array,
};
