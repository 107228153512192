import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'antd';
import { OnboardingQustions } from '../data/OnboardingQuestions';

const ScriptItem = ({ t, questions, url, currentLang }) => {
  const history = useHistory();
  const [numberQuestion, setNumberQuestion] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    setNumberQuestion(0);
  }, [questions]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 7500, behavior: 'smooth' });
    }
  }, [numberQuestion]);

  const handleNextQuestion = () => {
    if (numberQuestion + 1 < questions?.set?.length) {
      setNumberQuestion(numberQuestion + 1);
    } else {
      const nextKey = OnboardingQustions?.find(i => i.order === questions?.order + 1)?.key;
      history.push(`${url}?category=${nextKey}`);
    }
  };
  return (
    <>
    <div ref={containerRef} className="onboarding-script-question">
      <div>
        {
          questions?.set?.slice(0, numberQuestion + 1)?.map(i => {
            console.log('item', i);
            return (
              <React.Fragment key={i.key}>
                <div className='onboarding-question-wrap'>
                  {i?.question[currentLang]}
                  {i?.options[currentLang] &&
                  <ul>
                    {i?.options[currentLang]?.map(i => <li key={uuidv4()}>{i}</li>)}
                  </ul>
                  }
                </div>
                {
                  i?.describe_other &&
                  i?.describe_other?.map(i => {
                    return (
                      <div key={uuidv4()} className='onboarding-question-other'>
                    {i[currentLang]}
                  </div>
                    );
                  })
                }
              </React.Fragment>
            );
          })
        }
      </div>
    </div>
    <div className='next-btn-wrapper'>
      <Button
        className="optimise-btn start-onbording-btn"
        onClick={handleNextQuestion}
      >
        {t('NEXT')}
      </Button>
    </div>

    </>
  );
};

export default ScriptItem;

ScriptItem.propTypes = {
  t: PropTypes.func,
  currentLang: PropTypes.string,
  url: PropTypes.string,
  questions: PropTypes.object,
};
