/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { deleteSuggestions, getSuggestionsPortfolio } from '../../data/store/clients/clientActions';

import { Button, Col, Row, Table } from 'antd';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';
import Container from '../../components/Container';

import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Eye } from '../../assets/eye-icon.svg';
import { ReactComponent as Download } from '../../assets/export-icon.svg';
import ColorData from './ColorData';
import dayjs from 'dayjs';
import ModalViewPP from './ModalViewPP';
import { downloadReport } from '../../data/store/portfolio/portfolioActions';
import ModalDeleteCandidat from './ModalDeleteCandidat';
import ModalApproveCandidat from './ModalApproveCandidat';
import ModalFilterCandidat from './ModalFilterCandidat';

const SuggestionsTable = ({ userId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { suggestions, isLoading } = useSelector(
    (state) => state.clientReducer
  );

  const [tableColumns, setTableColumns] = useState();
  const [filteredPortfolio, setFilteredPortfolio] = useState(null);
  const [filter, setFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCounter, setFilterCounter] = useState(null);
  const [viewReport, setViewReport] = useState('');
  const [modalDeleteCandidat, setModalDeleteCandidat] = useState(0);
  const [modalApproveCandidat, setModalApproveCandidat] = useState(0);

  useEffect(() => {
    dispatch(getSuggestionsPortfolio(userId));
    return () => {
      dispatch({ type: 'CLEAR_SUGGESTIONS_PORTFOLIO' });
    };
  }, []);

  const applyFilters = () => {
    if (!filter) return setFilteredPortfolio(suggestions);
    const { current_cvar, current_mean_return, current_volatility, ...rest } = filter;
    const filteredByDate = suggestions.filter(item => {
      const createDate = dayjs(item.create_date);
      if (!rest.create_start_date && !rest.create_end_date) {
        return true;
      }
      if (!rest.create_start_date) {
        return createDate.isBefore(rest.create_end_date, 'day') || createDate.isSame(rest.create_end_date, 'day');
      }
      if (!rest.create_end_date) {
        return createDate.isAfter(rest.create_start_date, 'day') || createDate.isSame(rest.create_start_date, 'day');
      }
      return (createDate.isAfter(rest.create_start_date, 'day') || createDate.isSame(rest.create_start_date, 'day')) &&
              (createDate.isBefore(rest.create_end_date, 'day') || createDate.isSame(rest.create_end_date, 'day'));
    });

    const argsMinMax = { current_cvar, current_mean_return, current_volatility };
    const filteredArgs = filteredByDate.filter((item) => {
      for (const key in argsMinMax) {
        const { min, max } = argsMinMax[key];
        const value = item[key];

        if (min === 0 && max === 0) {
          continue;
        }
        if (min !== 0 && max === 0) {
          if (value < min) {
            return false;
          }
        }
        if (min === 0 && max !== 0) {
          if (value > max) {
            return false;
          }
        }
        if (min !== 0 && max !== 0) {
          if (value < min || value > max) {
            return false;
          }
        }
      }
      return true;
    });
    let filteredByPortfolio = [];
    if (rest.portfolioList[0] === 'All') {
      filteredByPortfolio = filteredArgs;
    } else {
      filteredByPortfolio = filteredArgs.filter(i => rest.portfolioList.includes(i.name));
    }
    setFilteredPortfolio(filteredByPortfolio);
  };

  useEffect(() => {
    applyFilters();
  }, [filter]);

  useEffect(() => {
    if (suggestions) {
      setFilteredPortfolio(suggestions);
    }
  }, [suggestions]);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  useEffect(() => {
    const defaultColumns = [
      {
        title: t('PORTFOLIO_NAME'),
        dataIndex: 'name',
        className: 'suggestions-name',
        width: '',
        align: 'left',
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('CREATION_DATE'),
        dataIndex: 'create_date',
        className: 'suggestions-create-date',
        width: '',
        align: 'left',
        render: (value) => <><span>{dayjs(value).format('MMM DD  YYYY')}</span><br/><span>{dayjs(value).format('HH:mm A')}</span></>,
        sorter: (a, b) => textSort(a.create_date, b.create_date),
      },
      {
        title: t('RETURN'),
        dataIndex: 'current_mean_return',
        className: 'suggestions-return',
        width: '',
        align: 'left',
        render: (value) => <ColorData value={value} />,
        sorter: (a, b) => a.current_mean_return - b.current_mean_return,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('VOLATILITY'),
        dataIndex: 'current_volatility',
        className: 'suggestions-volatility',
        width: '',
        align: 'left',
        render: (value) => <ColorData value={value} />,
        sorter: (a, b) => a.current_volatility - b.current_volatility,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'CVar',
        dataIndex: 'current_cvar',
        className: 'suggestions-cvar',
        width: '',
        align: 'left',
        render: (value) => <ColorData value={value} />,
        sorter: (a, b) => a.current_cvar - b.current_cvar,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ACTIONS'),
        align: 'center',
        dataIndex: 'operation',
        width: '15%',
        render: (_, record) => {
          return (
            <div className="cell-button-wrapper">
              <Button
                className="action-btn advisor-action-btn approve"
                onClick={() => handlApproval(record.id)}
              >
                {t('APPROVE')}
              </Button>
              <Button type="text" onClick={() => hendlePreview(record.report_link)}>
                <Eye />
              </Button>
              <Button type="text" onClick={() => hendleDownload(record.repot_id)}>
                <Download />
              </Button>
              <Button type="text" onClick={() => handleDelete(record.id)}>
                <Delete />
              </Button>
            </div>
          );
        },
      },
    ];
    setTableColumns(defaultColumns);
  }, [suggestions]);

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSearch = (event) => {
    const { value } = event?.target;
    if (value?.length > 2) {
      const searched = suggestions?.filter(
        (i) =>
          i.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredPortfolio(searched);
    } else {
      setFilteredPortfolio(suggestions);
    }
  };

  const hendlePreview = (report_link) => {
    setViewReport(report_link);
  };

  const hendleDownload = (repot_id) => {
    dispatch(downloadReport(repot_id));
  };

  const handleDelete = (id) => {
    setModalDeleteCandidat(id);
  };

  const handlApproval = (id) => {
    setModalApproveCandidat(id);
  };

  const getCandidatNameById = useMemo(() => {
    return suggestions?.find(i => i.id === modalDeleteCandidat)?.name || '- - -';
  }, [suggestions, modalDeleteCandidat]);

  return (
    <Container widthP={100} mb="28px" padding="0">
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '19px', padding: '19px 40px 0' }}
      >
        <Col>
          <h2 className="instrument-header-title">
          {t('SUGGESTIONS')}
          </h2>
        </Col>
        <Col className="instrument-button-wraper">
          <CustomSearchInput
            placeholder={t('SEARCH_CODE')}
            onChange={handleSearch}
          />
          <Button
            className="filter-btn"
            type="default"
            disabled={isLoading}
            onClick={handeleFilter}
            icon={<Filter />}
            iconPosition="end"
          >
            {t('FILTER')}
            {filterCounter
              ? (
              <span className="filter-counter">{filterCounter}</span>
                )
              : (
                  ''
                )}
          </Button>
        </Col>
      </Row>
      <div className="suggestions-table">
          <Table
            rowKey={(record) => record.id}
            loading={isLoading}
            rowClassName={(_, index) =>
              index % 2 === 0
                ? 'holdings-table-row'
                : 'holdings-table-row is-odd'
            }
            columns={tableColumns}
            dataSource={filteredPortfolio}
            size="middle"
            bordered
            pagination={false}
          />
      </div>
      <ModalViewPP
        viewReport={viewReport}
        setViewReport={setViewReport}
      />
      <ModalDeleteCandidat
        userId={userId}
        text='DELETE_THIS_SUGGESTED'
        candidatType={'suggestions'}
        name={getCandidatNameById}
        modalDeleteCandidat={modalDeleteCandidat}
        setModalDeleteCandidat={setModalDeleteCandidat}
      />
      <ModalApproveCandidat
        userId={userId}
        modalApproveCandidat={modalApproveCandidat}
        setModalApproveCandidat={setModalApproveCandidat}
      />
      <ModalFilterCandidat
        type='suggestions'
        source={suggestions}
        setFilter={setFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
      />
    </Container>
  );
};

export default SuggestionsTable;

SuggestionsTable.propTypes = {
  userId: PropTypes.string,
  data: PropTypes.array,
};
