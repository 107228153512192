/* eslint-disable no-undef */
import { colorsCategoryOpt } from '../helpers/colors';

export const getPieDataOpt = (data) => {
  if (!data) return {};
  const dataTotal = data.reduce((acc, item) => acc + item.value_sek, 0);
  let assetCategory = [];

  data?.forEach(el => {
    const name = el.financialAssetCategory?.name;
    if (assetCategory.some(i => i?.name === name)) {
      assetCategory = assetCategory.map(i => i.name === name ? { ...i, y: i?.y + el.value_sek } : i);
    } else if (name) {
      assetCategory.push(
        {
          name,
          y: el.value_sek,
          color: colorsCategoryOpt[name] ? colorsCategoryOpt[name] : colorsCategoryOpt.other,
        }
      );
    }
  });
  const sortRule = {
    Avkastningstillgångar: 1,
    Riskspridare: 2,
    'Defensiva Tillgångar': 3
  };
  const categoryRound = assetCategory.map(i => ({ ...i, y: Math.round(i.y * 100) / 100, sort: sortRule[i.name] })).sort((a, b) => a.sort - b.sort);
  const pieData = { category: categoryRound, dataTotal };
  console.log('🚀 ~ getPieDataOpt ~ pieData:', pieData);
  return pieData;
};
