import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

// eslint-disable-next-line no-unused-vars
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OnboardingPoints } from './data/OnboardingPoints';
import { ReactComponent as Array } from './img/array-icon.svg';

import CategorySigning from './CategoryList/CategorySigning';
import CategoryTypeOfAccount from './CategoryList/CategoryTypeOfAccount';

import CategoryClientInformation from './CategoryList/CategoryClientInformation';
import './style.scss';

const CategoryItem = ({ t, name, status, selected }) => {
  return (
    <div className={selected ? 'category-item selected' : 'category-item'}>
      <div className="category-colunm">
        <div className="category-checkbx" />
        <p className="category-name">{t(name)}</p>
      </div>
      <div className="category-colunm">
        <Array />
      </div>
    </div>
  );
};

const OnboardingCategory = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  function useQuery () {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const category = query.get('category');

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ url:', url);
    console.log('🚀 ~ OnboardingCategory ~ category:', category);
    if (!category) {
      history.push(`${url}?category=signing`);
    };
  }, []);

  const [selectedCategory, setSelectedCategory] = useState({ value: 'Signing', label: 'SIGNING' });

  const { onbording } = useSelector((state) => state.clientReducer);
  console.log('🚀 ~ OnboardingSigning ~ onbording:', onbording);

  const handleSelectCategory = (value, label) => {
    console.log('🚀 ~ handleSelectCategory ~ value, label:', value, label);
    history.push(`${url}?category=${value}`);
    setSelectedCategory({ value, label });
  };

  return (
    <div className="profile-wrap onboarding-wrap" style={{ position: 'relative' }}>
      <div style={{ padding: '35px 24px', height: '100%', overflowY: 'auto' }}>
        <Container widthP={100} height={'800px'} padding="24px 0">
          <div className="onbording-card-wrapper">
            <div className="onbording-card card-left">
              <div className="onbording-step-wrapper step-signing">
                <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
                <p className="onbording-card-description ">
                  {t('CHECK_YOUR_CLIENT_INFO')}
                </p>
                <div className="finplan-category-list-wrapper onboarding-category-wrap">
                    {OnboardingPoints.map((i) => {
                      return (
                        <div
                          key={i.key}
                          onClick={() => handleSelectCategory(i.value, i.label)}
                        >
                          <CategoryItem
                            name={i.label}
                            selected={selectedCategory?.value === i.value}
                            t={t}
                          />
                        </div>
                      );
                    })}
                  </div>
              </div>
            </div>

            <div className="onbording-card card-right">
            {category === 'signing' && <CategorySigning t={t}/>}
            {category === 'type-of-account' && <CategoryTypeOfAccount t={t}/>}
            {category === 'client-information' && <CategoryClientInformation t={t}/>}
            </div>
          </div>
        </Container>
      </div>
      <MainFooter />
    </div>
  );
};

export default OnboardingCategory;

OnboardingCategory.propTypes = {
  url: PropTypes.string,
  accountInfo: PropTypes.object,
};
// t, name, status, selected
CategoryItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  status: PropTypes.string,
  selected: PropTypes.bool,
};
