/* eslint-disable camelcase */
import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';
import { EditableContext } from './Portfolio';
import { useSelector } from 'react-redux';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';
import CustomSelect from '../CustomSelect/CustomSelect';

const EditableCellCashes = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  dataCashes,
  setTarget,
  currencyList,
  ...restProps
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation('');
  const form = useContext(EditableContext);
  const cashesList = useSelector(state => state.instrumentsReducer.cashes.list);

  const avalibleCashes = useMemo(() => {
    const avalible = cashesList.filter((i) => dataCashes?.every((el) => el.name !== i.name));
    return avalible;
  }, [dataCashes, cashesList]);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = async (data) => {
    try {
      const values = await form.getFieldsValue();
      const { ID, currency_id } = cashesList.find(i => i.name === values?.name);
      const rate = currencyList.find(i => i.id === currency_id);
      handleSave({
        ...record,
        ...values,
        currency_id,
        instrument_id: ID,
        rate,
        financialAsset: 'Liquidity'
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {dataIndex === 'amount'
            ? (
            <CustomInputNumber
              className='custom-inputnumber'
              style={{ width: '100%' }}
              min={0}
              onBlur={save}
              onChange={save}
              onStep={save}
              onPressEnter={save}
              placeholder={record[dataIndex]}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            />
              )
            : dataIndex === 'name'
              ? (
            <CustomSelect
              className="custom-select"
              placeholder={t('SELECT_OPTION')}
              type="select"
              onSelect={save}
            >
              {avalibleCashes.map((i) => {
                return (
                  <Select.Option key={i.ID} value={i.name}>
                    {i.name}
                  </Select.Option>
                );
              })}
            </CustomSelect>
                )
              : (
            <td {...restProps} onClick={setTarget}>
              {children}
            </td>
                )}
        </Form.Item>
      </td>
    );
  } else {
    return (
      <td {...restProps} onClick={setTarget}>
        {children}
      </td>
    );
  }
};

export default EditableCellCashes;

EditableCellCashes.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  setTarget: PropTypes.func,
  dataCashes: PropTypes.array,
  currencyList: PropTypes.array,
};
