import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../components/Container';
import GearLoading from '../../components/Loading/GearLoading';
import { Col, Layout, Row, Select } from 'antd';
import {
  clearCandidates,
  getCandidates,
  getTransactions,
} from '../../data/store/portfolio/portfolioActions';
import TransactionTabs from '../../components/Tabs/TransactionTabs';
import { TransactionsTable } from './TransactionsTable';

import './styles.scss';
const { Header } = Layout;

const OrderInstructions = ({ userId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const {
    isLoading,
    portfolioList: { list },
    candidates,
    transactions,
  } = useSelector((state) => state.portfolioReducer);

  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [selectedCandidates, setSelectedCandidates] = useState('');
  const [options, setOptions] = useState([]);
  const [optionsCandidates, setOptionsCandidates] = useState([]);
  const [tabName, setTabName] = useState(1);
  const [curentTable, setCurentTable] = useState();

  useEffect(() => {
    return () => {
      dispatch(clearCandidates());
    };
  }, []);

  useEffect(() => {
    let data = [];
    if (list?.length) {
      data = list?.map((i) => ({
        value: i.ID,
        label: `"${i.name}" , code --- ${i.portfolio_code}`,
      }));
    }
    setOptions([
      { value: '', label: t('CHOOSE_PORTFOLIO'), disabled: true },
      ...data,
    ]);
  }, [list]);

  useEffect(() => {
    let data = [];
    if (candidates) {
      data = candidates?.map((i) => ({
        value: i.id,
        label: `"${i?.id}", total value: ${i?.total_value ?? '- - -'} `,
      }));
    }
    setOptionsCandidates([
      { value: '', label: t('SELECT_PORTFOLIO'), disabled: true },
      ...data,
    ]);
  }, [candidates]);

  useEffect(() => {
    if (transactions) {
      const data = tabName === 1 ? transactions.buy : transactions.sell;
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      let parseData;
      if (tabName === 1) {
        parseData = data?.map(i => {
          return { ...i, share: i.value / summ * 100 };
        });
      } else {
        parseData = data;
      }
      setCurentTable(parseData);
    }
  }, [transactions, tabName]);

  const updateData = () => {
    dispatch(getTransactions({ portfolioId: selectedPortfolio, candidatId: selectedCandidates }));
  };

  const handleSelect = (value) => {
    setSelectedPortfolio(value);
    dispatch(getCandidates(value));
  };

  const handleSelectCandidates = (value) => {
    setSelectedCandidates(value);
    dispatch(getTransactions({ portfolioId: selectedPortfolio, candidatId: value }));
  };

  return (
    <div className='order-instructions-wrapper'>
      <Header className='headerStyle'/>
      <Container id="order-instructions" className="portfolio-container">
        <h2 className="portfolio-container-title">{t('TRANSACTIONS')}</h2>
        <div className="transactions-wrapper">
          <Row justify={'start'} gutter={48}>
            <Col span={8}>
              <Select
                className="transactions-select"
                value={selectedPortfolio}
                onSelect={handleSelect}
                options={options}
              />
            </Col>
            <Col span={8}>
              <Select
                disabled={!candidates}
                className="transactions-select"
                value={selectedCandidates}
                onSelect={handleSelectCandidates}
                options={optionsCandidates}
              />
            </Col>
          </Row>
          {transactions
            ? (<TransactionTabs tabName={tabName} setTabName={setTabName} updateData={updateData}>
              <GearLoading loading={isLoading}>
                {transactions &&
                  <TransactionsTable data={curentTable} tabName={tabName}/>
                }
              </GearLoading>
            </TransactionTabs>)
            : (
              <GearLoading loading={isLoading}/>
              )
          }
        </div>
      </Container>
    </div>
  );
};

export default OrderInstructions;

OrderInstructions.propTypes = {
  userId: PropTypes.string,
};
