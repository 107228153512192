import React from 'react';

import PropTypes from 'prop-types';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import { useTranslation } from 'react-i18next';

import ProcessingPending from './ProcessingPending';
import './style.scss';

const OnboardingProcessing = ({ url, meeningId, status }) => {
  const { t } = useTranslation();

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
    <div style={{ padding: '35px 24px', height: '100%' }}>
      <Container widthP={100} height={'100%'} padding='24px 0'>
      <div className='onbording-card-wrapper'>
        <div className='onbording-card card-left'>
        <div className='onbording-step-wrapper'>
          <div className='onbording-card-number'>4</div>
          <h2 className='onbording-card-title'>
            {t('ONBOARDING')}
          </h2>
          <p className='onbording-card-description'>{t('TO_MAKE_MEETING_TRANSCRIPTION')}</p>
        </div>
        </div>

        <div className='onbording-card card-right'>
        <div className='card-right-wrap'>
          <div style={{ width: '327px', margin: '0 auto' }}>
            <h2 className='onbording-card-header complet-step'>
              {t('PROCESSING')}
            </h2>
            <p className='onbording-card-subtitle'>{t('MEETING_PROCESSING_LEVEL')}</p>
            <ProcessingPending
              url={url}
              interval={30}
              meeningId={meeningId}
              processing={status}
            />
          </div>
        </div>
        </div>
      </div>

      </Container>
    </div>
    <MainFooter />
    </div>
  );
};

export default OnboardingProcessing;

OnboardingProcessing.propTypes = {
  url: PropTypes.string,
  meeningId: PropTypes.number,
  status: PropTypes.string,
};
