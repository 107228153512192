import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useRouteMatch, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getOnboardingStatus } from '../../data/store/clients/clientActions';

import OnboardingStart from '../Onboarding/OnboardingStart';
import OnboardingPlanning from '../Onboarding/OnboardingPlanning';
import OnboardingPlanningComplete from '../Onboarding/OnboardingPlanningComplete';
import OnboardingProcessing from '../Onboarding/OnboardingProcessing';
import OnboardingScript from '../Onboarding/OnboardingScript';
import OnboardingCategory from '../Onboarding/OnboardingCategory';
import { useTranslation } from 'react-i18next';

const OnboardingRouting = ({ userId }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation('');

  const { onbording } = useSelector((state) => state.clientReducer);
  const [accountInfo, setAccountInfo] = useState(null);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  useEffect(() => {
    console.log('accountInfo', accountInfo);
  }, [accountInfo]);

  useEffect(() => {
    dispatch(getOnboardingStatus({ clientId: userId }));
  }, []);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ pathname:', pathname);
    console.log('🚀 ~ useEffect ~ url:', url);
    if (onbording?.status === '' && pathname !== `${url}/script`) {
      // history.push(`${url}/script`);
      history.push(`${url}/start`);
    }
    if (onbording?.status === 'planning') {
      history.push(`${url}/planning-complete`);
    }
    if (onbording?.status === 'processing') {
      history.push(`${url}/processing`);
    }
    if (onbording?.status === 'processing_done') {
      history.push(`${url}/processing-complete`);
    }
  }, [onbording]);

  return (
    <Switch>
      <Route path={`${url}/start`}>
        <OnboardingStart userId={userId} setAccountInfo={setAccountInfo} url={url} />
      </Route>
      <Route path={`${url}/planning`}>
        <OnboardingPlanning userId={userId} setAccountInfo={setAccountInfo} accountInfo={accountInfo} url={url}/>
      </Route>
      <Route path={`${url}/planning-complete`}>
        <OnboardingPlanningComplete userId={userId} setAccountInfo={setAccountInfo} accountInfo={accountInfo} url={url}/>
      </Route>
      <Route path={`${url}/script`}>
        <OnboardingScript
          userId={userId}
          currentLang={currentLang}
          />
      </Route>
      <Route path={`${url}/processing`}>
        <OnboardingProcessing
          userId={userId}
          meeningId={onbording?.id}
          url={url}/>
      </Route>
      <Route path={`${url}/processing-complete`}>
        <OnboardingCategory
          userId={userId}
          meeningId={onbording?.meeningId}
          status={onbording?.status}
          url={url}/>
      </Route>
    </Switch>
  );
};

export default OnboardingRouting;

OnboardingRouting.propTypes = {
  userId: PropTypes.string,
};
