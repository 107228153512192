import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, DatePicker } from 'antd';
import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';
import { ReactComponent as Copy } from '../../assets/copy-icon.svg';
import './style.scss';

const OnboardingPlanningComplete = ({ url }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { onbording } = useSelector((state) => state.clientReducer);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  const meetingStart = onbording?.start_date;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = dayjs(meetingStart).tz(userTimezone);

  const formattedDate = startDate.format('dddd MMM D, YYYY [⋅] h:mm A') +
  ' – ' +
  startDate.add(1, 'hour').format('h:mm A') +
  ` (${startDate.format('z')} - ${userTimezone})`;

  const [copySuccess, setCopySuccess] = useState(false);

  const guest = useMemo(() => {
    const guest = onbording?.members?.split(' ');
    return guest;
  }, [onbording]);

  const handleStartScript = () => {
    history.push(`${url}/script`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(onbording?.meeting_link)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch(err => {
        console.log('🚀 ~ handleCopy ~ err:', err);
        setCopySuccess(false);
      });
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
    <div style={{ padding: '35px 24px', height: '100%' }}>
      <Container widthP={100} height={'100%'} padding='24px 0'>
      <div className='onbording-card-wrapper'>
        <div className='onbording-card card-left'>
        <div className='onbording-step-wrapper'>
          <div className='onbording-card-number'>3</div>
          <h2 className='onbording-card-title'>
            {t('ONBOARDING')}
          </h2>
          <p className='onbording-card-description'>{t('PLEASE_USE_SCRIPT')}</p>
        </div>
        </div>
        <div className='onbording-card card-right'>
        <div className='card-right-wrap'>
          <div style={{ width: '327px', margin: '0 auto' }}>
            <h2 className='onbording-card-header complet-step'>
              {t('YOUR_MEETING_DETAILS')}
            </h2>
            <p className='onbording-card-subtitle'>{t('MEETING_LINK')}</p>
            <p className='onbording-card-meeting-link'>
              <a href={onbording?.meeting_link} target='_blank' className='' rel="noreferrer">
              {onbording?.meeting_link}
              </a>
              {!copySuccess ? <Copy onClick={handleCopy}/> : <span className='onbording-card-copied'>{t('COPIED')}</span>}
            </p>
            <p className='onbording-card-subtitle'>{t('WHEN')}</p>
            <p className='onbording-card-meeting-link'>{formattedDate}</p>
            <p className='onbording-card-subtitle'>{t('GUESTS')}</p>
            {
              guest.map((guest, index) => {
                return <p key={index} className='onbording-card-meeting-link'>{guest}</p>;
              })
            }
              <DatePicker
                name='newMeetingDate'
                className='date-picker-btn'
                showTime
                placeholder={t('RESCHEDULE')}
                suffixIcon={<Picker />}
                onChange={(value, dateString) => {
                  console.log('Selected Time: ', value);
                  console.log('Formatted Selected Time: ', dateString);
                }}
              />
          </div>
              <Button
                className="optimise-btn start-script-btn"
                onClick={handleStartScript}
              >
                {t('SCRIPT')}
              </Button>

        </div>
        </div>
      </div>

      </Container>
    </div>
    <MainFooter />
    </div>
  );
};

export default OnboardingPlanningComplete;

OnboardingPlanningComplete.propTypes = {
  url: PropTypes.string,
};
