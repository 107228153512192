import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Alert, Button } from 'antd';
import { ReactQrCode } from '@devmehq/react-qr-code';
import { getQRCodeBankID } from '../../data/store/user/userActions';
import { ReactComponent as Telephone } from './img/telephone.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';
import { API_URLS } from '../../constants/api_urls';
import { CLIENTS } from '../../constants/routes';
import { LOGIN_USER } from '../../data/store/user/userActionTypes';
import StorageService from '../../services/StorageService';
import SessionService from '../../services/SessionService';

import titleLogo from '../../assets/lt_logo.png';
import './style.scss';

const BankIDLogin = ({ remember }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  const { codeBankID } = useSelector((state) => state.userReducer);
  const [intervalID, setIntervalID] = useState(null);

  const [codeQR, setCodeQR] = useState('');
  const [alert, setAlert] = useState('');

  useEffect(() => {
    console.log('codeQR', codeQR); ;
  }, [codeQR]);

  useEffect(() => {
    dispatch(getQRCodeBankID());
  }, []);

  useEffect(() => {
    if (codeBankID?.qrs?.length > 0) {
      renderCode();
    }
  }, [codeBankID]);

  const collect = async () => {
    const resp = await axios(
      `${API_URLS.BANKID_LOGIN_COLLECT}?order_ref=${codeBankID.order_ref}`
    );
    let data;
    resp.status === 200 ? (data = resp.data) : (data = resp.response.data);
    return data;
  };
  const stopCollect = async () => {
    const resp = await axios.post(`${API_URLS.BANKID_CANCEL_COLLECT}`, {
      order_ref: codeBankID.order_ref,
    });
    return resp.data;
  };
  const stopInterval = (idInt) => {
    clearInterval(idInt);
  };
  const successAlert = (value) => {
    setAlert(value);
    setTimeout(() => {
      setAlert('');
    }, 3500);
  };

  const renderCode = () => {
    const offset = Math.round((Date.now() - codeBankID.start_time) / 1000);
    let startIndex = offset;
    const idInt = setInterval(code, 1000);
    setIntervalID(idInt);

    async function code () {
      if (startIndex > codeBankID.qrs?.length - 1) {
        dispatch({ type: 'CLEAR_QR_BANKID' });
        setIntervalID(null);
        stopInterval(idInt);
        setCodeQR('Try again');
      } else if (codeBankID?.qrs?.length) {
        const res = await collect();
        if (res?.hint_code === 'userSign') {
          setCodeQR('wait finger');
        } else if (!res?.hint_code && res?.status === 'complete') {
          successAlert(res.completion_data.user.name);
          stopInterval(idInt);
          setIntervalID(null);
          setCodeQR('');
          if (res?.ID) {
            if (remember) {
              StorageService.setItem('acc', res.token);
              StorageService.setItem('rfr', res.expire);
            } else {
              SessionService.setItem('acc', res.token);
              SessionService.setItem('rfr', res.expire);
            }
            dispatch({ type: LOGIN_USER.SUCCESS, payload: res });
            if (res.role !== 'CLIENT') {
              history.push(CLIENTS);
            } else {
              history.push('/');
            }
          }
          dispatch({ type: 'CLEAR_QR_BANKID' });
        } else if (res.status === 400) {
          stopInterval(idInt);
          setIntervalID(null);
          setCodeQR('');
          dispatch({ type: 'CLEAR_QR_BANKID' });
          dispatch({ type: 'SET_ERROR', payload: res.message });
        } else setCodeQR(codeBankID.qrs[startIndex]);
        startIndex += 1;
      }
    }
  };
  const closeBankID = () => {
    setCodeQR('');
    if (codeBankID?.order_ref) stopCollect();
    clearInterval(intervalID);
    dispatch({ type: 'CLEAR_QR_BANKID' });
    history.goBack();
  };

  return (
    <div className="login-form">
      {alert && <Alert message={`Success. ${alert} login`} type="success" />}
      <div className="login-form-title">
        <img src={titleLogo} alt="title logo" />
      </div>
      {codeQR && codeQR.includes('bankid.') && (
        <>
          <div className="modal-bankId-header">{t('MOBILEID_HEADER')}</div>
          <div className="modal-bankId-code-wrapper">
            {codeQR && <ReactQrCode value={codeQR} size={200} />}
          </div>
          <div className="modal-bankId-link">
            <a href="#">{t('USE_BANKID')}</a>
            <Arrow/>
          </div>
        </>
      )}
      {codeQR === 'Try again' && (
        <>
          <div className="modal-bankId-header">{t('THE_LOGIN_FAILED')}</div>
          <p className="modal-bankId-text">{t('LOGIN_FAILED_TEXT')}</p>
          <div className="modal-bankId-link">
            <button onClick={() => dispatch(getQRCodeBankID())}>
              {t('TRY_AGAIN')}
            </button>
          </div>
        </>
      )}
      {codeQR === 'wait finger' && (
        <>
          <div className="modal-bankId-header">{t('LOGIN_WITH_BANKID')}</div>
          <div className="modal-bankId-code-wrapper">
            <Telephone />
          </div>
          <p className="modal-bankId-text">{t('TYPE_YOUR_SECURITY')}</p>
        </>
      )}
      <Button
        iconPosition='end'
        className='modal-bankId-cancel w360'
        onClick={closeBankID}>Cancel
      </Button>
    </div>
  );
};

export default BankIDLogin;

BankIDLogin.propTypes = {
  remember: PropTypes.bool,
};
