import React from 'react';

import PropTypes from 'prop-types';

import { Radio } from 'antd';

import './style.scss';

const CustomRadioButtom = ({ children, value, className, ...rest }) => {
  const classN = `custom-radio-btn ${className}`;
  return (
    <Radio className={classN} value={value} {...rest}>
      {children}
    </Radio>
  );
};

export default CustomRadioButtom;

CustomRadioButtom.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  className: PropTypes.string
};
