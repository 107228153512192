export const OnboardingEN = {
  WELCOME: 'Welcome to Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'What type of account do you want to open?',
  WANT_TO_START_A_PERSONAL:
    'Do you want to start a personal account or a business account?',
  PRIVATE_CUSTOMER: 'Private client',
  BUSINESS_CUSTOMER: 'Corporate client',
  PRIVAT_ORGANISATION_NUMBER: 'Social security/organisation number',
  PRIVAT_NUMBER: 'Social security number',
  ORGANISATION_NUMBER: 'Organisation number',
  ENTER_ORGANIZATION_NUMBER: 'Enter social security/organization number',
  MOVE_ON: 'Move on',
  RFA19: 'Would you like to identify yourself or sign with a BankID on this computer or with a Mobile BankID?',
  MOBILEID_HEADER: 'Start the BankID app on your phone or tablet. Tap the QR code button in the app. Point the camera at the QR code below.',
  USE_BANKID: 'Use BankID on this device',
  THE_LOGIN_FAILED: 'The login failed',
  LOGIN_FAILED_TEXT: 'Failed to scan the QR code. Start the BankID app and scan the QR code. Make sure that the BankID app is up to date. If you don\'t have the BankID app, you need to install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.',
  TRY_AGAIN: 'Try again',
  LOGIN_WITH_BANKID: 'Log in with Test BankID',
  TYPE_YOUR_SECURITY: 'Type your security code in the BankID app and select Identify.',
  // new-part
  WHAT_TYPE_OF_ACCOUNT_IS: 'What type of account is needed for your client?',
  WHAT_TYPE_ACCOUNT_OPEN: 'What type of account do you want to open?',
  DO_YOU_WANT_TO_START: 'Do you want to start a personal account or a business account?',
  PLEASE_PROVIDE_MEETING: 'Please provide the way you meeting your client',
  WHAT_TYPE_MEETING: 'What type of meeting do you plan?',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  PLEASE_PROVIDE_MEETING_LINK: 'Please provide a meeting link or plan it now:',
  CONFIRM: 'Confirm',
  PLAN_MEETING: 'Plan meeting',
  PLEASE_USE_SCRIPT: 'Please use Script to make meeting more productive',
  YOUR_MEETING_DETAILS: 'Your meeting details',
  MEETING_LINK: 'Meeting link',
  COPIED: 'Copied!',
  WHEN: 'When',
  GUESTS: 'Guests',
  SCRIPT: 'Script',
  RESCHEDULE: 'Reschedule',
  TO_MAKE_MEETING_TRANSCRIPTION: 'It’s take some time to make meeting transcription, we notify you when it’s done',
  PROCESSING: 'Processing',
  MEETING_PROCESSING_LEVEL: 'Meeting processing level',
  MEETING_ID: 'Meeting ID',
  MEETING_TRANSCRIBING: 'Meeting transcribing...',
  CHECK_YOUR_CLIENT_INFO: 'Check your client info and Singing the information',
  TYPE_OF_ACCOUNT: 'Type of account',
  CLIENT_INFORMATION: 'Client information',
  PURPOSE_MATTER: 'Purpose & Matter',
  FINANCIAL_SITUATION: 'Financial situation',
  BASIC_PREREQUISITES_FOR_MANAGEMENT: 'Basic prerequisites for management',
  KNOW_YOUR_CLIENT: 'Know your client',
  RISK_TOLERANCE: 'Risk tolerance',
  RISK_PROFILE: 'Risk profile',
  EXPERIENCE_BACKGROUND: 'Experience background',
  TYPE_OF_ADVICE: 'Type of advice',
  ASSIGNMENT_AGREEMENT: 'Assignment agreement',
  SIGNING: 'Signing',
  SIGNING_CHECK_ONE: 'I hereby agree that Citroneer processes personal data provided in connection with entering into this agreement. the personal data is used by Citroneer for the opening of the depot and in other cases in connection with what appears in this agreement*',
  SIGNING_CHECK_TWO: 'I have read relevant information material and fact sheets.*',
  SIGNING_CHECK_THREE: 'I agree that my personal data is stored and handled by Citroneer for administration.*',
  SIGNING_CHECK_FOUR: 'I have understood that in exceptional cases I risk losing all or part of the invested capital.*',
  MEETING_INFORMATION: 'Meeting information',
  DATE_OF_COUNSELING: 'Date of counseling',
  THE_EXECUTION_CONSULTANCY: 'The execution of the consultancy',
  BASIC_INFORMATION: 'Basic information',
  SURNAME: 'Surname',
  ENTER_SURNAME: 'Enter surname',
  SOCIAL_SECURITY_NUMBER: 'Social security number',
  ENTER_SOCIAL_SECURITY_NUMBER: 'Enter social security number',
  ONBOARDING_SCRIPT: 'Onboarding Script',
  FIND_ALL_QUESTIONS: 'You can find all questions here:',
  NEXT: 'Next',
};
