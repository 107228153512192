import {
  ASSET_REQUEST,
  CREATE_INSTRUMENT,
  DELETE_INSTRUMENT,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL,
  GET_INSTRUMENTS_TBD,
  MAPPING_REQUEST,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT,
  CREATE_ASSET_CLASS,
  DELETE_ASSET_CLASS,
} from './instrumentsActionTypes';
import { RESET_ERROR } from '../helpers/auxiliaryActionTypes';
import { LOG_OUT } from '../user/userActionTypes';

const initialState = {
  instruments: { list: [], totalCount: 0 },
  cashes: { list: [], totalCount: 0 },
  withoutCashes: { list: [], totalCount: 0 },
  instrumentsTBD: { list: [], totalCount: 0 },
  instrumentsTBDPagination: { offset: 0, limit: 10 },
  mapingError: null,
  assets: [],
  assetCategories: [],
  currencyList: [],
  diversificationList: [],
  isLoading: false,
  isError: false,
  countResponse: 0,
};

const setInstrument = (data) => {
  const { list, totalCount, type } = data;
  if (type === 'cashes-only') {
    return { cashes: { list, totalCount } };
  } else if (type === 'without-cashes') {
    return { withoutCashes: { list, totalCount } };
  } else {
    return { instruments: { list, totalCount } };
  }
};

export const instrumentsReducer = (state = initialState, action) => {
  const creteAssetClass = (data) => {
    console.log('🚀 ~ creteAssetClass ~ data:', data);
    const newData = [...state?.assets, data];
    console.log('🚀 ~ creteAssetClass ~ newData:', newData);
    return newData;
  };

  const deleteAssetClass = (id) => {
    console.log('🚀 ~ creteAssetClass ~ id:', id);
    const newData = [...state?.assets];
    console.log('🚀 ~ creteAssetClass ~ newData:', newData);
    return newData.filter(i => i.ID !== id);
  };

  switch (action.type) {
    case GET_INSTRUMENTS_ALL.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_INSTRUMENTS_ALL.SUCCESS:
      return {
        ...state,
        ...setInstrument(action.payload),
        isLoading: false,
        isError: false,
      };

    case GET_INSTRUMENTS_ALL.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };

    case GET_ASSETS.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ASSETS.SUCCESS:
      return {
        ...state,
        assets: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_ASSETS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPDATE_FINANCIAL_ASSET.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_FINANCIAL_ASSET.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case UPDATE_FINANCIAL_ASSET.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPDATE_INSTRUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_INSTRUMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    }

    case UPDATE_INSTRUMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_ASSET_CATEGORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ASSET_CATEGORIES.SUCCESS:
      return {
        ...state,
        assetCategories: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_ASSET_CATEGORIES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      };

    case CREATE_INSTRUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_INSTRUMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        instruments: {},
        isError: false,
      };
    }

    case CREATE_INSTRUMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_INSTRUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_INSTRUMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    }

    case DELETE_INSTRUMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_REQUEST.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_REQUEST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    }

    case DELETE_REQUEST.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case LOG_OUT:
      return {
        instruments: [],
        assets: [],
        assetCategories: [],
        isLoading: false,
        isError: false,
      };

    case GET_INSTRUMENTS_TBD.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_INSTRUMENTS_TBD.ACTION:
      return {
        ...state,
        instrumentsTBDPagination: {
          ...state.instrumentsTBDPagination,
          ...action.payload,
        },
      };

    case GET_INSTRUMENTS_TBD.SUCCESS:
      return {
        ...state,
        instrumentsTBD: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_INSTRUMENTS_TBD.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ASSET_REQUEST.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ASSET_REQUEST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case ASSET_REQUEST.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CREATE_ASSET_CLASS.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CREATE_ASSET_CLASS.SUCCESS:
      return {
        ...state,
        assets: creteAssetClass(action.payload),
        isLoading: false,
        isError: false,
      };

    case CREATE_ASSET_CLASS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_ASSET_CLASS.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_ASSET_CLASS.SUCCESS:
      return {
        ...state,
        assets: deleteAssetClass(action.payload),
        isLoading: false,
        isError: false,
      };

    case DELETE_ASSET_CLASS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case MAPPING_REQUEST.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case MAPPING_REQUEST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        mapingError: null,
      };

    case MAPPING_REQUEST.ERROR:
      return {
        ...state,
        isLoading: false,
        mapingError: action.payload,
      };

    case GET_CURRENCY.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: state.countResponse ? state.isError : false,
        countResponse: state.countResponse ? ++state.countResponse : 1,
      };

    case GET_CURRENCY.SUCCESS:
      return {
        ...state,
        countResponse: state.countResponse - 1,
        isLoading: state.countResponse ? state.isLoading : false,
        isError: false,
        currencyList: action.payload,
      };

    case GET_CURRENCY.ERROR:
      return {
        ...state,
        countResponse: state.countResponse - 1,
        isLoading: state.countResponse ? state.isLoading : false,
        isError: action.payload,
      };

    case GET_DIVERSIFICATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: state.countResponse ? state.isError : false,
        countResponse: state.countResponse ? ++state.countResponse : 1,
      };

    case GET_DIVERSIFICATION.SUCCESS:
      return {
        ...state,
        countResponse: state.countResponse - 1,
        isLoading: state.countResponse ? state.isLoading : false,
        isError: false,
        diversificationList: action.payload,
      };

    case GET_DIVERSIFICATION.ERROR:
      return {
        ...state,
        countResponse: state.countResponse - 1,
        isLoading: state.countResponse ? state.isLoading : false,
        isError: action.payload,
      };

    default: {
      return state;
    }
  }
};
