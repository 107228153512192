export const OnboardingQustions = [
  {
    order: 1,
    key: 'type-of-account',
    set: []
  },
  {
    order: 2,
    key: 'client-information',
    set: [
      {
        key: 1,
        question: {
          en: 'Date of advice',
          sw: 'Datum för rådgivning'
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 2,
        question:
        {
          en: 'The meeting format of the advice',
          sw: 'Rådgivningens mötesform'
        },
        options:
        {
          en: ['Offline meeting', 'Online meeting', 'Phone meeting'],
          sw: ['Offlinemöte', 'Onlinemöte', 'Telefonmöte']
        }
      },
      {
        key: 3,
        question: {
          en: 'First name',
          sw: 'Förnamn',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 4,
        question: {
          en: 'Surname',
          sw: 'Efternamn',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 5,
        question: {
          en: 'Social security number',
          sw: 'Personnummer',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 6,
        question: {
          en: 'Postal address',
          sw: 'Postadress',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 7,
        question: {
          en: 'ZIP code',
          sw: 'Postnummer',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 8,
        question: {
          en: 'City',
          sw: 'Stad',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 9,
        question: {
          en: 'Country',
          sw: 'Land',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 10,
        question: {
          en: 'Phone',
          sw: 'Telefon',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 11,
        question: {
          en: 'Phone',
          sw: 'Telefon',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 12,
        question: {
          en: 'Phone',
          sw: 'Telefon',
        },
        options: {
          en: null,
          sw: null,
        }
      },
      {
        key: 13,
        question: {
          en: 'Email address',
          sw: 'E-postadress',
        },
        options: {
          en: null,
          sw: null,
        }
      },
    ]
  },
  {
    order: 3,
    key: 'purpose-matter',
    set: [
      {
        key: 1,
        question: {
          en: 'What is the purpose and goal of the investments?',
          sw: 'Vad är syftet och målet med investeringarna?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 2,
        question: {
          en: 'Do you have any "matters close to your heart" that you want to be considered when choosing investments?',
          sw: 'Har du några "ärenden nära ditt hjärta" som du vill ska beaktas när du väljer investeringar?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 3,
        question: {
          en: 'In addition to the return, are there qualitative goals with the investments?',
          sw: 'Utöver avkastningen, finns det kvalitativa mål med investeringarna?'
        },
        options: {
          en: ['None - Return is the focus', 'Basic Sustainability (ESG)',
            'Sustainability in depth (SRI)', 'Strong impact (Impact Investing)'],
          sw: ['Ingen - Återgång är i fokus', 'Basic Sustainability (ESG)',
            'Hållbarhet i djupet (SRI)', 'Stark påverkan (Impact Investing)']
        },
      },
      {
        key: 4,
        question: {
          en: 'Which statement below best describes your view on investments and sustainability issues?',
          sw: 'Vilket påstående nedan beskriver bäst din syn på investeringar och hållbarhetsfrågor?'
        },
        options: {
          en: ['My focus in investments is risk and return', 'I want to integrate sustainability aspects where possible without renouncing risk and return prospects',
            'Sustainability is a very important issue in my portfolio and I might consider lowering the risk and return outlook requirements to exclude certain industries'],
          sw: ['Mitt fokus på investeringar är risk och avkastning', 'Jag vill integrera hållbarhetsaspekter där det är möjligt utan att avsäga mig risk- och avkastningsutsikter',
            'Hållbarhet är en mycket viktig fråga i min portfölj och jag kan överväga att sänka kraven på risk och avkastningsutsikter för att utesluta vissa branscher']
        },
      },
      {
        key: 5,
        question: {
          en: 'Are there any other aspects of working with the portfolio that Citroneer should be aware of?',
          sw: 'Finns det några andra aspekter av att arbeta med portföljen som Citroneer bör vara medveten om?'
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 4,
    key: 'financial-situation',
    set: [
      {
        key: 1,
        question: {
          en: 'Income from employment',
          sw: 'Inkomst av anställning'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 2,
        question: {
          en: 'Other income',
          sw: 'Övriga inkomster'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 3,
        question: {
          en: 'Expenditure',
          sw: 'Utgifter'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 4,
        question: {
          en: 'Surplus',
          sw: 'Överskott'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 5,
        question: {
          en: 'Additional information about revenue and expenditures',
          sw: 'Ytterligare information om inkomster och utgifter'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 6,
        question: {
          en: 'Financial investments',
          sw: 'Finansiella investeringar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 7,
        question: {
          en: 'Cash and cash equivalents',
          sw: 'Likvida medel'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 8,
        question: {
          en: 'Pension savings',
          sw: 'Pensionssparande'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 9,
        question: {
          en: 'Other assets',
          sw: 'Övriga tillgångar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 10,
        question: {
          en: 'Additional information about financial assets',
          sw: 'Ytterligare information om finansiella tillgångar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 11,
        question: {
          en: 'Housing',
          sw: 'Hus'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 12,
        question: {
          en: 'Other fixed assets',
          sw: 'Övriga anläggningstillgångar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 13,
        question: {
          en: 'Additional information about fixed assets',
          sw: 'Ytterligare information om anläggningstillgångar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 14,
        question: {
          en: 'Housing',
          sw: 'Hus'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 15,
        question: {
          en: 'Other liabilities',
          sw: 'Övriga skulder'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 16,
        question: {
          en: 'Additional information about shoulder',
          sw: 'Ytterligare information om skuldra'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 17,
        question: {
          en: 'Are there elements that should not be covered by the advice?',
          sw: 'Finns det moment som inte bör omfattas av råden?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 18,
        question: {
          en: 'Capital not to be covered by the advice',
          sw: 'Kapital som inte omfattas av rådgivningen'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 19,
        question: {
          en: 'Investable capital',
          sw: 'Investerbart kapital'
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 5,
    key: 'basic-prerequisites-for-management',
    set: [
      {
        key: 1,
        question: {
          en: 'Is there a need to be able to liquidate the holdings at short notice?',
          sw: 'Finns det behov av att kunna avveckla innehavet med kort varsel?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 2,
        question: {
          en: 'Are there any thoughts on how future generations will share in the portfolio\'s returns that Citroneer should know about?',
          sw: 'Finns det några tankar om hur framtida generationer kommer att ta del av portföljens avkastning som Citroneer borde känna till?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 3,
        question: {
          en: 'Time horizon',
          sw: 'Tidshorisont'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 4,
        question: {
          en: 'Additional comment about time horizon',
          sw: 'Ytterligare kommentar om tidshorisont'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 5,
        question: {
          en: 'Buffer capital',
          sw: 'Buffertkapital'
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 6,
    key: 'know-your-client',
    set: [
      {
        key: 1,
        question: {
          en: 'Citizenship',
          sw: 'Medborgarskap'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 2,
        question: {
          en: 'Second citizenship',
          sw: 'Andra medborgarskap'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 3,
        question: {
          en: 'Tax residence',
          sw: 'Skattlig bostad'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 4,
        question: {
          en: 'Fill in the ID document control number',
          sw: 'Fyll i ID-handlingens kontrollnummer'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 5,
        question: {
          en: 'Fill in the expiry date of the ID document',
          sw: 'Fyll i ID-handlingens utgångsdatum'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 6,
        question: {
          en: 'Do you have any relationship with the US?',
          sw: 'Har du någon relation med USA?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 7,
        question: {
          en: 'What is your relationship with the US?',
          sw: 'Vad är ditt förhållande till USA?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 8,
        question: {
          en: 'Do you usually do transactions with foreign countries?',
          sw: 'Brukar du göra transaktioner med utlandet?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 9,
        question: {
          en: 'How large are your transactions with foreign countries?',
          sw: 'Hur stora är dina transaktioner med utlandet?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 10,
        question: {
          en: 'What is the purpose of your savings and/or investments?',
          sw: 'Vad är syftet med ditt sparande och/eller dina investeringar?'
        },
        options: {
          en: ['Savings/investments', 'Financial security', 'Pension', 'Private consumption', 'Gift to a close relative', 'Beneficiary', 'Other (describe)'],
          sw: ['Spara/investeringar', 'Finansiell säkerhet', 'Pension', 'Privat konsumtion', 'Gåva till en nära anhörig', 'Förmånstagare', 'Övrigt (beskriv)']
        },
        describe_other: [
          {
            en: 'If in the question "savings_purpose" the option "Other (describe)" was selected, enter here the description of the other option',
            sw: 'Om alternativet "Annat (beskriv)" valdes i frågan "sparmål" anger du här beskrivningen av det andra alternativet'
          }
        ]
      },
      {
        key: 11,
        question: {
          en: 'On whose behalf are you investing?',
          sw: 'För vems räkning investerar du?'
        },
        options: {
          en: ['On his own behalf', 'For someone else by power of attorney', 'Other (describe)'],
          sw: ['På hans egen vägnar', 'För någon annan genom fullmakt', 'Annan (beskriv)']
        },
        describe_other: [
          {
            en: 'If in the question "behalf" the option "Other (describe)" was selected, leave a comment on the power of attorney',
            sw: 'Om alternativet "Övrigt (beskriv)" valdes i frågan "för räkning", lämna en kommentar om fullmakten'
          },
          {
            en: 'If in the question "behalf" the option "Other (describe)" was selected, enter here the description of the other option',
            sw: 'Om alternativet "Annat (beskriv)" valdes i frågan "för räkning" anger du här beskrivningen av det andra alternativet'
          },
        ]
      },
      {
        key: 12,
        question: {
          en: 'What is your main occupation?',
          sw: 'Vilken är din huvudsakliga sysselsättning?'
        },
        options: {
          en: ['Employee', 'Self-employed', 'Retired', 'Student', 'Other (describe)'],
          sw: ['Anställd', 'Egenföretagare', 'Pensionerad', 'Student', 'Övrigt (beskriv)']
        },
        describe_other: [
          {
            en: 'If in the question "main_occupation" the option "Other (describe)" was selected, enter here the description of the other option',
            sw: 'Om i frågan "main_occupation" alternativet "Annat (beskriv)" valdes, skriv in beskrivningen av det andra alternativet här'
          },
        ]
      },
      {
        key: 13,
        question: {
          en: 'In which sector do you work?',
          sw: 'Inom vilken sektor arbetar du?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 14,
        question: {
          en: 'What is your approximate monthly income?',
          sw: 'Vad är din ungefärliga månadsinkomst?'
        },
        options: {
          en: ['0 - 20 000 kr', '20 001 - 50 000 kr', '50 001 - 100 000 SEK', 'Over SEK 100 000'],
          sw: ['0 - 20 000 kr', '20 001 - 50 000 kr', '50 001 - 100 000 SEK', 'Över 100 000 SEK']
        },
      },
      {
        key: 15,
        question: {
          en: 'Is it your money that will be transferred?',
          sw: 'Är det dina pengar som kommer att överföras?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 16,
        question: {
          en: 'Explain whose money will be transferred',
          sw: 'Förklara vems pengar som kommer att överföras'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 17,
        question: {
          en: 'Where do the money and/or securities you deposit with Citroneer come from?',
          sw: 'Varifrån kommer de pengar och/eller värdepapper du sätter in hos Citroneer?'
        },
        options: {
          en: ['Salary/pension', 'Savings/investments', 'Sale of a company', 'Inheritance/Gift', 'Sale of home or property', 'Other (describe)'],
          sw: ['Lön/pension', 'Spara/investeringar', 'Försäljning av företag', 'Arv/gåva', 'Försäljning av bostad eller fastighet', 'Övrigt (beskriv)']
        },
        describe_other: [
          {
            en: 'If in the question "money_origin" the option "Other (describe)" was selected, enter here the description of the other option',
            sw: 'Om alternativet "Övrigt (beskriv)" valdes i frågan "money_origin" anger du här beskrivningen av det andra alternativet'
          },
        ]
      },
      {
        key: 18,
        question: {
          en: 'From which bank/insurance company do you take capital for your investments?',
          sw: 'Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?'
        },
        options: {
          en: ['Bank or securities institution in Sweden', 'Insurance company in Sweden', 'Bank or securities institution in another EU/EEA country', 'Insurance company in another EU/EEA country', 'Bank or securities institution outside the EU/EEA', 'Insurance company outside the EU/EEA'],
          sw: ['Bank eller värdepappersinstitut i Sverige', 'Försäkringsbolag i Sverige', 'Bank eller värdepappersinstitut i annat EU/EES-land', 'Försäkringsbolag i annat EU/EES-land', 'Bank eller värdepappersinstitut utanför EU/EES ', 'Försäkringsbolag utanför EU/EES']
        },
      },
      {
        key: 19,
        question: {
          en: 'From which bank/insurance company do you take capital for your investments?',
          sw: 'Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?'
        },
        options: {
          en: ['Bank or securities institution in Sweden', 'Insurance company in Sweden', 'Bank or securities institution in another EU/EEA country', 'Insurance company in another EU/EEA country', 'Bank or securities institution outside the EU/EEA', 'Insurance company outside the EU/EEA'],
          sw: ['Bank eller värdepappersinstitut i Sverige', 'Försäkringsbolag i Sverige', 'Bank eller värdepappersinstitut i annat EU/EES-land', 'Försäkringsbolag i annat EU/EES-land', 'Bank eller värdepappersinstitut utanför EU/EES ', 'Försäkringsbolag utanför EU/EES']
        },
      },
      {
        key: 20,
        question: {
          en: 'What approximate value will you transfer when opening your account with Citroneer?',
          sw: 'Vilket ungefärligt värde kommer du att överföra när du öppnar ditt konto hos Citroneer?'
        },
        options: {
          en: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 SEK', 'Over SEK 5 000 000'],
          sw: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 SEK', 'Över 5 000 000 SEK']
        },
      },
      {
        key: 21,
        question: {
          en: 'Enter estimate of the approximate value that will be transferred when opening account with Citroneer',
          sw: 'Ange uppskattning av det ungefärliga värdet som kommer att överföras när du öppnar konto hos Citroneer'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 22,
        question: {
          en: 'What will the average value of your deposits be per year?',
          sw: 'Vad blir det genomsnittliga värdet av dina insättningar per år?'
        },
        options: {
          en: ['One-time deposit only when opening the account', '0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'Over 5 000 000 kr'],
          sw: ['Engångsinsättning endast när du öppnar kontot', '0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'Över 5 000 000 kr']
        },
      },
      {
        key: 23,
        question: {
          en: 'Enter estimate of the average value of deposits per year',
          sw: 'Ange uppskattning av medelvärdet av insättningar per år'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 24,
        question: {
          en: 'How often will deposits be made per year?',
          sw: 'Hur ofta görs insättningar per år?'
        },
        options: {
          en: ['0 - 10 times', '11 - 50 times', '51 - 100 times', 'More than 100 times'],
          sw: ['0 - 10 gånger', '11 - 50 gånger', '51 - 100 gånger', 'Fler än 100 gånger']
        },
      },
      {
        key: 25,
        question: {
          en: 'How often will withdrawals be made per year?',
          sw: 'Hur ofta görs uttag per år?'
        },
        options: {
          en: ['0 - 10 times', '11 - 50 times', '51 - 100 times', 'More than 100 times'],
          sw: ['0 - 10 gånger', '11 - 50 gånger', '51 - 100 gånger', 'Fler än 100 gånger']
        },
      },
      {
        key: 26,
        question: {
          en: 'How large individual transactions in financial instruments do you normally make?',
          sw: 'Hur stora enskilda transaktioner i finansiella instrument gör du normalt?'
        },
        options: {
          en: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'Over 5 000 000 kr'],
          sw: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'Över 5 000 000 kr']
        },
      },
      {
        key: 27,
        question: {
          en: 'Enter estimate of the amount of individual transactions in financial instruments',
          sw: 'Ange uppskattning av mängden enskilda transaktioner i finansiella instrument'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 28,
        question: {
          en: 'Are you yourself, or have you been, a person in a politically vulnerable position?',
          sw: 'Är du själv, eller har du varit, en person i en politiskt utsatt position?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 29,
        question: {
          en: 'What kind of PEP are you?',
          sw: 'Vilken typ av PEP är du?'
        },
        options: {
          en: ['The Head of State', 'Members of Parliament', 'The prime minister and other ministers in the government office', 'Judges of the Supreme Court and the Supreme Administrative Court', 'The Auditor General', 'The Riksbank\'s Executive Board', 'Ambassadors', 'Senior officers (general, lieutenant general, major general, admiral, vice admiral and rear admiral)', 'CEO or board member of state-owned companies', 'Person in the management of an international organization (board members, general secretary, etc.)', 'Member of political party\'s board'],
          sw: ['Statschefen', 'riksdagsledamöter', 'Statsministern och övriga ministrar i regeringskansliet', 'domare i Högsta domstolen och Högsta förvaltningsdomstolen', 'Riksrevisorn', 'Riksbankens direktion', 'Ambassadörer', 'Högre officerare (general, generallöjtnant, generalmajor, amiral, viceamiral och konteramiral)', 'VD eller styrelseledamot i statligt ägda företag', 'Person i ledningen för en internationell organisation ( styrelseledamöter, generalsekreterare etc.)', 'Medlem i det politiska partiets styrelse']
        },
      },
      {
        key: 30,
        question: {
          en: 'Do you have someone close to you who is, or has been, a politically exposed person?',
          sw: 'Har du någon nära dig som är, eller har varit, en politiskt utsatt person?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 31,
        question: {
          en: 'What type of PEP is your relative?',
          sw: 'Vilken typ av PEP är din släkting?'
        },
        options: {
          en: ['The Head of State',
            'Members of Parliament',
            'The prime minister and other ministers in the government office',
            'Judges of the Supreme Court and the Supreme Administrative Court',
            'The Auditor General',
            'The Riksbank\'s Executive Board',
            'Ambassadors',
            'Senior officers (general, lieutenant general, major general, admiral, vice admiral and rear admiral)',
            'CEO or board member of state-owned companies',
            'Person in the management of an international organization (board members, general secretary, etc.)',
            'Member of political party\'s board'],
          sw: ['Statschefen',
            'parlamentsledamöter',
            'Premiärministern och andra ministrar i regeringskansliet',
            'domare i Högsta domstolen och Högsta förvaltningsdomstolen',
            'Riksrevisorn',
            'Riksbankens direktion',
            'Ambassadörer',
            'Överofficerare (general, generallöjtnant, generalmajor, amiral, viceamiral och konteramiral)',
            'VD eller styrelseledamot i statligt ägda företag',
            'Person i ledningen för en internationell organisation (styrelseledamöter, generalsekreterare etc.)',
            'Medlem i det politiska partiets styrelse']
        },
      },
      {
        key: 31,
        question: {
          en: 'What is the annual expected return on capital?',
          sw: 'Vad är den årliga förväntade avkastningen på kapital?'
        },
        options: {
          en: ['In line with bank branch interest', 'Something above bank branch interest', 'A clearly higher yield than bank branch interest', 'There is a defined return target'],
          sw: ['I linje med bankkontorsränta', 'Något över bankkontorsränta', 'En klart högre avkastning än bankkontorsränta', 'Det finns ett definierat avkastningsmål']
        },
      },
      {
        key: 32,
        question: {
          en: 'Return target',
          sw: 'Återgå mål'
        },
        options: {
          en: ['3%', '5%', '7%', '9%', '11%', '15%', '20%'],
          sw: ['3%', '5%', '7%', '9%', '11%', '15%', '20%']
        },
      },
      {
        key: 33,
        question: {
          en: 'Which statement below best describes your investment philosophy?',
          sw: 'Vilket påstående nedan beskriver bäst din investeringsfilosofi?'
        },
        options: {
          en: ['Focus on controlling the risk of loss and thus accept a relatively low return.', 'Accepts some risk to get higher potential for returns.', 'Focus on returns and thus can accept large fluctuations in the value of the investments.'],
          sw: ['Fokusera på att kontrollera risken för förlust och acceptera därmed en relativt låg avkastning.', 'Accepterar viss risk för att få högre potential för avkastning.', 'Fokusera på avkastning och kan därmed acceptera stora fluktuationer i investeringarnas värde.']
        },
      },
      {
        key: 34,
        question: {
          en: 'To what extent is your view on investments influenced by market news and the media?',
          sw: 'I vilken utsträckning påverkas din syn på investeringar av marknadsnyheter och media?'
        },
        options: {
          en: ['Not significant and does not cause portfolio concern.', 'Negative news means a greater commitment to how the investments are going.', 'Negative news means that the portfolio changes to follow the experts advice.', 'Increased attention to the portfolio but acts with ice in the stomach.'],
          sw: ['Inte signifikant och orsakar inte portföljoro.', 'Negativa nyheter innebär ett större engagemang för hur investeringarna går.', 'Negativa nyheter innebär att portföljen ändras för att följa experternas råd.', 'Ökad uppmärksamhet på portföljen men verkar med is i magen.']
        },
      },
      {
        key: 35,
        question: {
          en: 'How is the your view of investments affected when the media is filled with negative market news and experts offer investment tips?',
          sw: 'Hur påverkas din syn på investeringar när media fylls av negativa marknadsnyheter och experter ger investeringstips?'
        },
        options: {
          en: ['Not significant and does not cause portfolio concern.', 'Negative news means a greater commitment to how the investments are doing.', 'Negative news means that the portfolio changes to follow the experts advice.', 'Increased attention to the portfolio but acts with ice in the stomach.'],
          sw: ['Inte betydande och orsakar inte portföljoro.', 'Negativa nyheter innebär ett större engagemang för hur investeringarna går.', 'Negativa nyheter innebär att portföljen ändras för att följa experternas råd.', 'Ökad uppmärksamhet på portföljen men verkar med is i magen.']
        },
      },
      {
        key: 36,
        question: {
          en: 'Suppose your portfolio is worth SEK 50 million today. How would you react if its value dropped to 45 million in 12 months?',
          sw: 'Anta att din portfölj är värd 50 miljoner kronor idag. Hur skulle du reagera om dess värde sjönk till 45 miljoner på 12 månader?'
        },
        options: {
          en: ['A willingness to reduce the risk in the portfolio and, for example, sell shares.', 'The portfolio is left intact, but reduce the dividend from the portfolio.', 'Contact the advisor to discuss the portfolio.', 'No one, it is natural to have large swings in the markets.', 'An opportunity to take advantage of and increase the risk and, for example, buy shares.'],
          sw: ['En vilja att minska risken i portföljen och till exempel sälja aktier.', 'Portföljen lämnas intakt, men minska utdelningen från portföljen', 'Kontakta rådgivaren för att diskutera portföljen.', 'Nej dels är det naturligt att ha stora svängningar på marknaderna.', 'En möjlighet att ta vara på och öka risken och till exempel köpa aktier.']
        },
      },
      {
        key: 37,
        question: {
          en: 'What will be your reaction if the value of your portfolio instead drops to 40 million in the next 12 months?',
          sw: 'Vad kommer din reaktion att bli om värdet på din portfölj istället sjunker till 40 miljoner under de kommande 12 månaderna?'
        },
        options: {
          en: ['No, big and small moves are a natural part of investing.', 'Contact the advisor to discuss the portfolio.', 'Small swings I can understand, but big falls definitely stress me out.'],
          sw: ['No, big and small moves are a natural part of investing.', 'Contact the advisor to discuss the portfolio.', 'Small swings I can understand, but big falls definitely stress me out.']
        },
      },
      {
        key: 38,
        question: {
          en: 'What will be your reaction if the Stockholm Stock Exchange rises by 20 percent while your portfolio only increases by 5 percent?',
          sw: 'Vad blir din reaktion om Stockholmsbörsen stiger med 20 procent medan din portfölj bara ökar med 5 procent?'
        },
        options: {
          en: ['Frustration of the missed opportunity and of falling behind the stock market.', 'Not worth mentioning, the Stockholm Stock Exchange has a higher risk than my portfolio.', 'Talk to the advisor to understand why the portfolio is underperforming.', 'None, the portfolio is balanced and will not follow individual market movements.'],
          sw: ['Frustration över det missade tillfället och att hamna efter börsen.', 'Inte värt att nämna, Stockholmsbörsen har en högre risk än min portfölj.', 'Prata med rådgivaren för att förstå varför portföljen underpresterar.', 'Inga, portföljen är balanserad och kommer inte att följa individuella marknadsrörelser.']
        },
      },
      {
        key: 39,
        question: {
          en: 'Do you compare your portfolio to anything/how do you evaluate your investments?',
          sw: 'Jämför du din portfölj med något/hur utvärderar du dina investeringar?'
        },
        options: {
          en: ['I compare my results with Swedish shares/Stockholmsbörsen.', 'I compare my results with a global stock portfolio.', 'I have a specific benchmark.', 'I compare my portfolio with a fund/manager.'],
          sw: ['Jag jämför mina resultat med svenska aktier/Stockholmsbörsen.', 'Jag jämför mina resultat med en global aktieportfölj.', 'Jag har ett specifikt riktmärke.', 'Jag jämför min portfölj med en fond/förvaltare.']
        },
      },
      {
        key: 40,
        question: {
          en: 'Comment on risk and return expectations',
          sw: 'Kommentera risk- och avkastningsförväntningar'
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 7,
    key: 'risk-tolerance',
    set: [
      {
        key: 1,
        question: {
          en: '',
          sw: ''
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 8,
    key: 'risk-profile',
    set: [
      {
        key: 1,
        question: {
          en: '',
          sw: ''
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
  {
    order: 9,
    key: 'experience-background',
    set: [
      {
        key: 1,
        question: {
          en: 'Do you have education and/or current or previous occupation that brought knowledge of how financial investments and markets work?',
          sw: 'Har du utbildning och/eller nuvarande eller tidigare yrke som ger kunskap om hur finansiella investeringar och marknader fungerar?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 2,
        question: {
          en: 'How long have you been trading securities?',
          sw: 'Hur länge har du handlat värdepapper?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 3,
        question: {
          en: 'What type of assets are you familiar with?',
          sw: 'Vilken typ av tillgångar är du bekant med?'
        },
        options: {
          en: ['Listed shares', 'Unlisted shares', 'UCITS Funds', 'AIF funds/special funds', 'Listed bonds', 'Unlisted bonds', 'Equity index bonds', 'Hedge funds', 'Certificate of Credit', 'Autocalls', 'ETF (Exchange Traded Funds)', 'Derivatives and commodity certificates'],
          sw: ['Noterade aktier', 'Onoterade aktier', 'UCITS-fonder', 'AIF-fonder/specialfonder', 'Noterade obligationer', 'Onoterade obligationer', 'Aktieindexobligationer', 'Hedgefonder', 'Kredittbevis', 'Autocalls', 'ETF (Exchange Traded Funds)', 'Derivat och råvarucertifikat']
        },
      },
      {
        key: 4,
        question: {
          en: 'Number of deals in the last three years',
          sw: 'Antal affärer under de senaste tre åren'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 5,
        question: {
          en: 'What knowledge & experience level of trading assets do you have?',
          sw: 'Vilken kunskap och erfarenhetsnivå av handelstillgångar har du?'
        },
        options: {
          en: ['None', 'Basic', 'Comprehensive'],
          sw: ['Ingen', 'Grundläggande', 'Omfattande']
        },
      },
      {
        key: 6,
        question: {
          en: 'Have you been trained on asset knowledge?',
          sw: 'Har du utbildats i tillgångskunskap?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 7,
        question: {
          en: 'Does the adviser believe that the customer has understood the risks of the product?',
          sw: 'Tror rådgivaren att kunden har förstått riskerna med produkten?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 8,
        question: {
          en: 'Provide additional comment on knowledge of different types of assets',
          sw: 'Ge ytterligare kommentarer om kunskap om olika typer av tillgångar'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 9,
        question: {
          en: 'What knowledge & experience level of managing portfolio do you have?',
          sw: 'Vilken kunskap och erfarenhetsnivå av att hantera portfölj har du?'
        },
        options: {
          en: ['None', 'Basic', 'Comprehensive'],
          sw: ['Ingen', 'Grundläggande', 'Omfattande']
        },
      },
      {
        key: 10,
        question: {
          en: 'Have you been trained on portfolio management?',
          sw: 'Har du utbildats i portföljförvaltning?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 11,
        question: {
          en: 'Does the advisor believe that the client understands portfolio management sufficiently well?',
          sw: 'Anser rådgivaren att kunden förstår portföljförvaltningen tillräckligt väl?'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 12,
        question: {
          en: 'Provide additional comment on knowledge of portfolio management',
          sw: 'Ge ytterligare kommentarer om kunskap om portföljförvaltning'
        },
        options: {
          en: null,
          sw: null
        },
      },
      {
        key: 13,
        question: {
          en: 'Can you lose all or part of your invested capital if you invest in financial instruments?',
          sw: 'Ge ytterligare kommentarer om kunskap om portföljförvaltning'
        },
        options: {
          en: null,
          sw: null
        },
      },
    ]
  },
];
