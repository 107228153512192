import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Button, DatePicker, Form, Input, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';

import { ReactComponent as Picker } from '../../../assets/date-picker-icon.svg';

import countryEN from '../../../helpers/countries/countryEN.json';
import countrySW from '../../../helpers/countries/countrySW.json';
import { textSort } from '../../../helpers/textSort';
import CustomPhoneInput from '../../../components/CustomPhoneInput/CustomPhoneInput';

const CategoryClientInformation = ({ t }) => {
  const [form] = Form.useForm();
  const { i18n } = useTranslation('');

  const [country, setCountry] = useState([]);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  useEffect(() => {
    let countryList = [];
    if (currentLang === 'en') {
      countryList = [...countryEN];
    }
    if (currentLang === 'sw') {
      countryList = [...countrySW];
    } else {
      countryList = [...countryEN];
    }
    setCountry(countryList);
  }, [currentLang]);

  useEffect(() => {}, [country]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {};
  return (
    <div className="card-right-wrap">
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="instrument-form"
        autoComplete="off"
      >
        <h2 className="onbording-card-header" style={{ marginBottom: '40px' }}>
          {t('MEETING_INFORMATION')}
        </h2>
        <Form.Item
          label={t('DATE_OF_COUNSELING')}
          className="custom-required"
          name={['consultation', 'date']}
          required={false}
        >
          <DatePicker
            placeholder="dd/mm/yyyy"
            className="finplan-datepicker onboarding-datepicker"
            suffixIcon={<Picker />}
          />
        </Form.Item>
        <Form.Item
          label={t('THE_EXECUTION_CONSULTANCY')}
          name={['consultation', 'type']}
          className="custom-required"
          required={false}
        >
          <CustomSelect className="custom-select">
            {[]?.map((i) => (
              <Select.Option key={uuidv4()} value={i}>
                {i}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <h2 className="onbording-card-header" style={{ margin: '40px 0' }}>
          {t('BASIC_INFORMATION')}
        </h2>
        <Form.Item
          label={t('FIRST_NAME')}
          name={['common', 'main_info', 'name']}
          className="custom-required"
        >
          <Input placeholder={t('ENTER_FIRST_NAME')} className="custom-input" />
        </Form.Item>
        <Form.Item
          label={t('SURNAME')}
          name={['common', 'main_info', 'last_name']}
          className="custom-required"
        >
          <Input placeholder={t('ENTER_SURNAME')} className="custom-input" />
        </Form.Item>
        <Form.Item
          label={t('SOCIAL_SECURITY_NUMBER')}
          name={['common', 'main_info', 'personal_number']}
          className="custom-required"
        >
          <Input
            placeholder={t('ENTER_SOCIAL_SECURITY_NUMBER')}
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          label={t('POSTAL_ADDRESS')}
          name={['common', 'main_info', 'address']}
          className="custom-required"
        >
          <Input
            placeholder={t('ENTER_POSTAL_ADDRESS')}
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          label={t('ZIP_CODE')}
          name={['common', 'main_info', 'zipcode']}
          className="custom-required"
        >
          <Input placeholder={t('ENTER_ZIP_CODE')} className="custom-input" />
        </Form.Item>
        <Form.Item
          label={t('CITY')}
          name={['common', 'main_info', 'city']}
          className="custom-required"
        >
          <Input placeholder={t('ENTER_CITY')} className="custom-input" />
        </Form.Item>
        <Form.Item
          label={t('COUNTRY_COMPANY')}
          name={['common', 'main_info', 'country']}
          className="custom-required"
        >
          <CustomSelect
            showSearch
            placeholder={t('ENTER_COUNTRY')}
            className="custom-select"
            labelInValue
            filterOption={(inputValue, option) =>
              inputValue.length > 1
                ? option.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
                : true
            }
            notFoundContent={<></>}
          >
            {country
              ?.slice()
              ?.sort((a, b) => textSort(a.name, b.name, 1))
              .map((item) => {
                return (
                  <Select.Option key={item.ID} value={item.name}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </CustomSelect>
        </Form.Item>
        <h2 className="onbording-card-header" style={{ margin: '40px 0' }}>
          {t('CONTACT_DETAILS')}
        </h2>
        <Form.Item
          label={`${t('MOBILE')}`}
          className="form-phone custom-required"
          name={['common', 'contact_data', 'phone']}
          rules={[
            {
              required: true,
              message: `${t('ENTER_PHONE_NUMBER')}`,
            },
          ]}
          required={false}
        >
        {/* PROBLEM!!! */}
          <CustomPhoneInput nameCode={['common', 'contact_data', 'phone_country_code']} />
        </Form.Item>
        <Form.Item
          style={{ margin: '24px 0 40px 0' }}
          label={t('MAILADRESS')}
          name={['common', 'contact_data', 'email']}
          className="custom-required"
        >
          <Input placeholder={t('ENTER_EMAIL_ADDRESS')} className="custom-input" />
        </Form.Item>
      </Form>
      <Button
        disabled={false}
        className="optimise-btn start-onbording-btn"
        onClick={handleSaveOnbording}
      >
        {t('SAVE')}
      </Button>
    </div>
  );
};

export default CategoryClientInformation;

CategoryClientInformation.propTypes = {
  t: PropTypes.func,
};

// client_info:
//   consultation:
//     date: -
//     type:
