/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import isEqual from 'lodash/isEqual';

import { Button, Col, Modal, Row } from 'antd';

import Container from '../../components/Container';

import { getPortfolioById, getStepOptimisationPortfolio, postStepOptimisationPortfolio } from '../../data/store/portfolio/portfolioActions';
import MainButton from '../../components/MainButton';
import PortfolioFixationItem from './PortfolioFixationItem';
import { getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';
import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';

import './style.scss';
import MainFooter from '../../components/MainFooter';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';

const PortfolioFixation = ({ userId, portfolioId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();

  const cashesList = useSelector(state => state.instrumentsReducer.cashes.list);
  const { isLoading, portfolioById, bias, constraints, fixations } = useSelector(
    (state) => state.portfolioReducer
  );
  const [instruments, setInstruments] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const reinitValue = useMemo(() => {
    if (!portfolioById) return [];
    const { portfolioCashes, portfolioContent, unlistedContent } = portfolioById;
    const allInstruments = portfolioContent?.concat(portfolioCashes, unlistedContent);

    const casheInstr = (id) => {
      const cash = cashesList?.find(i => i.ID === id);
      return ({
        name: cash?.name,
        financialAssetID: cash?.financialAssetID,
        financialAssetName: cash?.financialAsset?.name,
      });
    };
    const getInstrument = (id, instrument_id) => {
      const findInstr = allInstruments?.find(i => i.ID === id);
      return ({
        name: findInstr?.instrumentName ?? findInstr?.name ?? casheInstr(instrument_id)?.name,
        financialAssetID: findInstr?.financialAssetID ?? casheInstr(instrument_id)?.financialAssetID,
        financialAssetName: findInstr?.financialAssetName ?? findInstr?.financialAsset?.name ?? casheInstr(instrument_id)?.financialAssetName,
      });
    };
    const newFix = fixations.map(i => {
      return ({
        key: i.internal_id,
        ID: i.internal_id,
        financialInstrumentID: i.instrument_id,
        instrumentName: getInstrument(i.internal_id, i.instrument_id)?.name,
        financialAssetID: getInstrument(i.internal_id, i.instrument_id)?.financialAssetID,
        financialAssetName: getInstrument(i.internal_id, i.instrument_id)?.financialAssetName,
        unitsNumber: i.unitsNumber,
        fixed: i.fixed,
        is_approved_instrumen: i?.is_approved_instrumen ?? false,
      });
    });
    return newFix;
  }, [fixations, cashesList, portfolioById]);

  const handleChange = () => {
    const data = selectedInstruments
      .filter(i => i.ID && ((!i.is_approved_instrumen && i.fixed) || i.is_approved_instrumen))
      .map(i => {
        const { ID, financialInstrumentID, unitsNumber, fixed, is_approved_instrumen } = i;
        return (
          {
            instrument_id: financialInstrumentID,
            internal_id: ID,
            unitsNumber,
            fixed,
            is_approved_instrumen,
          }
        );
      });
    console.log('isEqual', isEqual(data, fixations));
    setNeedSave(!isEqual(data, fixations));
  };

  useEffect(() => {
    selectedInstruments && handleChange();
  }, [selectedInstruments]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  useEffect(() => {
    setSelectedInstruments(reinitValue);
  }, [fixations, cashesList, portfolioById]);

  const getCash = (id) => {
    const cash = cashesList?.find(i => i.ID === id);
    return ({
      name: cash?.name ?? '',
      financialAssetID: cash?.financialAssetID ?? '',
      financialAssetName: cash?.financialAsset?.name ?? ''
    });
  };

  const initValue = {
    ID: '',
    financialInstrumentID: null,
    instrumentName: '',
    financialAssetID: '',
    financialAssetName: '- - - - - - - - - ',
    unitsNumber: 0,
    fixed: 0,
    is_approved_instrumen: false,
  };

  useEffect(() => {
    if (!bias?.length || !constraints?.length || !fixations?.length) {
      dispatch(getStepOptimisationPortfolio({ portfolioId }));
    }
    if (!portfolioById?.length) dispatch(getPortfolioById({ portfolioId }));
    if (!cashesList?.length) dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' })); ;
  }, []);

  useEffect(() => {
    if (Object.keys(portfolioById)?.length) {
      const newListedData = portfolioById?.portfolioContent.map((i) => {
        return {
          ID: i?.ID,
          financialInstrumentID: i.financialInstrumentID,
          instrumentName: i.instrumentName,
          financialAssetID: i.financialAssetID,
          financialAssetName: i.financialAsset?.name,
          unitsNumber: i.unitsNumber,
          fixed: 0,
        };
      })?.filter(i => !!i?.financialAssetID);
      const newUnlistedData = portfolioById?.unlistedContent.map((i) => {
        return {
          ID: i?.ID,
          financialInstrumentID: i.financialInstrumentID,
          instrumentName: i.name,
          financialAssetID: i.financialAssetID,
          financialAssetName: i.financialAsset.name,
          unitsNumber: i.unitsNumber,
          fixed: 0,
        };
      });
      const newCashesData = portfolioById?.portfolioCashes?.map((i) => {
        return {
          ID: i?.ID,
          financialInstrumentID: i.instrument_id,
          instrumentName: getCash(i.instrument_id).name,
          financialAssetID: getCash(i.instrument_id).financialAssetID,
          financialAssetName: getCash(i.instrument_id).financialAssetName,
          unitsNumber: i?.amount,
          fixed: 0,
        };
      });
      const selectedKey = selectedInstruments.map(i => i?.ID);
      const arrayForSelect = newListedData.concat(newUnlistedData, newCashesData).filter(i => !selectedKey.includes(i?.ID));
      setInstruments(arrayForSelect);
    }
  }, [portfolioById]);

  const addInstrument = () => {
    const needAdd = selectedInstruments.every(i => i.ID && i.instrumentName);
    if (needAdd) {
      setSelectedInstruments([...selectedInstruments, { ...initValue, key: Date.now() }]);
    }
  };

  const handleChangeFix = (value, key) => {
    const newSelect = selectedInstruments.map(i => i.key === key ? { ...i, fixed: value } : i);
    setSelectedInstruments(newSelect);
  };

  // const editClose = () => {
  //   setSelectedInstruments(reinitValue);
  // };

  const renderSelectOption = useMemo(() => {
    const render = instruments.map(el => {
      return (
        {
          key: el.ID,
          value: el?.ID,
          label: el?.instrumentName
        }
      );
    });
    return render.filter(i => !selectedInstruments?.some(el => el?.ID === i.value));
  }, [instruments, selectedInstruments]);

  const handleSelect = (data, key) => {
    const instrument = instruments?.find(i => i.ID === data.value);
    const newData = {
      key,
      ID: data.value,
      financialInstrumentID: instrument?.financialInstrumentID,
      instrumentName: instrument?.instrumentName,
      financialAssetID: instrument?.financialAssetID,
      financialAssetName: instrument?.financialAssetName,
      unitsNumber: instrument?.unitsNumber,
      fixed: 0,
      is_approved_instrumen: false,
    };
    const newSelect = selectedInstruments.map(i => i.key === key ? newData : i);
    setSelectedInstruments(newSelect);
  };

  const handleDelete = (key) => {
    const newSelect = selectedInstruments.filter(i => i.key !== key);
    setSelectedInstruments(newSelect);
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    dispatch(getStepOptimisationPortfolio({ portfolioId }));
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    const data = selectedInstruments
      .filter(i => i.ID && ((!i.is_approved_instrumen && i.fixed) || i.is_approved_instrumen))
      .map(i => {
        const { ID, financialInstrumentID, unitsNumber, fixed, is_approved_instrumen } = i;
        return (
          {
            instrument_id: financialInstrumentID,
            internal_id: ID,
            unitsNumber,
            fixed,
            is_approved_instrumen,
          }
        );
      });
    dispatch(postStepOptimisationPortfolio({ portfolioId, data: { fixations: data }, history, path: saveAlert.path }));
    // editClose();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  const handleReset = () => {
    setSelectedInstruments(reinitValue);
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
  };

  return (
    <div className='wrap'>
    <div>
      <div className="top-btn-wrapper">
        <Button
          // disabled={!needSave}
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!needSave}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-edit-save"
        >
          {t('SAVE')}
        </Button>
      </div>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('FIXATION')}</h2>
      </div>
      <div className='portfolio-fixation'>
        <Container widthP={100} mb='48px' padding='0 0 24px'>
          <Row className="header-wrapper fixation-header-wrapper">
            <h2 className="header-title fixation-header-title">{t('INSTRUMENTS')}</h2>
          </Row>
          <div className='grey-wrapper'>
            <Row className="table-header-wrapper">
              <Col style={{ flexBasis: '25%' }} className='fixation-table-header'>
                {t('ASSET_CLASS')}
              </Col>
              <Col style={{ flexBasis: '29.16%' }} className='fixation-table-header'>
                {t('INSTRUMENTS')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '8.33%' }}>
                {t('NO_SHARES')}
              </Col>
              <Col span={3} className='fixation-table-header'>
                {t('NO_SHARES_TO_FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '8.33%' }}>
                {t('FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '8.33%' }}>
                {t('APPROVED_INSTRUMENT')}
              </Col>
              <Col style={{ flexBasis: '8.33%' }}>
              {' '}
              </Col>
            </Row>
          </div>
          <div className='investor_bias-wrapper'>
            {selectedInstruments?.map((i) => {
              return (
                <PortfolioFixationItem
                  key={i.key}
                  i={i}
                  selectedInstruments={selectedInstruments}
                  setSelectedInstruments={setSelectedInstruments}
                  handleSelect={handleSelect}
                  renderSelectOption={renderSelectOption}
                  handleChangeFix={handleChangeFix}
                  handleDelete={handleDelete}
                  cashesList={cashesList}
                />
              );
            })
            }

            <Row justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn"
                onClick={() => addInstrument()}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
            </Row>

          </div>
        </Container>
      </div>
    </div>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
      <MainFooter />
    </div>
  );
};

export default PortfolioFixation;

PortfolioFixation.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
