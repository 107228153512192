/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { downloadPdfReport, getAllImplementedPortfolio, getCandidatTransactions } from '../../data/store/clients/clientActions';

import { Button, Col, Row, Spin, Table } from 'antd';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';
import Container from '../../components/Container';

import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import { ReactComponent as Eye } from '../../assets/eye-icon.svg';
import { ReactComponent as Download } from '../../assets/export-icon.svg';
import { ReactComponent as Link } from '../../assets/link-icon.svg';

import dayjs from 'dayjs';
import ModalFilterCandidat from './ModalFilterCandidat';
import ModalViewTransaction from './ModalViewTransaction';

const AdminImplementedTable = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { implemented, isLoading, isDownload } = useSelector((state) => state.clientReducer);
  const advisors = useSelector((state) => state.advisorReducer.advisors.list);
  const clients = useSelector((state) => state.clientReducer?.users?.list);

  const [tableColumns, setTableColumns] = useState();
  const [filteredPortfolio, setFilteredPortfolio] = useState([]);
  const [filter, setFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filterCounter, setFilterCounter] = useState(null);
  const [openModalTransaction, setOpenModalTransaction] = useState('');

  useEffect(() => {
    dispatch(getAllImplementedPortfolio());
    return () => {
      dispatch({ type: 'CLEAR_IMPLEMENTED_PORTFOLIO' });
    };
  }, []);

  const applyFilters = () => {
    if (!filter) return setFilteredPortfolio(implemented);
    const { current_cvar, current_mean_return, current_volatility, ...rest } = filter;
    // By created
    const filteredByDate = implemented.filter(item => {
      const createDate = dayjs(item.create_date);
      if (!rest.create_start_date && !rest.create_end_date) {
        return true;
      }
      if (!rest.create_start_date) {
        return createDate.isBefore(rest.create_end_date, 'day') || createDate.isSame(rest.create_end_date, 'day');
      }
      if (!rest.create_end_date) {
        return createDate.isAfter(rest.create_start_date, 'day') || createDate.isSame(rest.create_start_date, 'day');
      }
      return (createDate.isAfter(rest.create_start_date, 'day') || createDate.isSame(rest.create_start_date, 'day')) &&
              (createDate.isBefore(rest.create_end_date, 'day') || createDate.isSame(rest.create_end_date, 'day'));
    });
    // By approved
    const filteredByApproved = filteredByDate.filter(item => {
      const approvedDate = dayjs(item.approve_date);
      if (!rest.approved_start_date && !rest.approved_end_date) {
        return true;
      }
      if (!rest.approved_start_date) {
        return approvedDate.isBefore(rest.approved_end_date, 'day') || approvedDate.isSame(rest.approved_end_date, 'day');
      }
      if (!rest.approved_end_date) {
        return approvedDate.isAfter(rest.approved_start_date, 'day') || approvedDate.isSame(rest.approved_start_date, 'day');
      }
      return (approvedDate.isAfter(rest.approved_start_date, 'day') || approvedDate.isSame(rest.approved_start_date, 'day')) &&
              (approvedDate.isBefore(rest.approved_end_date, 'day') || approvedDate.isSame(rest.approved_end_date, 'day'));
    });

    const filteredByExecuted = filteredByApproved.filter(item => {
      const executedDate = dayjs(item.execute_date);
      if (!rest.executed_start_date && !rest.executed_end_date) {
        return true;
      }
      if (!rest.executed_start_date) {
        return executedDate.isBefore(rest.executed_end_date, 'day') || executedDate.isSame(rest.executed_end_date, 'day');
      }
      if (!rest.executed_start_date) {
        return executedDate.isAfter(rest.executed_start_date, 'day') || executedDate.isSame(rest.executed_start_date, 'day');
      }
      return (executedDate.isAfter(rest.executed_start_date, 'day') || executedDate.isSame(rest.executed_start_date, 'day')) &&
              (executedDate.isBefore(rest.executed_end_date, 'day') || executedDate.isSame(rest.executed_end_date, 'day'));
    });

    const filteredByImplemented = filteredByExecuted.filter(item => {
      const implementedDate = dayjs(item.executed_date);
      if (!rest.implemented_start_date && !rest.implemented_end_date) {
        return true;
      }
      if (!rest.implemented_start_date) {
        return implementedDate.isBefore(rest.implemented_end_date, 'day') || implementedDate.isSame(rest.implemented_end_date, 'day');
      }
      if (!rest.implemented_start_date) {
        return implementedDate.isAfter(rest.implemented_start_date, 'day') || implementedDate.isSame(rest.implemented_start_date, 'day');
      }
      return (implementedDate.isAfter(rest.implemented_start_date, 'day') || implementedDate.isSame(rest.implemented_start_date, 'day')) &&
              (implementedDate.isBefore(rest.implemented_end_date, 'day') || implementedDate.isSame(rest.implemented_end_date, 'day'));
    });

    let filteredByPortfolio = [];
    if (rest.portfolioList[0] === 'All') {
      filteredByPortfolio = filteredByImplemented;
    } else {
      filteredByPortfolio = filteredByImplemented.filter(i => rest.portfolioList.includes(i.name));
    }

    let filteredByAdvisor = [];
    if (rest.advisorsList[0] === 'All') {
      filteredByAdvisor = filteredByPortfolio;
    } else {
      filteredByAdvisor = filteredByPortfolio.filter(i => rest.advisorsList.includes(i.advisor_id));
    }

    let filteredByClient = [];
    if (rest.clientsList[0] === 'All') {
      filteredByClient = filteredByAdvisor;
    } else {
      filteredByClient = filteredByAdvisor.filter(i => rest.clientsList.includes(i.client_id));
    }
    setFilteredPortfolio(filteredByClient);
  };

  useEffect(() => {
    applyFilters();
  }, [filter]);

  useEffect(() => {
    if (implemented) {
      setFilteredPortfolio(implemented);
    } else {
      setFilteredPortfolio([]);
    }
  }, [implemented]);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  const getAdvisorName = (id) => {
    const advisor = advisors?.find(i => i.ID === id);
    const firstName = advisor?.profile?.firstName ?? '';
    const lastName = advisor?.profile?.lastName ?? '';
    return firstName || lastName ? `${firstName} ${lastName}` : 'Super Admin';
  };

  const getClientName = (id) => {
    const client = clients?.find(i => i.ID === id);
    const firstName = client?.firstName ?? '';
    const lastName = client?.lastName ?? '';
    return `${firstName} ${lastName}`;
  };

  useEffect(() => {
    const defaultColumns = [
      {
        title: t('ADVISOR'),
        dataIndex: 'advisor_id',
        className: 'suggestions-name',
        width: '',
        align: 'left',
        render: (value) => getAdvisorName(value),
        sorter: (a, b) => textSort(getAdvisorName(a), getAdvisorName(b)),
      },
      {
        title: t('CLIENT'),
        dataIndex: 'client_id',
        className: 'suggestions-name',
        width: '',
        align: 'left',
        render: (value) => getClientName(value),
        sorter: (a, b) => textSort(getClientName(a), getClientName(b)),
      },
      {
        title: t('PORTFOLIO_NAME'),
        dataIndex: 'name',
        className: 'suggestions-name',
        width: '',
        align: 'left',
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('CREATION_DATE'),
        dataIndex: 'create_date',
        className: 'suggestions-create-date',
        width: '',
        align: 'left',
        render: (value) => <><span>{dayjs(value).format('MMM DD  YYYY')}</span><br/><span>{dayjs(value).format('HH:mm A')}</span></>,
        sorter: (a, b) => textSort(a.create_date, b.create_date),
      },
      {
        title: t('APPROVAL_DATE'),
        dataIndex: 'approve_date',
        className: 'suggestions-approval-date',
        width: '',
        align: 'left',
        render: (value) => <><span>{dayjs(value).format('MMM DD  YYYY')}</span><br/><span>{dayjs(value).format('HH:mm A')}</span></>,
        sorter: (a, b) => textSort(a.approve_date, b.approve_date),
      },
      {
        title: t('EXECUTION_DATE'),
        dataIndex: 'execute_date',
        className: 'suggestions-approval-date',
        width: '',
        align: 'left',
        render: (value) => <><span>{dayjs(value).format('MMM DD  YYYY')}</span><br/><span>{dayjs(value).format('HH:mm A')}</span></>,
        sorter: (a, b) => textSort(a.execute_date, b.execute_date),
      },
      {
        title: t('IMPLEMENTATION_DATE'),
        dataIndex: 'executed_date',
        className: 'suggestions-approval-date',
        width: '',
        align: 'left',
        render: (value) => <><span>{dayjs(value).format('MMM DD  YYYY')}</span><br/><span>{dayjs(value).format('HH:mm A')}</span></>,
        sorter: (a, b) => textSort(a.executed_date, b.executed_date),
      },
      {
        title: t('ACTIONS'),
        align: 'center',
        dataIndex: 'operation',
        width: '15%',
        render: (_, record) => {
          return (
            <div className="cell-button-wrapper">
              <Button type="text" onClick={() => hendlePreview(record.id, record.portfolio_id)}>
                <Eye />
              </Button>
              {!isDownload
                ? <Button type="text" onClick={() => hendleDownload(record.portfolio_id, record.id)}>
                    <Download />
                  </Button>
                : <Spin size="small" />
              }
              <Button type="text" onClick={() => hendlePortfolioLink(record.client_id, record.implemented_portfolio_id)}>
                <Link />
              </Button>
            </div>
          );
        },
      },
    ];
    setTableColumns(defaultColumns);
  }, [implemented, advisors, clients, isDownload]);

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSearch = (event) => {
    const { value } = event?.target;
    if (value?.length > 2) {
      const searched = implemented?.filter(
        (i) =>
          i.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredPortfolio(searched);
    } else {
      setFilteredPortfolio(implemented);
    }
  };

  const hendlePreview = (candidatId, portfolio_id) => {
    setOpenModalTransaction(true);
    dispatch(getCandidatTransactions({ candidatId, portfolio_id }));
  };

  const hendleDownload = (portfolio_id, client_id) => {
    dispatch(downloadPdfReport({ portfolioId: portfolio_id, candidatId: client_id }));
  };

  const hendlePortfolioLink = (userId, portfolioId) => {
    history.push(`/clients/${userId}/portfolio/${portfolioId}/portfolio-information`);
  };
  const closeModalTransaction = () => {
    setOpenModalTransaction(false);
  };

  return (
    <Container widthP={100} mb="28px" padding="0">
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '19px', padding: '19px 40px 0' }}
      >
        <Col>
          <h2 className="instrument-header-title">
            {t('IMPLEMENTED')}
          </h2>
        </Col>
        <Col className="instrument-button-wraper">
          <CustomSearchInput
            placeholder={t('SEARCH_CODE')}
            onChange={handleSearch}
          />
          <Button
            className="filter-btn"
            type="default"
            disabled={isLoading}
            onClick={handeleFilter}
            icon={<Filter />}
            iconPosition="end"
          >
            {t('FILTER')}
            {filterCounter
              ? (
              <span className="filter-counter">{filterCounter}</span>
                )
              : (
                  ''
                )}
          </Button>
        </Col>
      </Row>
      <div className="suggestions-table">
          <Table
            rowKey={(record) => record.id}
            loading={isLoading}
            rowClassName={(_, index) =>
              index % 2 === 0
                ? 'holdings-table-row'
                : 'holdings-table-row is-odd'
            }
            columns={tableColumns}
            dataSource={filteredPortfolio}
            size="middle"
            bordered
            pagination={false}
          />
      </div>
      <ModalViewTransaction
        openModalTransaction={openModalTransaction}
        closeModalTransaction={closeModalTransaction}
      />
      <ModalFilterCandidat
        type='admin-implemented'
        source={implemented}
        setFilter={setFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
        getAdvisorName={getAdvisorName}
        getClientName={getClientName}
      />
    </Container>
  );
};

export default AdminImplementedTable;
