import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
HighchartsMore(Highcharts);

export const Spiderweb = ({ data }) => {
  const getLebleText = (index) => {
    return `${Math.round(data.optim[index] * 100) / 100} (${Math.round(data.current[index] * 100) / 100})`;
  };
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      polar: true,
      type: 'area'
    },

    title: false,

    pane: {
      size: '90%'
    },

    xAxis: {
      categories: [`Efficiency ${getLebleText(0)}`,
      `Sustainability ${getLebleText(1)}`,
      `Return Factors ${getLebleText(2)}`,
      `Concentration Risk ${getLebleText(3)}`,
      `Fees ${getLebleText(4)}`
      ],
      tickmarkPlacement: 'on',
      lineWidth: 0
    },

    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 1,
      tickInterval: 20,
      min: 0,
      max: 100
    },

    tooltip: {
      shared: true,
      // eslint-disable-next-line no-template-curly-in-string
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.2f}</b><br/>'
    },

    series: [{
      name: 'Current Portfolio',
      data: data.current,
      color: '#C94709',
      pointPlacement: 'on'
    }, {
      name: 'Optimized Portfolio',
      data: data.optim,
      color: '#305572',
      pointPlacement: 'on'
    }],
    area: {
      stacking: 'normal',
      lineColor: '#666666',
      lineWidth: 1,
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'vertical'
          },
          pane: {
            size: '70%'
          }
        }
      }]
    }

  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

Spiderweb.propTypes = {
  data: PropTypes.object,
};
