import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  activateAdvisor,
  deactivateAdvisor,
  deleteAdvisor,
  getAdvisors,
  getAdvisorsAwaitApprove,
  reinviteAdvisor,
} from '../../data/store/advisors/advisorAction';

import { Row, Button, Table, Col, Layout, Modal } from 'antd';

import { USER_STATUS } from '../../constants/user_status';
import Container from '../../components/Container';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import './style.scss';

const { Header } = Layout;

const Advisor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();

  const advisors = useSelector((state) => state.advisorReducer.advisors);
  // const advisorsAwaitApprove = useSelector(
  //   (state) => state.advisorReducer.advisorsAwaitApprove
  // );

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [filteredInstrument, setFilteredInstrument] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(getAdvisors({ limit: 10, offset: 0 }));
    dispatch(
      getAdvisorsAwaitApprove({
        limit: 100,
        offset: 0,
        status: USER_STATUS.AWAIT_APPROVE,
      })
    );
  }, []);

  // useEffect(() => {
  //   if (!advisors?.list) {
  //     dispatch(getAdvisors({ limit: 10, offset: 0 }));
  //   }
  // }, [advisors.list]);

  const getAdvisorsPagination = useCallback(
    (page, pageSize) => {
      setPage(page);
      setPageSize(pageSize);
      dispatch(getAdvisors({ limit: pageSize, offset: pageSize * (page - 1) }));
    },
    [advisors]
  );

  useEffect(() => {
    const advisorsData = advisors.list?.map((el) => {
      return {
        key: el.ID,
        id: el.ID,
        firstName: el.profile?.firstName,
        lastName: el.profile?.lastName,
        email: el.email,
        phone: el.profile?.phone,
        status: el.status,
      };
    });
    setData(advisorsData);
  }, [advisors]);

  useEffect(() => {
    setFilteredInstrument(data);
  }, [data]);

  const handlDeactivate = (key) => {
    dispatch(deactivateAdvisor(key));
  };

  const handlActivate = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const handlReSendInvite = (id) => {
    dispatch(reinviteAdvisor(id));
  };
  const handlApproval = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const hendleEdit = (id) => {
    history.push(`/advisors/edit-advisor/${id}`);
  };

  const getAdvisorNAme = (id) => {
    const find = advisors?.list?.find(i => i.ID === id);
    if (find) {
      return `${find?.profile.firstName} ${find?.profile.lastName}`;
    } else {
      return '';
    }
  };
  const handleDeleteMOdal = (id) => {
    setDeleteId(id);
    setOpenModalDelete(true);
    console.log('handleDelete');
  };

  const cancelWithoutDelete = () => {
    setDeleteId(null);
    setOpenModalDelete(false);
  };

  const onSubmitDelete = () => {
    console.log('handleDelete');
    dispatch(deleteAdvisor(deleteId));
    cancelWithoutDelete();
    setDeleteId(null);
  };

  const getStatusStyle = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') return 'yellow';
    if (value === 'USER_STATUS_ACTIVE') return 'green';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') return 'purple';
    return 'purple';
  };

  const getStatusAction = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') {
      return {
        action: handlApproval,
        title: t('APPROVE'),
        style: 'approve',
      };
    }
    if (value === 'USER_STATUS_ACTIVE') {
      return {
        action: handlDeactivate,
        title: t('DEACTIVATE'),
        style: 'deactivate',
      };
    }
    if (value === 'USER_STATUS_DEACTIVATED') {
      return {
        action: handlActivate,
        title: t('ACTIVATE'),
        style: 'active',
      };
    }
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') {
      return {
        action: handlReSendInvite,
        title: t('RE_INVITE'),
        style: 'active',
      };
    }
    return null;
  };

  const columns = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      width: '15%',
      sorter: (a, b) => {
        if (a.firstName < b.firstName) {
          return 1;
        } else if (a.firstName > b.firstName) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      width: '15%',
      sorter: (a, b) => {
        if (a.lastName < b.lastName) {
          return 1;
        } else if (a.lastName > b.lastName) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      width: '20%',
      sorter: (a, b) => {
        if (a.email < b.email) {
          return 1;
        } else if (a.email > b.email) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      width: '15%',
      sorter: (a, b) => {
        if (a.phone < b.phone) {
          return 1;
        } else if (a.phone > b.phone) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      width: '15%',
      render: (value) => {
        return (
          <span className={`status-advisor ${getStatusStyle(value)}`}>
            {value === 'USER_STATUS_AWAIT_APPROVE'
              ? t('AWAITING_APPROVAL')
              : value === 'USER_STATUS_ACTIVE'
                ? t('ACTIVE')
                : value === 'USER_STATUS_DEACTIVATED'
                  ? t('NOT_ACTIVE')
                  : t('NOT_ACTIVATED_YET')}
          </span>
        );
      },
      sorter: (a, b) => {
        if (getStatusStyle(a.status) < getStatusStyle(b.status)) {
          return 1;
        } else if (getStatusStyle(a.status) > getStatusStyle(b.status)) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        return (
          <div
            className="cell-button-wrapper"
            style={{ justifyContent: 'flex-start' }}
          >
            {getStatusAction(record.status) && (
              <Button
                className={`action-btn advisor-action-btn ${
                  getStatusAction(record.status)?.style
                }`}
                onClick={() =>
                  getStatusAction(record.status)?.action(record.id)
                }
              >
                {getStatusAction(record.status)?.title}
              </Button>
            )}
            <Button type="text" onClick={() => hendleEdit(record.id)}>
              <Edit />
            </Button>
            {(getStatusStyle(record.status) === 'red' || getStatusStyle(record.status) === 'purple') && (
              <Button type="text" onClick={() => handleDeleteMOdal(record.id)}>
                <Delete />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    const { value } = event?.target;
    if (value?.length > 2) {
      const searched = data?.filter(
        (i) =>
          (i.firstName + ' ' + i.lastName)
            .toLowerCase()
            .includes(value?.toLowerCase()) ||
          i.ISIN?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredInstrument(searched);
    } else {
      setFilteredInstrument(data);
    }
  };

  const handleCreate = () => {
    history.push('/advisors/invite-advisor');
  };

  return (
    <Row className="advisor-wrapper">
      <div className="top-btn-wrapper">
        <Button
          onClick={handleCreate}
          className="portfolio-details-btn optimise-btn">
          {t('INVITE_ADVISOR')}
        </Button>
      </div>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn ant-btn-active">
              {t('ADVISORS_LC')}
            </Button>
          </Col>
        </Row>
      </Header>
      <Container className="advisor-container table-inner-wrapper" widthP={100} >
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '19px', padding: '19px 40px 0' }}
        >
          <Col>
            <h2 className="instrument-header-title">{t('ADVISOR_LIST')}</h2>
          </Col>
          <Col className="instrument-button-wraper">
            <CustomSearchInput
              placeholder={t('SEARCH_LC')}
              onChange={handleSearch}
            />
          </Col>
        </Row>
        <Table
          rowClassName={(_, index) =>
            index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
          }
          style={{ width: '100%' }}
          bordered
          dataSource={filteredInstrument}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            onChange: getAdvisorsPagination,
            total: advisors.totalCount,
          }}
        />
      </Container>
      <Modal
        width={650}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_TEXT')}</p>
            <p className="modal-advisor-name">{getAdvisorNAme(deleteId)}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutDelete}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={onSubmitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
    </Row>
  );
};

export default Advisor;
