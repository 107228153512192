export const DashboardSW = {
  CUSTOMER_INFORMATION: 'Kundinformation',
  COMPANY_INFORMATION: 'Företagsinformation',
  CLIENT_DASHBOARD: 'Kundens instrumentpanel',
  REMOVE: 'Ta bort',
  ECONOMIC_SITUATION: 'Ekonomisk situation',
  RISK_PROFILE: 'Riskprofil',
  CUSTOMER_KNOWLEDGE: 'Kundkännedom',
  COMPANY_KNOWLEDGE: 'Företagskunskap',
  ASSIGNMENT_AGREEMENT: 'Uppdragsavtal',
  PURPOSE_MATTERS: 'Syfte & hjärtefrågor',
  ENTER: 'Ange',
  CORRECT: 'korrekt',
  FINANCIAL_PLANNING: 'Finansiell planering',
  LEGAL_ANALYSIS: 'Juridisk behovsanalys',
  EXPERIENCE_BACKGROUND: 'Erfarenhetsbakgrund',
  PURCHASE_INFORMATION: 'Förköpsinformation',
  SIGN_AGREEMENT: 'Signera avtal',
  SOCIAL_SECURITY_NUMBER: 'Personnummer',
  ORGANIZATION_NUMBER: 'Organisationsnummer',
  PRIVATPERSON: 'Privatperson',
  CORPORATE_CLIENT: 'Företagskund',
  ENTER_FIRST_NAME: 'Ange förnamn',
  ANGE_EFTERNAMN: 'Ange efternamn',
  CONTACT_DETAILS: 'Kontaktuppgifter',
  MAILADRESS: 'Mailadress',
  ENTER_EMAIL_ADDRESS: 'Ange mailadress',
  MOBILE_NUMBER: 'Mobilnummer',
  ADD_MORE_PHONE_NUMBERS: 'Lägg till fler telefonnummer',
  ENTER_PHONE_NUMBER: 'Ange telefonnummer',
  ADDRESS: 'Adress',
  POSTAL_ADDRESS: 'Postadress',
  ENTER_POSTAL_ADDRESS: 'Ange postadress',
  ENTER_CO_ADDRESS: 'Ange C/O-adress',
  ZIP_CODE: 'Postnummer',
  ENTER_ZIP_CODE: 'Ange postnummer',
  CITY: 'Stad',
  COUNTRY: 'Land (bosättning)',
  ENTER_CITY: 'Ange stad',
  COUNTRY_COMPANY: 'Land',
  LEI_CODE: 'LEI-kod',
  ENTER_COUNTRY: 'Ange land',
  MARITAL_STATUS_AND_EMPLOYMENT: 'Civilstånd och sysselsättning',
  MARITAL_STATUS: 'Civilstånd',
  SELECT_OPTION: 'Välj alternativ',
  MAIN_OCCUPATION: 'Huvudsaklig sysselsättning',
  CHILDREN_OR_OTHERS_DEPENDENT: 'Barn eller andra beroende av försörjning',
  ACCOUNT_SELECTION: 'Kontoval',
  ACCOUNT_TEXT1: 'Följande konton kommer att öppnas. Aktie- & fondkontot är vårt grundkonto och',
  ACCOUNT_TEXT2: 'öppnas automatiskt när man blir kund hos oss. Investeringssparkontot (ISK) är det',
  ACCOUNT_TEXT3: 'konto som är populärast bland våra kunder. Om man inte vill ha ett ISK bockar',
  ACCOUNT_TEXT4: 'man ur rutan nedan.',
  STOCK_FUND_ACCOUNT: 'Aktie- & fondkonto',
  INVESTMENT_SAVINGS_ACCOUNT: 'Investeringssparkonto',
  ENDOWMENT_INSURANCE: 'Kapitalförsäkring',
  GO_BACK: 'Gå tillbaka',
  MOVE_ON: 'Gå vidare',
  DETAILS: 'Detaljer',
  MY_DETAILS: 'Mina uppgifter',
  COMPANY_DETAILS: 'Företagsinformation',
  MARRIED: 'Gift eller sambo',
  NO_MARRIED: 'Ogift',
  WIDOW_OR_WIDOWER: 'Änka eller änkeman',
  EMPLOYEE: 'Anställd',
  SELF_EMPLOYED: 'Egen företagare',
  PENSIONER: 'Pensionär',
  STUDENT: 'Studerande',
  OTHER: 'Annat',
  PURPOSE_AND_HEARTA: 'Syfte och hjärtefrågor med investeringarna',
  WHAT_IS_THE_PURPOSE: 'Vad är syftet med investeringarna? ',
  DO_YOU_HAVE_ANY_MATTERS: 'Har du några “hjärtefrågor” som du vill ska beaktas vid valet av investeringar?',
  PENSION_SAVINGS: 'Pensionssparande',
  INVESTMENT_TOWARDS_TARGET: 'Målsparande',
  INVESTMENT: 'Investering',
  WHICH_OF_BELOW_STATEMENTS: 'Vilket påstående nedan beskriver din syn på investeringar och hållbarhetsfrågor bäst?',
  MY_FOCUS_IN_INVESTMENTS: 'Mitt huvudfokus är risk och avkastning.',
  I_WANT_TO_INTEGRATE_SUSTAINABILITY: 'Jag vill ta hänsyn till hållbarhet men behålla mina risk och avkastningsmål.',
  SUSTAINABILITY_IS_A_VERY_IMPORTANT: 'Hållbarhet är en viktig fråga för mig och jag kan tänka mig att sänka mina krav på risk och avkastning och avstå från investeringar i vissa tilllgångsslag och företag.',
  ARE_THERE_ANY_OTHER_ASPECTS: 'Finns det några övriga aspekter på arbetet med portföljen som Citroneer bör känna till?',
  INCOME_AND_EXPENSES: 'Inkomst och utgifter',
  AFTER_TAX: 'Efter skatt',
  INCOME_FROM_EMPLOYMENT: 'Inkomst från anställning',
  OTHER_INCOME: 'Annan inkomst',
  EXPENDITURE: 'Utgifter',
  EXCESS: 'Överskott',
  COMMENT: 'Kommentar',
  FINANCIAL_ASSETS: 'Finansiella tillgångar',
  FINANCIAL_INVESTMENTS: 'Finansiella investeringar',
  CASH_AND_CASH_EQUIVALENTS: 'Likvida medel',
  PENSION_SAVINGS_AND_SIMILAR: 'Pensionssparande',
  OTHER_ASSETS: 'Övriga finansiella tillgångar',
  FIXED_ASSETS: 'Fasta tillgångar',
  HOME: 'Boende',
  OTHER_REAL_ASSETS: 'Övriga fasta tillgångar',
  DEBTS: 'Skulder',
  MORTGAGES: 'Bolån',
  OTHER_DEBTS: 'Övriga skulder',
  INVESTABLE_CAPITAL: 'Omfattning och investeringsbart kapital',
  PARTS_THAT_SHOULD_NOT_BE_COVERED: 'Finns det delar som ej ska omfattas av rådgivningen?',
  CAPITAL_NOT_BE_COVERED: 'Kapital som ej ska omfattas av rådgivningen',
  INVESTIBLE_CAPITAL: 'Investeringsbart kapital',
  INVESTIBLE_CAPITAL_ADV: 'Investeringsbart kapital',
  SAVING: 'Sparande',
  IS_THERE_NEED_FOR_ABILITY_LIQUIDATE: 'Finns det behov att med kort varsel kunna likvidera innehaven?',
  YES: 'Ja',
  NO: 'Nej',
  HOW_FUTURE_GENERATIONS: 'Finns det några tankar på hur kommande generationer ska ta del av portföljens avkastning som Citroneer bör känna till?',
  TIME_HORIZON: 'Tidshorisont',
  YEAR: 'år',
  COMMENT_TIME_HORIZON: 'Kommentar tidshorisont',
  REGULAR_DEPOSITS: 'Regelbundna insättningar (per månad)',
  ENTER_REGULAR_DEPOSITS: 'Ange regelbundna insättningar',
  BUFFER_SAVINGS: 'Buffertsparande',
  ENTER_YOUR_DESIRED_BUFFER_SAVING: 'Ange ditt önskade buffertsparande',
  GOAL_SAVING: 'Målsparande',
  ENTER_NAME: 'Ange namn',
  YEAR_OF_PURCHASE: 'År till köp',
  ENTER_YEAR_OF_PURCHASE: 'Ange år till köp',
  AMOUNT: 'Belopp',
  ENTER_AMOUNT: 'Ange belopp',
  ADD_SAVING: 'Lägg till fler målsparanden',
  ADD_ANOTHER_COUNTRY: 'Lägg till ett annat land',
  YEARS_LEFT_UNTIL_RETIREMENT: 'År kvar till pension',
  ENTER_YEARS_LEFT_UNTIL_RETIREMENT: 'Ange år kvar till pension',
  REGULAR_WITHDRAWALS: 'Regelbundna uttag (per månad)',
  ENTER_REGULAR_WITHDRAWALS: 'Ange regelbundna uttag efter pension',
  REQUIREMENT_FOR_RESIDUAL_CAPITAL: 'Krav på restkapital',
  ENTER_REQUIREMENTS_FOR_RESIDUAL: 'Ange krav på restkapital efter tidsperiodens slut',
  RISK_TOLERANCE: 'Risktolerans',
  HOW_DO_YOU_VIEW_RISK: 'Hur ser du på risk?',
  WHAT_IS_THE_ANNUAL_EXPECTED: 'Vilken är den årliga förväntade avkastningen på kapitalet?',
  IN_LINE_WITH_THE_BANK: 'I linje med sparräntan',
  SOMEWHAT_ABOVE_THE_BANK: 'Något över sparräntan',
  SUBSTANTIALLY_ABOVE_THE_BANK: 'En klart högre avkastning än sparräntan',
  I_HAVE_A_DEFINED_TARGET: 'Det finns ett definierat avkastningsmål',
  RETURN_TARGET: 'Avkastningsmål',
  ENTER_THE_RETURN_TARGET: 'Ange avkastningsmålet (%)',
  WHICH_OF_THE_BELOW_BEST_DESCRIBES: 'Vilket påstående nedan beskriver investeringsfilosofin bäst?',
  FOCUS_TO_CONTROLL_RISK_AND_LOSSES: 'Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning.',
  ACCEPT_A_SOMEWHAT_HIGHER_RISK: 'Accepterar viss risk för att få högre potential till avkastning.',
  FOCUS_IS_ON_HIGH_RETURN: 'Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna.',
  HOW_IS_YOUR_VIEW_OF_INVESTMENTS: 'Hur påverkas synen på investeringarna när media fylls av negativa marknadsnyheter och experter kommer med investeringstips?',
  NEWS_DOES_NOT_EFFECT: 'Inte nämnvärt och förorsakar ingen oro över portföljen.',
  WHEN_I_RECIEVE_NEGATIVE_MARKET: 'Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.',
  WHEN_I_RECEIVE_NEGATIVE_NEWS: 'Negativa nyheter innebär att portföljen förändras för att följa experternas tips.',
  I_GET_MORE_INVOLVED_IN_MY_INVESTMENTS: 'Ger ökad uppmärksamhet åt portföljen men med is i magen.',
  ASSUME_THAT_YOUR_PORTFOLIO_TODAY: 'Anta att portföljen idag är värd runt 50 MSEK. Vilken blir reaktionen om värderingen om tolv månader var 45 MSEK?',
  A_NEED_TO_LOWER_MY_RISK: 'En vilja att minska risken i portföljen och till exempel sälja aktier.',
  THE_PORTFOLIO_IS_LEFT_INTACT: 'Portföljen lämnas intakt, men minska ned på utdelningen från portföljen.',
  CONTACT_THE_ADVISOR_TO_DISCUSS: 'Kontaktar rådgivaren för att diskutera portföljen.',
  NONE_ITS_NATURAL_WITH_BIG_FLUCTUATIONS: 'Ingen, det är en naturligt med stora svängningar på marknaderna.',
  AN_OPPORTUNITY_TO_TAKE_ADVANTAGE: 'En möjlighet att ta till vara på och öka risken och exempelvis köpa aktier.',
  HOW_WOULD_YOUR_REACTION_BE_DIFFERENT: 'På vilket sätt skulle reaktionen vara annorlunda om värdet istället var 40 MSEK?',
  LARGE_AND_SMALL_MOVEMENTS: 'Ingen, stora och små rörelser är en naturlig del av investeringar.',
  I_CAN_UNDERSTAND_SMALL_FLUCTUATIONS: 'Små svängningar kan jag förstå, men stora fall gör mig klart stressad.',
  WHAT_WOULD_BE_YOUR_REACTION: 'Vilken blir reaktionen om Stockholmsbörsen stiger med 20 procent medan portföljen bara ökar med 5 procent?',
  FRUSTRATION_OVER_THE_MISSED: 'Frustration över den missade möjligheten och av att halka efter börsen.',
  NOTHING_REALLY_IM_AWARE_THAT: 'Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.',
  I_SPEAK_WITH_MY_ADVISOR_TO_UNDERSTAND: 'Samtalar med rådgivaren för att förstå varför portföljen presterar sämre.',
  NOTHING_THE_PORTFOLIO_IS_BALANCED: 'Ingen, portföljen är balanserad och kommer inte att hänga med enskilda marknadsrörelser.',
  DO_YOU_COMPARE_YOUR_PORTFOLIO: 'Jämför du din portfölj med något/ hur utvärderar du dina investeringar?',
  COMPARE_MY_RESULT_WITH_THE_SWEDISH: 'Jag jämför mitt resultat med svenska aktier/Stockholmsbörsen.',
  COMPARE_MY_RESULT_WIHT_A_GLOBAL: 'Jag jämför mitt resultat med en global aktieportfölj.',
  I_HAVE_A_SPECIFIC_BENCHMARK: 'Jag har ett specifikt benchmark.',
  I_COMPARE_MY_PORTFOLIO_WITH: 'Jag jämför min portfölj med en fond/förvaltare.',
  TO_WHICH_EXTENT_DO_FINANCIAL: 'I vilken utsträckning påverkar finansiella nyheter din syn på investeringar?',
  WHEN_I_RECIEVE_NEGATIVE_NEWS: 'Negativa nyheter innebär att portföljen förändras för att följa experternas tips.',
  I_GET_MORE_INVOLVED: 'Ger ökad uppmärksamhet åt portföljen men med is i magen.',
  COMMENT_RISK_AND_RETURN: 'Kommentar: Risk- och avkastningsförväntningar',
  RISK: 'Risk',
  RISK_PARAGRAF1: 'När du väljer riskklass för ditt sparande är det viktigt att tänka på att du även väljer den genomsnittliga avkastning du kan förvänta dig. För ett diversifierat sparande över längre tidsperioder gäller normalt sambandet att lägre risk leder till lägre avkastning och att högre avkastning kräver högre risk. Notera att sambandet är svagt för enskilda värdepapper och/eller kortare tidsperioder. Om din investeringshorisont är kort bör du därför välja lägre risk än om din investeringshorisont är lång.',
  RISK_PARAGRAF2: 'På den europeiska marknaden beskrivs risk och avkastning med hjälp av en sjugradig skala som kallas sammanfattande riskindikator (SRI). Enkelt beskrivet är det den förväntade variationen för avkastningen som avgör värdet för SRI. Tabellen nedan beskriver risk och avkastning för ett enskilt år. De övriga siffrorna i tabellen syftar till att relatera den sjugradiga skalan till konkreta värden för avkastning och risk.',
  RISK_CLASS: 'Riskklass',
  EXAMPLE_OF_PRODUCT: 'Exempel på produkt',
  VOLATILITY_ANNUAL: 'Volatilitet (årlig)',
  RETURN_ANNUAL: 'Avkastning (årlig)',
  RISK_OF_AN_ANNUAL_RETURN_LOWER: '10 % risk för en årsavkastning lägre än',
  VERY_LOW_RISK: 'Mycket låg risk',
  GOVERNMENT_BOND: 'Statsobligation',
  LOW_RISK: 'Låg risk',
  CORPORATE_BOND: 'Företagsobligation (Investment Grade)',
  MEDIUM_LOW_RISK: 'Medellåg risk',
  CORPORATE_BOND_HIGH_YIELD: 'Företagsobligation (High Yield)',
  MEDIUM_RISK: 'Medel risk',
  BROAD_STOCK_PORTFOLIO_INDEX: 'Bred aktieportfölj (index)',
  MEDIUM_HIGH_RISK: 'Medelhög risk',
  STOCK_SMALL_COMPANIES: 'Aktie (småbolag)',
  HIGH_RISK: 'Hög risk',
  STRUCTURED_PRODUCT: 'Strukturerad produkt',
  VERY_HIGH_RISK: 'Mycket hög risk',
  WARRANT: 'Warrant',
  READ_MORE: 'Läs mer',
  RISK_CLASSIFICATION_PROPOSAL: 'Citroneers riskklassificeringsförslag',
  RISK_PARAGRAF3: 'Baserat på dina tidigare svar angående din risktolerans bedömer Citroneer att riskklass 4 (Medel risk) är en lämplig riskklass för dina ändamål.',
  LEGAL_NEEDS_PLANNING: 'Juridisk behovsanalys',
  STRUCTURE_FOR_INVESTMENTS: 'Struktur för placeringar, framtidsplaneringsrisk',
  WHAT_INVESTMENTS_YOU_CURRENTLY: 'Vilka placeringar har Du i nuläget och vilken struktur finns för dessa (privatägda, kapitalförsäkring, ISK eller bolag)?',
  WHAT_OTHER_ASSETS_DO_YOU_CURRENTLY: 'Vilka övriga tillgångar har Du (t ex fastigheter i Sverige/utlandet eller andra typer av investeringar av mer långsiktig karaktär)?',
  HAVE_YOU_ASSETS_THAT: 'Finns tillgångar som är samägda med andra eller placeringar som omfattas av aktieägaravtal, testamente, förordnande som enskild egendom eller liknande?',
  WHAT_ARE_THE_PLANS: 'Hur ser planerna ut för framtiden – är avsikten att skapa någon form av förvaltningsstruktur/-bolag (om inte sådan redan finns)?',
  OTHER_ISSUES_OF_A_LEGAL: 'Andra frågor av legal karaktär som Du vill diskutera eller flagga för inför diskussionen?',
  COMMENT_LEGAL: 'Kommentar: Juridisk behovsanalys',
  CITIZENSHIP_TAX_RESIDENCE: 'Medborgarskap och skattehemvist',
  WHAT_YOUR_COUNTRY_CITIZENSHIP: 'I vilket land har du medborgarskap?',
  COUNTRY_CITIZENSHIP: 'Medborgarskap',
  COUNTRY_TAX: 'Land för skattemässig hemvist',
  OWNERSHIP: 'Äganderätt',
  Sweden: 'Sverige',
  WHAT_YOUR_COUNTRY_TAX_RESIDENCE: 'Var har du din skattemässiga hemvist?',
  ABROAD: 'Utlandet',
  HAVE_YOU_ANY_RELATIONSHIP_WITH: 'Har du någon relation till USA?',
  DO_YOU_REGULARLY_MAKE_TRANSACTIONS: 'Gör du som regel transaktioner med utlandet?',
  WHAT_PURPOSE: 'Vilket syfte?',
  PURPOSE_WITH_INVESTMENT: 'Syfte med engagemang',
  WHAT_PURPOSE_YOUR_BUSINESS: 'Vad är syftet med ditt engagemang hos Citroneer?',
  BEHALF_ARE_YOU_INVESTING: 'För vems räkning investerar du för?',
  FOR_MY_OWN_PART: 'För egen del',
  FOR_SOMEONE_ELSE_BY_PROXY: 'För någon annan genom fullmakt',
  OTHER_DESCRIBE: 'Annat (beskriv)',
  WHAT_YOUR_MAIN_OCCUPATION: 'Vilken är din huvudsakliga sysselsättning?',
  APPROXIMATE_MONTHLY_INCOME: 'Vilken ungefärlig månadsinkomst har du?',
  OVER_100000: 'Över 100 000 kr',
  CAPITAL_TRANSFER: 'Kapitalöverföring',
  PERSONAL_MONEY_THAT_WILL_TRANSFERED: 'Är det dina pengar/innehav som kommer att överföras?',
  WHERE_DOES_THE_MONEY: 'Varifrån kommer de pengar och/eller värdepapper som du sätter in på Citroneer? Du har möjlighet att kryssa i flera alternativ',
  SALARY_PENSION: 'Lön/Pension',
  OWN_COMPANY: 'Eget företag (lön eller utdelning)',
  INHERITAGE: 'Arv/Gåva',
  HOUSING_PROPERTY: 'Bostads- eller fastighetsförsäljning',
  CAPITAL_GAINS_COMPANY: 'Företagsförsäljning',
  OTHER_SALE: 'Annan försäljning (exempelvis bil- eller båtförsäljning)',
  CAPITAL_GAINS_SECURITIES: 'Vinst från värdepappershandel',
  LOTTERY_GAMBLING: 'Lotteri/Spel',
  INSURANCE_PAYMENT: 'Försäkringsutbetalning',
  ALLOWANCE: 'Bidrag/Arbetslöshetsersättning',
  FROM_WHICH_BANK_INSURANCE: 'Från vilken bank/försäkringsbolag tar du kapital för dina investeringar? Du har möjlighet att kryssa i flera alternativ',
  SWEDISH_BANK: 'Svensk bank',
  SWEDISH_INSURANCE_COMPANY: 'Svenskt försäkringsbolag',
  FORREIGN_BANK: 'Utländsk bank',
  FORREIGN_INSURANCE_COMPANY: 'Utländskt försäkringsbolag',
  ESTIMATED_VALUE_WILL_YOU_TRANSFER: 'Vilket ungefärligt värde kommer du att överföra i samband med öppnandet av ditt konto hos Citroneer?',
  OVER_100000000: 'Över 100 000 000 kr',
  DEPOSITS_WITHDRAWALS_TRANSACTIONS: 'Insättningar, uttag och transaktioner',
  WHAT_VALUE_YOUR_DEPOSITS_AMOUT: 'Vilket värde kommer dina insättningar att uppgå till i genomsnitt per år?',
  OVER_10000000: 'Över 10 000 000 kr',
  OFTEN_WILL_DEPOSITS: 'Hur ofta kommer insättningar att göras per år?',
  TIMES_0_10: '0 - 10 gånger',
  TIMES_10_50: '10 - 50 gånger',
  TIMES_50_100: '50 - 100 gånger',
  MORE_THAN_100_TIMES: 'Fler än 100 gånger',
  OFTEN_WILL_WITHDRAWALS: 'Hur ofta kommer uttag att göras per år?',
  HOW_LARGE_INDIVIDUAL_TRANSACTIONS: 'Hur stora enskilda transaktioner i finansiella instrument gör du normalt?',
  POLITICALLY_EXPOSED_POSITION: 'Person i politiskt utsatt ställning',
  KNOWN_EMPLOYEE_SUCH_PERSON: 'Är du eller har du varit en person i politisk utsatt ställning alternativt en familjemedlem eller en känd medarbetare till en sådan person?',
  GENERAL_INFORMATION: 'Allmän information',
  DO_YOU_HAVE_EDUCATION: 'Har du utbildning och/eller pågående eller tidigare yrke som medfört kunskap om hur finansiella placeringar och marknader fungerar?',
  HOW_MANY_YEARS_INVESTING: 'Hur länge har du handlat med värdepapper?',
  ENTER_THE_NUMBER_YEARS: 'Ange hur många år du har handlat med värdepapper',
  SPECIFIC_KNOWLEDGE_DIFFERENT: 'Specifik kunskap om olika tillgångsklasser',
  ASSET_CLASSES: 'Tillgångsklasser',
  NUMBER_OF_DEALS: 'Antal affärer under senaste 3 åren',
  KNOWLEDGE_AND_EXPERIENCE: 'Kunskap & erfarenhet',
  THE_CUSTOMER_HAS_TRAINED: 'Kunden har utbildats',
  THE_ADVISER_BELIEVES_CUSTOMER: 'Rådgivaren anser att kunden förstått riskerna med produkten',
  SHARES_LISTED: 'Aktier - Noterade',
  SHARES_UNLISTED: 'Aktier - Onoterade',
  FUNDS_UCITS: 'Fonder - UCITS',
  FUNDS_AIF_SPECIALFONDER: 'Fonder – AIF/Specialfonder',
  BONDS_LISTED: 'Obligationer - Noterade',
  BONDS_UNLISTED: 'Obligationer - Onoterade',
  STOCK_INDEX_BONDS: 'Aktieindexobligationer',
  HEDGE_FUNDS: 'Hedgefonder',
  CREDIT_CERTIFICATE: 'Kreditcertifikat',
  AUTOCALLS: 'Autocalls',
  PORTFOLIO_MANAGEMENT: 'Portföljförvaltning',
  EXCHANGE_TRADED_FUNDS: 'ETF (Börshandlade fonder)',
  DERIVATIVES_LEVERAGED: 'Derivat och hävstångscertifikat',

  QUANTITY: 'Antal',
  NONE: 'Ingen',
  BASIC: 'Grundläggande',
  GOOD_KNOWLEDGE: 'God kunskap och erfarenhet',
  ADD_DESCRIPTIVE_TAGS: 'Lägg till beskrivande taggar',
  SUSTAINABILITY: 'hållbarhet',
  LOCAL: 'norden',
  NEXT_GENERATION: 'nästa generation',
  RISK_OF_LOSS: 'Förlustrisk',
  THE_CLIENT_AWARE_THAT_FINANCIAL_INSTRUMENTS: 'Kunden är medveten om att finansiella instrument både kan öka och minska i värde och det finns en risk att de inte får tillbaka de pengar som de investerar. ',
  AGREEMENT_PAGE_HEADER: 'Avtal avseende investeringsrådgivning och relaterade tjänster',
  THIS_AGREEMENT: 'Text: Detta Avtal (‘Avtalet’) har idag ingåtts mellan Citroneer AB (organisationsnummer 559159–1192 St Eriksgatan 63B, 112 34 Stockholm, e-postadress: kontakt@citroneer.com, härefter också kallad ‘Citroneer’) och följande kund (härefter också kallad ‘Kunden’).',
  THE_ABOVE_CONTRACTUAL_PARTIES: 'Ovanstående avtalsparter benämns härefter gemensamt som ”Parterna” och var för sig som ”Part" eller "Parten”. Med "Avtal" menas detta Avtal jämte Citroneers Förköpsinformation (på www.citroneer.com/forkopsinformation) samt Citroneers Allmänna Villkor (på www.citroneer.com/allmannavillkor).',
  ABOUT_CITRONEER: 'Om Citroneer',
  INFORMATION_ABOUT_CITRONEER: 'För information om Citroneer och dess tjänster, information om risker med investeringar i finansiella instrument, framställande av klagomål, bästa genomförande av order med mera hänvisas till Citroneers Förköpsinformation.',
  OPENING_OF_ACCOUNTS: 'Öppnande av konton',
  CUSTOMER_INSTRUCTS_CITRONEER: 'Kunden ger Citroneer i uppdrag att öppna följande konton hos Citroneer med SEB som depåbank.',
  CHOOSE_SERVICE: 'Välj tjänst',
  INVESTMENT_ADVICE: 'Investeringsrådgivning',
  CHARGES: 'Avgifter',
  ASSIGNMENTS_AND_FEES: 'Uppdrag och avgifter',
  ASSIGNMENTS_AND_FEES_TEXT: 'Kunden ger Citroneer i uppdrag att löpande eller på Kundens begäran, fortsättningsvis benämnt "Uppdraget", utföra de tjänster som beskrivs i detta Avtal samt i Citroneers Allmänna Villkor.',
  SERVICE: 'Tjänst',
  CHARGE: 'Avgift',
  CONSULTATION: 'Konsultation',
  FEE_IN: 'Avgift i %',
  TERMS_AND_CONDITIONS: 'Allmänna villkor',
  FOR_THIS_AGREEMENT_P1: 'För detta Avtal gäller Citroneers Allmänna Villkor (villkoren) som vid var tid publicerats på ',
  FOR_THIS_AGREEMENT_P2: 'Citroneer förbehåller sig rätten att när som helst uppdatera sina Allmänna Villkor genom att meddela Kunden senast 2 veckor innan sådan uppdatering sker och från vilket datum den nya versionen av de Allmänna Villkoren gäller. Om Kunden fortsätter att använda de licensierade modulerna eller tjänsterna från Citroneer efter detta datum skall Kunden anses ha accepterat den nya versionen av Citroneers Allmänna Villkor.',
  CONTRACT_PERIOD_TERMINATION: 'Avtalstid, uppsägning',
  THIS_AGREEMENT_APPLIES: 'Detta Avtal gäller från dagen för undertecknandet och tills vidare. För bägge Parter gäller därefter tre (3) månaders uppsägningstid. Uppsägning ska ske skriftligen.',
  LIMITATION_OF_LIABILITY: 'Ansvarsbegränsning',
  LIMITATION_OF_LIABILITY_P1: 'Citroneer ansvarar inte för det ekonomiska utfallet av eventuella placeringar som Kunden gör på grund av information eller rådgivning som Citroneer lämnat eller för skada som uppkommit genom att Kunden lämnat felaktiga eller ofullständiga uppgifter till Citroneer. Det åligger inte Citroneer beakta skattemässiga konsekvenser av Kundens transaktioner enligt detta Avtal. Föreligger hinder för Citroneer att helt eller delvis verkställa order eller annan åtgärd som framgår av detta Avtal får order eller annan åtgärd skjutas upp till dess hindret upphört. Citroneer är inte ansvarigt om skada uppkommer för Kunden med anledning av sådant dröjsmål. Citroneer ansvarar inte för skada som orsakats av att den av Citroneer lagda kundordern inte effektuerats eller endast delvis effektuerats av Citroneers motpart eller av den handelsplats som Citroneer eller motparten anlitat. Citroneer ansvarar inte för skada som skulle ha kunnat undvikas om reklamation skett omgående. Part ansvarar inte för skada som beror av svensk eller utländsk lag, åtgärd av svensk eller utländsk myndighet, krigshändelse, strejk, blockad, bojkott, lockout eller annan liknande omständighet. Förbehållet om strejk, blockad, bojkott eller lockout gäller även om Part är föremål för eller själv vidtar sådan konfliktåtgärd. Skada som kan uppkomma i annat fall ska ersättas av Part endast i den mån skadan orsakats av att Parten varit grovt oaktsam eller det skett med uppsåt.',
  LIMITATION_OF_LIABILITY_P2: 'I intet fall är Part ansvarig för indirekt skada eller för skador orsakade av tredje man.',
  APPLICABLE_LAW_AND_DISPUTE: 'Tillämplig lag och tvist',
  APPLICABLE_LAW_AND_DISPUTE_P1: 'Vid tolkning av detta Avtal ska svensk lag tillämpas. Tvist rörande tolkningen eller tillämpningen av detta Avtal ska avgöras av svensk domstol.',
  CUSTOMER_APPROVAL: 'Kundens godkännande',
  CUSTOMER_APPROVAL_P1: 'Kunden har fått information om Citroneers Riktlinjer för bästa utförande vid mottagande och vidarebefordran av order genom Citroneers Förköpsinformation som finns tillgänglig på www.citroneer.com/forkopsinformation. Kunden godtar Citroneers Riktlinjer och godkänner att Citroneer får medverka till att Kundens order genomförs utanför en reglerad marknad eller en handelsplattform. Kunden godkänner att ändringar i och tillägg till Citroneers Förköpsinformation tillkännages på',
  CUSTOMER_APPROVAL_P2: 'Kunden godkänner vidare också att Citroneer kan uppdatera Citroneers Allmänna Villkor genom att göra ändringar i och tillägg till dem genom att publicera en ny version på',
  CUSTOMER_APPROVAL_P3: 'senast 30 dagar innan sådan ny versionen träder i kraft. Om Kunden inte accepterar den nya versionen har Kunden rätt att säga upp Avtalet senast dagen innan den träder i kraft. Om kunden inte sagt upp Avtalet innan den nya versionen trätt i kraft anses Kunden ha accepterat den nya versionen av Citroneers Allmänna Villkor.',
  COMPLETE_AGREEMENT_SIGNATURES: 'Komplett avtal, underskrifter',
  COMPLETE_AGREEMENT_SIGNATURES_P1: 'Detta Avtal har upprättats i ett eller fler likalydande exemplar, i fysisk eller digital form, och utgör tillsammans Citroneers Förköpsinformation, Citroneers Allmänna Villkor och eventuellt specifikt angivna bilagor hela avtalet och ersätter alla tidigare avtal, överenskommelser etc mellan Parterna.',
  SEND_APPROVAL: 'Skicka vid godkännande',
  LOWLEVELTEXT: 'Var medveten om att den förväntade investeringsavkastningen med största sannolikhet kommer att vara lägre',
  HIGHTLEVELTEXT: 'Var medveten om att högre risk kommer att medföra högre volatilitet i din portfölj',
  WAITING_ADVISOR_CONFIRMATION: 'Väntar på rådgivarens bekräftelse',
  CLIENT_AWAIT_APPROVE: 'Kunden väntar på godkännande',
  COMPANY_NAME: 'Företagsnamn',
  ENTER_COMPANY_NAME: 'Ange företagsnamn',
  COMPANY_REPRESENTATIVE: 'Information om företagsrepresentant',
  AUTHORITY: 'Auktoritet',
  AUTHORITY_1: 'Verklig huvudman',
  AUTHORITY_2: 'Firmatecknare',
  AUTHORITY_3: 'Fullmakt',
  PERSONAL_NUMBER: 'Personnummer',
  // Know your company
  ABOUT_THE_COMPANY: 'Frågor om företaget',
  WHAT_COMPANY_FORM: 'Vilken företagsform har företaget?',

  LIMITED_COMPANY: 'Aktiebolag',
  LIMITED_PARTNERSHIP: 'Handelsbolag/Kommanditbolag',
  SOLE_PROPRIETORSHIP: 'Enskild firma',
  PUBLIC_LIMITED_COMPANY: 'Börsbolag i Sverige',
  STATE_REGION: 'Stat/Region/Kommun',
  ECONOMIC_ASSOCIATION: 'Ekonomisk förening',
  NON_PROFIT_ASSOCIATION: 'Ideel förening',
  FOUNDATION_TRUST: 'Stiftelse',
  RELIGIOUS_COMMUNITY: 'Trossamfund',

  WHAT_INDUSTRY_DOES_THE_COMPANY: 'I vilken bransch verkar företaget?',
  CONSTRUCTION_INFRASTRUCTURE: 'Bygg/Anläggning/Infrastruktur',
  DATA_TECHNOLOGY_IT: 'Data/Teknik/IT',
  OPERATION_MAINTENANCE: 'Drift/Underhåll',
  ECONOMICS_FINANCE: 'Ekonomi/Finans',
  REAL_ESTATE: 'Fastigheter',
  RD_SCIENCE: 'FoU/Vetenskap',
  SALES_BUSINESS_DEVELOPMENT: 'Försäljning/Affärsutveckling',
  HOTEL_RESTAURANT_TOURISM: 'Hotell/Restaurang/Turism',
  HR_REKRYTERING: 'HR/Personal',
  IMPORT_EXPORT_TRADE: 'Import/Export/Handel',
  LAW: 'Juridik',
  CUSTOMER_SUPPORT_SERVICE: 'Kundsupport/Service',
  AGRICULTURE_FORESTRY: 'Lantbruk/Skogsbruk',
  MANAGEMENT: 'Ledning/Management',
  LOGISTICS_TRANSPORTATION: 'Logistik/Transport',
  MARKETING_ADVERTISING: 'Marknad/Reklam',
  HEALTCARE: 'Sjukvård/Hälsa',
  WRITER_PUBLISHING: 'Skribent/Publishing',
  SECURITY_RESCUE_SERVICE: 'Säkerhet/Räddningstjänst',
  THEOLOGY_PSYCHOLOGY_PHILOSOPHY: 'Teologi/Psykologi/Filosofi',
  MANUFACTURING_PRODUCTION: 'Tillverkning/Produktion',
  EDUCATION: 'Utbildning',

  COUNTRY_COMPANY_TAX: 'I vilket land har företaget sin skattemässiga hemvist?',
  COMPANY_FINANCIAL_INSTITUTION: 'Är företaget en finansiell institution?',
  IS_COMPANY_ACTIVE_PASSIVE: 'Är företaget aktivt eller passivt?',
  ACTIVE: 'Aktivt',
  PASSIVE: 'Passivt',
  HOW_MANY_EMPLOYEES: 'Hur många anställda har företaget?',
  TURNOVER_IN_THE_COMPANY: 'Vad är omsättningen i företaget (netto)?',

  OVER_100: 'Över 100',
  NEWLY_STARTED_COMPANY: 'Nystartat bolag',
  LESS_THAN_3_MILLION: 'Mindre än 3 miljoner SEK',
  MILLION_3_40: '3-40 miljoner SEK',
  OVER_40_MILLION: 'Över 40 miljoner SEK',

  WHERE_DOES_THE_COMPANYS_MONEY: 'Varifrån kommer företagets pengar och/eller värdepapper som du sätter in i Citroneer?',
  PROFIT_FROM_BUSINESS: 'Vinst från egna verksamheten',
  COMPANY_SALES: 'Företagsförsäljning',
  SHARE_CAPITAL: 'Aktiekapital',
  INVESTMENTS: 'Placeringar och investeringar',
  MEMBERSHIP_FEES: 'Medlemsavgifter',
  DONATIONS: 'Donationer',
  ALLOWANCE_2: 'Anslag',
  COMPANY_HAVE_AUDITOR: 'Har företaget en revisor?',
  OWNERSHIP_STRUCTURE: 'Ägarstruktur',
  ANYONE_WHO_CONTROLS_MORE: 'Finns det någon som kontrollerar mer än 25% av rösterna i företaget?',
  ANYONE_ELSE_WHO_CONTROLS_MORE: 'Är det någon annan som kontrollerar mer än 25% av rösterna i företaget?',
  TYPE_OF_OWNER: 'Vilken typ av ägare är det?',
  INDIVIDUAL: 'Privatperson',
  COMPANY: 'Företag',
  MISSING_SWEDISH_NUMBER: 'Saknar svenskt personnummer',
  DATE_OF_BIRTH: 'Födelsedatum',
  PERCENTAGE: 'Procentsats',
  PERCENTAGE_OF_VOTES: 'Procentandel av röster',
  COUNTRY_PERSON_HAVE_CITIZENSHIP: 'I vilket land har personen medborgarskap?',
  COUNTRY_PERSON_TAX_RESIDENT: 'I vilket land är personen skattemässigt bosatt?',
  TIN: 'TIN',
  POLITICALLY_EXPOSED_POSITION_PERSON: 'Är personen eller har personen varit en person i en politiskt utsatt position eller en familjemedlem eller en känd anställd till en sådan person?',
  MISSING_ORGANIZATION_NUMBER: 'Saknar svenskt organisationsnummer',
  ORGANIZATION_NUMBER_OWNING: 'Organisationsnummer för det ägande företaget',
  FOREIGN_ORGANIZATION_NUMBER: 'Utländskt organisationsnummer för det ägande företaget',
  NAME_OWNING_COMPANY: 'Namn på det ägande företaget',
  ADDRESS_OWNING_COMPANY: 'Postadress för det ägande företaget',
  ZIPCODE_OWNING_COMPANY: 'Postnummer för det ägande företaget',
  CITY_OWNING_COMPANY: 'Poststad för ägarbolaget',
  COUNTRY_OWNING_COMPANY: 'Land för det ägande företaget',
  TAX_COUNTRY_OWNING_COMPANY: 'Skatterättslig hemvist för det ägande företaget',
  PERCENTAGE_OF_VOTES_COMPANY: 'Andel röster för det ägande företaget',
  ANYONE_WHO_CONTROLS_COMPANY: 'Finns det någon som kontrollerar mer än 25% av rösterna i ägarbolaget?',
  NEED_DOWNLOAD_AND_FILL: 'Du måste ladda ner och fylla i ett formulär med den fullständiga ägarstrukturen.',
  ANYONE_ELSE_WHO_CONTROLS: 'Är det någon annan som kontrollerar mer än 25% av rösterna i ägarbolaget?',
  CO_OWNERS_WHO_ARE_RELATED: 'Finns det delägare som är släkt med ovan nämnda ägare?',
  HOW_MANY_CO_OWNERS_OWNERS: 'Hur många delägare är släkt med ovan nämnda ägare?',
  MORE_THAN_4: 'Fler än 4',
  ARE_THERE_TWO_OR_MORE_PARTIES: 'Finns det två eller flera närstående som tillsammans kontrollerar mer än 25 % av rösterna i bolaget?',
  HOW_MANY_PEOPLE_CONTROL_VOTES: 'Hur många står tillsammans för kontroll av rösterna?',
  ROLE_FUNCTION: 'Personens roll/funktion i ditt företag',
  ROLE_FUNCTION2: 'roll/funktion',
  CLICK_UPLOAD: 'Klicka för att överföra',
  UPLOAD_DOCUMENT: 'Ladda upp ett dokument som bevisar att personen är undertecknande eller har en fullmakt.',
  UPLOAD_ID_DOCUMENT: 'Uppladdad kopia av ID - dokument',
  FILTER: 'Filtrera',
  SEARCH_NAME: 'Sök namn eller ISIN',
  SEARCH_CODE: 'Sök efter namn eller kod',
  ACTIONS: 'Handlingar',
  ACTIVATE: 'Aktivera',
  CREATE_NEW_INSTRUMENT: 'Skapa nytt instrument',
  INSTRUMENT_CREATION: 'Instrumentskapande',
  INSTRUMENT_EDIT: 'Redigera instrument',
  REQUEST_EDIT: 'Redigera instrumentförfrågan',
  HIGHT: 'Höjd',
  MEDIUM: 'Medium',
  APPLY: 'Tillämpa',
  STATUS: 'Status',
  RE_INVITE: 'Re-invite',
  //
  MODEL: 'Modell',
  APPROVED_INSTRUMENTS: 'Godkända instrument',
  INVESTOR_BIAS: 'Investerare Bias',
  INSTRUMENT_MAPPING: 'Instrumentkartläggning',
  DEFINED_ASSET_CLASSES: 'Definierade tillgångsklasser',
  SUGGESTED_PORTFOLIOS: 'Föreslagna portföljer',
  SUGGESTIONS: 'Förslag',
  APPROVED: 'Godkänd',
  EXECUTING: 'Utför',
  EXECUTE: 'Utföra',
  IMPLEMENTED: 'Genomfört',
  APPROVAL_DATE: 'Godkännandedatum',
  DELETE_THIS_SUGGESTED: 'Vill du verkligen ta bort denna föreslagna portfölj?',
  CONFIRM_APPROVAL: 'Bekräfta godkännande',
  WANT_TO_APPROVE: 'Vill du verkligen godkänna denna föreslagna portfölj?',
  PORTFOLIOS_REPORT: 'Portföljens rapport',
  DELETE_THIS_APPROVED: 'Vill du verkligen ta bort denna godkända portfölj?',
  PORTFOLIO_NAME: 'Portföljens namn',
  QUANTIFY: 'Kvantifiera',
  CONFIRM_EXECUTION: 'Bekräfta utförandet',
  WANT_ORDER_EXECUTED: 'Vill du att denna order ska verkställas och skickas till FA?',
  EXECUTION_DATE: 'Utförandedatum',
  IMPLEMENTATION_DATE: 'Implementeringsdatum',
};
